import { getMessageForField } from "../fieldNameContentAssist/FieldNameContentAssist";
import { NO_VALUE_FILTER_WITH_OUTPUT, NO_VALUE_FILTER_NO_OUTPUT, FILTER_WITH_OUTPUT, FILTER_NO_OUTPUT, EDITOR_LOGIC_OPERATION_WITH_OUTPUT, EDITOR_LOGIC_OPERATION_NO_OUTPUT, LOGIC_OPERATION_WITH_OUTPUT, LOGIC_OPERATION_NO_OUTPUT, LABELED_FILTER_NO_OUTPUT, LABELED_FILTER_WITH_OUTPUT, FILTER_NO_OUTPUT_INVALID, FILTER_WITH_OUTPUT_INVALID } from "../../blockly/customBlocks";
import { entityDescriptors, getEntityDescriptor } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import { Filter } from "./Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import moment from "moment";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import _ from "lodash";
import { getDropdownItemLabel } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DropdownFieldRenderer";
import { FieldInterval } from "@crispico/foundation-react/entity_crud/CrudSettings";
import { AUDITABLE_ENTITY_TYPE, AUDITABLE_FIELD_TYPE, AuditUtils, AUDIT_ED_NAME } from "@crispico/foundation-react/pages/Audit/AuditUtils";
import { EntityDescriptorForServerUtils } from "@crispico/foundation-react/flower/entityDescriptorsForServer/EntityDescriptorForServerUtils";

export function createFilterBlock(filter: Filter, paramsForRecursion: ParamsForRecursion, withOutput?: boolean, forEditor?: boolean, useLabel?: boolean) {
    if (isSimpleFilter(filter)) {
        return generateSimpleFilterBlock(filter, paramsForRecursion, withOutput, useLabel);
    } else {
        return generateAdvancedFilterBlock(filter, paramsForRecursion, withOutput, forEditor, useLabel);
    }
}

type ParamsForRecursion = {
    entityDescriptorName?: string;
    
    /**
     * Not a super solution, because it's specific to Audit. But it's not easy to find a nicer solution,
     * and we don't see other similar cases so that it's worth investing in it (the nicer solution).
     */
    foundAuditEntityMappingId?: string;
}

function isSimpleFilter(filter: Filter) {
    return filter.filters ? false : true;
}

function generateAdvancedFilterBlock(filter: Filter, paramsForRecursion: ParamsForRecursion, withOutput?: boolean, forEditor?: boolean, useLabel?: boolean) {
    const type = forEditor 
        ? withOutput ? EDITOR_LOGIC_OPERATION_WITH_OUTPUT : EDITOR_LOGIC_OPERATION_NO_OUTPUT 
        : withOutput ? LOGIC_OPERATION_WITH_OUTPUT : LOGIC_OPERATION_NO_OUTPUT;
    if (filter.filters!.length === 0) {
        return `<block type="${type}" x="0" y="0">
            <mutation items='2'></mutation>
            <field name="OP">${filter.operator}</field>
        </block>`;
    }
    const filters: Array<string | undefined> = filter.filters!.map(f => {
        return isSimpleFilter(f) ? generateSimpleFilterBlock(f, paramsForRecursion, true, useLabel) : generateAdvancedFilterBlock(f, paramsForRecursion, true, forEditor);
    });

    const nrEntries = filter.filters!.length;
    let values: string = '';
    for (let i = 0; i < filters.length; i++) {
        values = values + `<value name='ADD${i}'>${filters[i]}</value>`;
    }
    return (
        `<block type='${type}' x='0' y='0'>
            <mutation items='${forEditor ? nrEntries === 1 ? 2 : nrEntries : nrEntries}'></mutation>
            <field name='ENABLED'>${filter.enabled ? 'TRUE' : 'FALSE'}</field>
            <field name='OP'>${filter.operator}</field>
            ${values}
        </block>`
    );
}

function generateSimpleFilterBlock(filter: Filter, paramsForRecursion: ParamsForRecursion, withOutput?: boolean, useLabel?: boolean) {
    let field = filter.field && paramsForRecursion.entityDescriptorName ? getMessageForField(filter.field, paramsForRecursion.entityDescriptorName) : filter.field;

    if (useLabel && filter.label) {
        const type = withOutput ? LABELED_FILTER_WITH_OUTPUT : LABELED_FILTER_NO_OUTPUT;
        return (
            `<block type='${type}' x='0' y='0'>
                <field name='ENABLED'>${filter.enabled ? 'TRUE' : 'FALSE'}</field>
                <field name='LABEL'>${filter.label}</field>
                <data>${_.escape(JSON.stringify({ ...filter }))}</data>
            </block>`
        );
    }
    if (FilterOperators.noValueOperators.find(op => op.value === filter.operator)) {
        const type = withOutput ? NO_VALUE_FILTER_WITH_OUTPUT : NO_VALUE_FILTER_NO_OUTPUT;
        return (
            `<block type='${type}' x='0' y='0'>
                <field name='ENABLED'>${filter.enabled ? 'TRUE' : 'FALSE'}</field>
                <field name='FIELD'>${field}</field>
                <field name='OPERATOR'>${filter.operator ? _msg("Filter.operator." + filter.operator) : ''}</field>
                <data>${_.escape(JSON.stringify({ ...filter }))}</data>
            </block>`
        );
    }

    let filterCopy = { ...filter };
    let type = withOutput ? FILTER_WITH_OUTPUT : FILTER_NO_OUTPUT;
    const operator = filter.operator ? _msg("Filter.operator." + filter.operator).replace('<', '&lt;').replace('>', '&gt;') : '';
    let value: any = filter.value;
    try {
        value = processFilterValue(filter, paramsForRecursion);
    } catch (e){
        type = type == FILTER_WITH_OUTPUT ? FILTER_WITH_OUTPUT_INVALID : FILTER_NO_OUTPUT_INVALID;
    }

    return (
        `<block type='${type}' x='0' y='0'>
            <field name='ENABLED'>${filter.enabled ? 'TRUE' : 'FALSE'}</field>
            <field name='FIELD'>${field}</field>
            <field name='OPERATOR'>${operator}</field>
            <field name='VALUE'>${_.escape(value)}</field>
            <data>${_.escape(JSON.stringify({ ...filterCopy }))}</data>
        </block>` 
    );
}

function processFilterValue(filter: Filter, paramsForRecursion: ParamsForRecursion): string {
    let value: any = filter.value;

    if (value && typeof value == 'object') {
        let entityDescriptor = entityDescriptors[value.__typename];
        let miniField = entityDescriptor ? entityDescriptor.miniFields[0] ? entityDescriptor.miniFields[0] : 'name' : 'name';

        if (value.length !== undefined) {
            filter.value = value.map((o: any) => o.id + Utils.defaultIdSeparator + o[miniField] + Utils.defaultIdSeparator + o.__typename).join(Filter.IN_DELIMITER);
            value = value.map((o: any) => o[miniField]);
        } else {
            filter.value = value.id + Utils.defaultIdSeparator + value[miniField] + Utils.defaultIdSeparator + value.__typename;
            value = value[miniField];
        }
    } else if (value && typeof value == 'string') {
        let idsAndNames = value.split(Filter.IN_DELIMITER)
        if (idsAndNames.length > 1) {
            value = idsAndNames.map((o: any) => {
                let values = o.split(Utils.defaultIdSeparator);
                return values[1];
            });
            if (value.filter((value: any) => value === undefined).length > 0) {
                value = filter.value;
            };
        } else {
            let values = idsAndNames[0].split(Utils.defaultIdSeparator);
            if (values.length === 3) {
                value = values[1];
            }
        }
    }

    if (value && filter.operator === FilterOperators.forNumber.between.value && value.toString().includes(Filter.BETWEEN_DELIMITER)) {
        value = value.replace(Filter.BETWEEN_DELIMITER, Filter.AND_DELIMITER)
    }

    if (paramsForRecursion.entityDescriptorName && filter.field) {
        let type;
        let entityDescriptor = entityDescriptors[paramsForRecursion.entityDescriptorName];
        let filterField = filter.field;
        if (filter.field.indexOf('.') >= 0) {
            entityDescriptor = getEntityDescriptor(Utils.substringBefore(filter.field, '.', true).split('.'), paramsForRecursion.entityDescriptorName);
            filterField = Utils.substringAfter(filter.field, '.', true);
            type = entityDescriptor.getField(filterField).getType();
        } else {
            type = entityDescriptor.getField(filterField).getType();
        }
        if (type === FieldType.dropdown) {
            return getDropdownItemLabel(entityDescriptor.getField(filterField), { from: value } as FieldInterval)
        }
        if (paramsForRecursion.entityDescriptorName === AUDIT_ED_NAME) {
            if (type === AUDITABLE_ENTITY_TYPE) {
                paramsForRecursion.foundAuditEntityMappingId = value;
                const ed = EntityDescriptorForServerUtils.getEntityDescriptor(value);
                return ed ? ed.getLabel() : value;
            }
            if (type === AUDITABLE_FIELD_TYPE && paramsForRecursion.foundAuditEntityMappingId) {
                return AuditUtils.getFieldLabelsFromIds(paramsForRecursion.foundAuditEntityMappingId, value);
            }
        }
        if (type !== FieldType.date) {
            return value; 
        }
    }

    if (value && FilterOperators.getOperatorsForType(FilterOperators.TYPE_DATE).getVisibleOperators().find(op => op.value === filter.operator)
        && !FilterOperators.dateOperatorsWithNumberValue.find(op => op.value === filter.operator)) {
        const date = moment(value, moment.ISO_8601);
        if (filter.operator === FilterOperators.forDate.dayOf.value) {
            value = date.format(Utils.dateFormat);
        } else if (filter.operator === FilterOperators.forDate.between.value) {
            const values = value.split(Filter.AND_DELIMITER);
            const date1 = values[0] ? moment(values[0], moment.ISO_8601).format(Utils.dateTimeFormat) : '';
            const date2 = values[1] ? moment(values[1], moment.ISO_8601).format(Utils.dateTimeFormat) : '';
            value = date1 + " " + _msg("Filter.operator.and") + " " + date2;
        } else {
            value = date.format(Utils.dateTimeFormat)
        }
    }
    return value;
}
"../../entity_crud/entityCrudConstants""../../utils/Utils""../../entity_crud/FieldType""../../entity_crud/fieldRenderersEditors/DropdownFieldRenderer""../../entity_crud/CrudSettings""../../pages/Audit/AuditUtils""../../flower/entityDescriptorsForServer/EntityDescriptorForServerUtils"