import React from "react";
import { FieldEditorProps } from "../fieldRenderersEditors";
import { categoricalColorSchemes } from "@nivo/colors";
import { DropdownItemProps, Select, StrictSelectProps } from "semantic-ui-react";

export class PieCountColorPaletteFieldEditor extends React.Component<FieldEditorProps & Partial<StrictSelectProps>> {
    private renderContent(value: string) {
        if (!value || value.length === 0) {
            return <></>;
        }
        const schema: string[] = (categoricalColorSchemes as any)[value as any];
        return <div className="flex-container-row">
            <div className="SelectNivoColor_Text">{value}</div>
            <div>{schema.map(color => <div className="SelectNivoColor_ColorBlock" style={{backgroundColor: color}} />)}</div>
        </div>;
    }

    render = () => {
        const value = this.props.formikProps.values?.colors;
        const options = Object.keys(categoricalColorSchemes).map((s, index) => {
            return {key: index, value: s, text: s, content: this.renderContent(s)} as DropdownItemProps;
        });
        return <Select options={options} clearable value={value} onChange={(e, data) => this.props.formikProps.setFieldValue("colors", data.value)}
            trigger={<div style={{marginLeft: "1px"}}>{this.renderContent(value)}</div>} {...this.props} />;
    }
}

export default PieCountColorPaletteFieldEditor;