import { FilterOperators, Messages } from "@crispico/foundation-gwt-js"
import { apolloClient, EntityDescriptor } from "@crispico/foundation-react"
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter"
import { CenteredMetric, helper, PieDatum, ResponsivePieExt } from "@crispico/foundation-react/components/nivoExt"
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants"
import { ShareLinkLogic } from "@crispico/foundation-react/entity_crud/ShareLinkLogic"
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents"
import { categoricalColorSchemes } from "@nivo/colors"
import gql from "graphql-tag"
import lodash from 'lodash'
import { MouseEventHandler } from "react"
import { Link } from "react-router-dom"
import { Divider, Label, List } from "semantic-ui-react"
import { AbstractWidgetWithFilter, AbstractWidgetWithFilterConfig, AbstractWidgetWithFilterProps, AbstractWidgetWithFilterReducers, AbstractWidgetWithFilterState } from "../AbstractWidgetWithFilter"
import { WidgetProps } from "../dashboardTab/WidgetWrapper"
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals"

export interface CriteriaConfigInput extends AbstractWidgetWithFilterConfig {
    title?: string | null;
    currentDate?: string | null;
    entityType: string;
    field: any;
    pieSliceType?: string | null;
    pieSlices: any[] | null;
    excludeOthers: boolean;
    detailedTooltip?: boolean;
    fontSizeForTotal?: number;
    colors?: string;
    showLegend?: boolean;
    legendDoesNotShowValuesUnder: number;
}

export class PieCountByCriteriaState extends AbstractWidgetWithFilterState {
    entity = '' as string;
    pieData = [] as PieDatum[];
    tooltipData = {} as any;
    keys = [] as string[];
    uniqueEntitiesUsed = undefined as number | undefined;
    totalFilter = undefined as Filter | undefined;
    lastClick = { id: -1, date: new Date().valueOf() } as { id: any, date: number };
}

export class PieCountByCriteriaReducers<S extends PieCountByCriteriaState = PieCountByCriteriaState> extends AbstractWidgetWithFilterReducers<S> {
    
        processData(p: { entity: string, pieData: PieDatum[], colors?: string, tooltipData: any, uniqueEntitiesUsed: number | undefined }) {
            this.s.entity = p.entity;
            let index = 0;
            const colorSchemes = categoricalColorSchemes as any;
            const colorScheme = p.colors && colorSchemes[p.colors] ? colorSchemes[p.colors] : colorSchemes["category10"];
            this.s.pieData = p.pieData.map(p => {
                return {
                    id: p.id,
                    label: p.label,
                    value: p.value,
                    color: p.color ? p.color : colorScheme[(index++) % colorScheme.length],
                    filter: p.filter
                }
            });

            const othersIndex = this.s.pieData.findIndex(d => d.id === "Others");
            if (othersIndex === -1) {
                this.s.totalFilter = Filter.createComposed(FilterOperators.forComposedFilter.or, []);
                for (let i = 0; i < this.s.pieData.length; i ++) {
                    if (this.s.pieData[i].filter) {
                        this.s.totalFilter.filters!.push(this.s.pieData[i].filter!);
                    }
                }
            }

            this.s.tooltipData = p.tooltipData
            this.s.keys = Object.keys(this.s.tooltipData);
            this.s.uniqueEntitiesUsed = p.uniqueEntitiesUsed;
        }
}

export type PieCountByCriteriaProps = WidgetProps & AbstractWidgetWithFilterProps & RRCProps<PieCountByCriteriaState, PieCountByCriteriaReducers> & { widgetConfig: CriteriaConfigInput, zeroTrainingMode: boolean, onPieClick?: MouseEventHandler<PieDatum>, buttonBarRef: any };

export class PieCountByCriteria extends AbstractWidgetWithFilter<PieCountByCriteriaProps> {

    shareLinkLogic = new ShareLinkLogic();

        async loadData(p: { config: CriteriaConfigInput, currentDate: Date, filter: Filter }) {     
            if (!entityDescriptors[p.config.entityType]) {
                return;
            }       
            let operationName = lodash.lowerFirst(p.config.entityType) + 'Service_criteriaChartData'
            let query = gql(`query q($config: CriteriaConfigInput!, $currentDate: Date!) { 
                ${operationName}(config: $config, currentDate: $currentDate) {
                    pieData { id, label, value, color, filter } tooltipData uniqueEntitiesUsed
                }
            }`);
            const config = {
                entity: p.config.entityType,
                field: p.config.field,
                filter: p.filter,
                pieSliceType: p.config.pieSliceType,
                pieSlices: p.config.pieSlices,
                excludeOthers: p.config.excludeOthers
            }
            config.pieSlices = p.config.pieSlices ? p.config.pieSlices.map(slice => { return { id: slice.id, name: slice.name } }) : null
            const criteriaChartData = (await apolloClient.query({ query: query, variables: { config: config, currentDate: p.currentDate }, context: { showSpinner: false } })).data[operationName];
            
            this.props.r.processData({
                entity: p.config.entityType,
                pieData: criteriaChartData.pieData.map((d: any) => { d.id = Messages.getInstance().maybeTranslateByUser(d.id); d.filter = JSON.parse(d.filter); return d }),
                colors: p.config.colors,
                tooltipData: criteriaChartData.tooltipData,
                uniqueEntitiesUsed: criteriaChartData.uniqueEntitiesUsed
            });
            
        }

    async refreshInternal(filter: Filter) {
        await this.loadData({ 
            config: this.props.widgetConfig,
            currentDate: this.props.widgetConfig.currentDate ? new Date(this.props.widgetConfig.currentDate) : new Date(),
            filter
        });
    }

    renderTotal(ed: EntityDescriptor, filter: Filter, total: number) {
        const occurrencesLabel = <Label key="totalOccurrencesLabel" circular size='mini' style={{ color: 'white', backgroundColor: 'silver', marginRight: '3px' }}>{total}</Label>;
        if (!this.props.s.uniqueEntitiesUsed || this.props.s.uniqueEntitiesUsed === 0) {
            return <Link to={this.shareLinkLogic.createLink(false, ed, Filter.enableAllFilters(filter), [], { allowEmptyFilter: true })} className="PieCountByCriteriaWidgetLegendLink flex-container-row flex-center">{[occurrencesLabel, _msg("Chart.pieCountByCriteria.total")]}</Link>
        }
        const uniqueEntitiesLabel = <Label key="totalUniqueEntitiesLabel" circular size='mini' style={{ color: 'white', backgroundColor: 'grey', marginRight: '3px' }}>{this.props.s.uniqueEntitiesUsed}</Label>;
        return <Link to={this.shareLinkLogic.createLink(false, ed, Filter.enableAllFilters(filter), [], { allowEmptyFilter: true })} className="PieCountByCriteriaWidgetLegendLink flex-container-row flex-center"><p>
            {uniqueEntitiesLabel} {_msg("Chart.pieCountByCriteria.uniqueEntitiesUsed.0", ed.getLabel(true))} {occurrencesLabel} {_msg("Chart.pieCountByCriteria.uniqueEntitiesUsed.1")}
        </p></Link>;
    }

    renderMain() {
        const props = this.props;
        const ed = entityDescriptors[props.widgetConfig.entityType];
        let additionalProps: any = {}
        additionalProps.colors = props.s.pieData.map(p => p.color)
        if (props.widgetConfig.colors) {
            additionalProps.colors = {scheme: props.widgetConfig.colors};
        }
        let total = 0;
        const filter = this.props.s.totalFilter ? this.props.s.totalFilter : this.createFilter();

        return ed ? (<div className="PieCountByCriteriaWidgetContainer">
            <div className="PieCountByCriteriaWidgetPie">
                <ResponsivePieExt data={props.s.pieData} {...additionalProps} arcLabelsTextColor="white"
                    layers={['arcs', 'arcLabels', props.widgetConfig.showLegend ? null : 'arcLinkLabels', props.widgetConfig.fontSizeForTotal !== 0 ? new CenteredMetric(props.widgetConfig.fontSizeForTotal).get() : null]}
                    tooltip={({ datum: { id, value, color } }) => (
                        <div className={'ResponsivePieChart_Tooltip'}>
                            <div style={{ backgroundColor: color, width: '12px', height: '12px', display: 'inline-block' }}></div> <strong>{Messages.getInstance().maybeTranslateByUser(id as string)}</strong>:&nbsp;
                            {_msg("Chart.pieCountByCriteria.tooltip.doubleclick")} {ed.getLabelWithIcon()}
                            {props.widgetConfig.detailedTooltip && props.s.tooltipData[id] && props.s.tooltipData[id].length > 0 ? <p>{helper(Array.from(props.s.tooltipData[id]).map((t: any) => t + ', '))}</p> : null}
                        </div>
                    )}
                    onClick={(data, e) => {
                        if (data.id === props.s.lastClick?.id && ((new Date().valueOf() - props.s.lastClick.date) < 1000)) {
                            const link = data.data.filter ? this.shareLinkLogic.createLink(false, ed, Filter.enableAllFilters(data.data.filter), []) : undefined;
                            link && AppMetaTempGlobals.history.push(link);
                        }
                        props.r.setInReduxState({ lastClick: { id: data.id as string, date: new Date().valueOf() } })
                    }} />
            </div>
            {this.props.widgetConfig.showLegend ? <div className="PieCountByCriteriaWidgetLegend">
                <List>
                    {props.s.pieData.map(p => {
                        total += p.value;
                        const link = this.shareLinkLogic.createLink(false, ed, p.filter ? Filter.enableAllFilters(p.filter) : undefined, []);
                        return <List.Item key={p.id}><Link to={link} className='flex-container-row flex-center'><Label circular size="mini" style={{ color: 'white', backgroundColor: p.color, marginRight: '3px' }}>{p.value}</Label><span style={{ textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: 'hidden' }}>{p.label}</span></Link></List.Item>
                    })}
                    <Divider />
                    <List.Item key="pieCountTotal">{this.renderTotal(ed, filter, total)}</List.Item>
                </List>
            </div> : null}
            {/* {props.buttonBarRef && ReactDOM.createPortal(createButtonForWidget({ positive: true, icon: "refresh", className: "dashboardWidgetRefresh" }, () => this.refresh()), props.buttonBarRef)} */}
        </div>) : null;
    }

}

export const PieCountInTerritoriesFastTabRRC = ReduxReusableComponents.connectRRC(PieCountByCriteriaState, PieCountByCriteriaReducers, PieCountByCriteria);
"../../..""../../../components/CustomQuery/Filter""../../../components/nivoExt""../../../entity_crud/entityCrudConstants""../../../entity_crud/ShareLinkLogic""../../../reduxReusableComponents/ReduxReusableComponents""../../../AppMetaTempGlobals"