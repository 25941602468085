import { TraceMapCache } from "./TraceMapCache";
import { FINISH_WAITING_CALLBACK_ERROR_DURING_WAITING, TestsAreDemoMaster } from "./TestsAreDemoMaster";
import { PuppeteerMessageFinished } from "../common-lib-and-node/common-types";
import { AUTO_CREATED_GROUP_PREFIX } from "./TestsAreDemoFunctions";

/**
 * Despite its name, this is not a Mocha reporter itself. It should have extended `Mocha.reporters.Base`, but mocha is not
 * accessible in "master". However, when the real reporter is created (in the slave frame), then this is created, and the
 * handlers from this class are registered.
 */
export class ReporterFromSlaveToMaster {

  protected _indents = 0;
  protected path: string[] = [];
  currentDir = "";
  /** @see where it is used for explanation */
  previousDir = "";
  testFailed = false;

  constructor(protected master: TestsAreDemoMaster, protected reporter: Mocha.reporters.Base, protected runner: Mocha.Runner, protected options: Mocha.MochaOptions) {
    this.onTestFail = this.onTestFail.bind(this);
    this.onEnd = this.onEnd.bind(this);

    // from: https://github.com/mochajs/mocha/blob/master/lib/runner.js
    const { EVENT_RUN_BEGIN, EVENT_RUN_END, EVENT_TEST_FAIL, EVENT_TEST_PASS, EVENT_SUITE_BEGIN, EVENT_SUITE_END, EVENT_TEST_BEGIN, EVENT_TEST_END } = this.master.slave.getMochaConstants();

    runner
      .once(EVENT_RUN_BEGIN, () => {
        this.log('start');
      })
      .on(EVENT_SUITE_BEGIN, suite => {
        this.increaseIndent(suite.title);
        this.log(this.indent() + "[suite] " + suite.title)
      })
      .on(EVENT_SUITE_END, () => {
        this.decreaseIndent();
      })
      .on(EVENT_TEST_BEGIN, test => {
        this.testFailed = false;
        this.increaseIndent(test.title);
        this.log(this.indent() + "[test begin] " + test.title)
      })
      .on(EVENT_TEST_END, () => {
        this.decreaseIndent();
      })
      .on(EVENT_TEST_PASS, test => {
        this.log(`${this.indent()}[test passed] ${test.title}`);
      })
      .on(EVENT_TEST_FAIL, this.onTestFail)
      .once(EVENT_RUN_END, this.onEnd);
  }

  indent() {
    return Array(this._indents).join('  ');
  }

  protected getDirFromPath(p: string[]) {
    let result = "";
    if (p.length >= 1) {
      result = p[p.length - 1];
    } 
    if (p.length >= 2) {
      if (!p[p.length - 2].startsWith(AUTO_CREATED_GROUP_PREFIX)) {
        result = p[p.length - 2] + "." + result;
      } else if (p.length >= 3) {
        result = p[p.length - 3] + "." + result;
      }
    }
    return result;
  }

  increaseIndent(suiteOrTestName: string) {
    this._indents++;
    if (suiteOrTestName) {
      // because the root is a suite w/o name
      // I hope all children have name though
      this.path.push(suiteOrTestName);
      this.previousDir = this.currentDir;
      this.currentDir = this.getDirFromPath(this.path);
    }
  }

  decreaseIndent() {
    this._indents--;
    this.path.pop();
    this.previousDir = this.currentDir;
    this.currentDir = this.getDirFromPath(this.path);
}

  /**
   * We have this so that it can be mocked/spied during tests.
   */
  consoleLog(what: string) {
    console.log(what);
  }

  log(what: string) {
    this.consoleLog(what);
    this.master.log(what);
  }

  async onTestFail(test: Mocha.Test, err: any) {
    this.testFailed = true;
    this.log(
      `${this.indent()}[test failed] (**look in the console for the clickable stacktrace**): ${test.title}. Error = ${err}`
    );
    this.consoleLog("Test '" + test.title + "' has failed w/ the following error (should have clickable stacktrace):");

    if (this.master.finishWaitingCallbackTestAndScreenshotNumber) {
      this.master.finishWaitingCallbackTestAndScreenshotNumber = FINISH_WAITING_CALLBACK_ERROR_DURING_WAITING;
      // the original callback: we don't want it to be executed any more
      this.master.setState({ finishWaitingCallback: undefined });
    }

    // ************************************************************************************************************************************************************
    // TODO 5 mar 2023: keeping the "old" method for a while; after some while of successful prod, let's delete this block
    console.log("*** Printing error w/ the old method");
    // I discovered that when we print an error like this => Chrome process it a little bit, e.g. 
    // applying the source maps. The result (at the console) is a clickable stack trace. I don't
    // think the same result is obtainable within the app. But anyway, even if we had there a nice
    // result, it wouldn't be clickable
    // PS: it appears that the Error class has a bunch of non standard stuff: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
    // or https://v8.dev/docs/stack-trace-api. Even if it's non standard, it seems to be implemented very similarily by FF and Chrome.
    // Regarding printing: FF also prints cf. above, but doesn't apply the source map
    this.consoleLog(err);
    if (Object.getPrototypeOf(err).name !== "Error") {
      // I discovered that for some subclasses of Error (not all; I don't understand which), such as the important AssertionError
      // which is thrown by e.g. "assert.equal()": Chrome doesn't print the error cf. above; it prints the error like a normal object.
      // Hence no more clickability. The solution is to not print the original error.
      const dummyError = new Error();
      dummyError.stack = err.stack;
      console.log("Printing the error again, to make sure we have a clickable stacktrace:");
      console.log(dummyError);
    }

    console.log("*** Printing the error w/ the new method (decoding the stack trace via source maps)");
    // ************************************************************************************************************************************************************

    // this instance is not the same w/ this.master.slave.report, because of async
    const reportInstanceBeforeDoingTheAsyncDecode = this.master.slave.report;
    // when it will be ordered to "print!", the print will wait for our error
    reportInstanceBeforeDoingTheAsyncDecode?.waitForError();

    const decodedError = await TraceMapCache.INSTANCE.decodeStackTrace(err);
    this.consoleLog(decodedError);
    
    reportInstanceBeforeDoingTheAsyncDecode?.errorArrived(decodedError);
  }

  onEnd() {
    const stats = this.runner.stats!;
    this.log(`end: ${stats.passes}/${stats.passes + stats.failures} ok`);
    if (this.master.props.puppeteer) {
      this.master.sendMessageToPuppeteer("finished", this.runner.stats as PuppeteerMessageFinished);
    }
  }
}