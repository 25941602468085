import { BigState } from "@crispico/foundation-react/AppMeta";
import { copyFieldDescriptorSettings, EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { ConnectedPageInfo, createSliceFoundation, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import React from "react";
import { FormValues } from "./PasswordEditor";
import { UserEditorPage, UserFormInEditor } from "./UserEditorPage";
import { SliceUserEditor, userEntityDescriptor } from "./userEntityDescriptor";

const languageFieldName = "language";

const currentUserEntityDescriptor = new class CurrentUserEntityDescriptor extends EntityDescriptor {
    // The language field is always authorized to be edit for any logged in user
    getAuthorizedFields(type: string): { [fieldName: string]: FieldDescriptor } {
        // return this.fields;
        var authorizedFields = super.getAuthorizedFields(type);
        if (!authorizedFields[languageFieldName]) {
            authorizedFields[languageFieldName] = this.fields[languageFieldName];
        }
        return authorizedFields;
    }
}({ name: "User" }, false)
    // The field descriptors are only a subset of userEntityDescriptor fields (expl. we don't have isAdmin field )
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "username", type: FieldType.string })
    .addFieldDescriptor({ name: "firstName", type: FieldType.string })
    .addFieldDescriptor({ name: "email", type: FieldType.string })
    .addFieldDescriptor({ name: "lastName", type: FieldType.string })
    .addFieldDescriptor({ name: languageFieldName, type: FieldType.dropdown })

currentUserEntityDescriptor.hasDuplicateButton = false;
currentUserEntityDescriptor.hasAttachedDashboards = false;

const sliceCurrentUserEntityEditor = createSliceFoundation(class CurrentUserEditor extends SliceUserEditor {
    getSaveOperationName() {
        // We override it because by default is 'userService_save'
        return 'userService_updateCurrentUser';
    }

    // The save is always enabled, no matter the permissions of the user
    // Because any logged in user can change at least the language of the interface
    public isSaveAuthorized(editMode: any, showErrorMessageIfNoPermission?: boolean): boolean {
        return true;
    }

    shouldOverrideSaveMutationEntirely(): boolean {
        return false;
    }

    /**
     * The super.handleDataReturnedBySaveOperation displays a notification to the user regarding whether or not an email was sent
     * Not the case for editing the current user
     */
    handleDataReturnedBySaveOperation(dataReturnedBySave: any): void {
    }
});

sliceCurrentUserEntityEditor.setEntityDescriptor(currentUserEntityDescriptor);

type Props = PropsFrom<typeof sliceCurrentUserEntityEditor> & {
    currentUserId: number,
    closeModal: () => void
};
class CurrentUserEditorPage extends UserEditorPage<Props> {
    editorFormSimpleClass = CurrentUserFormInEditor;
    state = { editPasswordEnabled: false, generatePasswordEnabled: false };

    componentDidMount() {
        this.load(this.props.currentUserId);
        // this is done here in order to ensure the userEntityDescriptor has the settings populated
        copyFieldDescriptorSettings([languageFieldName], userEntityDescriptor, currentUserEntityDescriptor);
        this.resetPasswordEditorState();
    }

    isPasswordEditingAuthorized() {
        return true;
    }

    /**
     * The current user can only change the password manually. Can not trigger the generation of a new password
     */
    protected isGeneratePasswordCheckBoxShown(): boolean {
        return false;
    }

    protected hasInputForCurrentPassword(): boolean {
        return true;
    }

    render() {
        return <>{this.renderHeader({})} {this.renderForm()}</>;
    }

    /**
     * Override in order to get the currentPassword value
     */
    getEntityValuesFromForm() {
        const entityValues = super.getEntityValuesFromForm();
        if (this.state.editPasswordEnabled && !this.state.generatePasswordEnabled) {
            entityValues.currentPassword = (this.refPasswordEditor.current!.formikContext.values as FormValues).currentPassword;
        }
        return entityValues;
    }

    protected async onSaveInternal() {
        const ed = currentUserEntityDescriptor;
        // All fields defined in the descriptor + newPassword + currentPassword fields
        await this.props.dispatchers.save(this.props.entity, true, [...Object.keys(ed.fields).filter(f => ed.fields[f].enabled), "newPassword", "currentPassword"]);
        this.props.closeModal();
    }

};

class CurrentUserFormInEditor extends UserFormInEditor {
    protected isGeneratePasswordCheckBoxShown() {
        return false;
    }

    protected hasInputForCurrentPassword() {
        return true;
    }
}

export const infoCurrentUserEditorPage = new ConnectedPageInfo(sliceCurrentUserEntityEditor, CurrentUserEditorPage, "CurrentUserEditorPage");
infoCurrentUserEditorPage.mapBigStateToProps = (state: BigState, props: Props) => {
    props.currentUserId = state.AppContainer.initializationsForClient?.currentUser?.id!;
}
"../../AppMeta""../../entity_crud/EntityDescriptor""../../entity_crud/FieldType""../../reduxHelpers"