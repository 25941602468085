import { addEntityDescriptor, EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { sliceColumnConfigEntityEditorPage, ColumnConfigEntityEditorPage } from "./ColumnConfigEntityEditorPage";
import { addAfterStartupRunnable, entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import _ from "lodash";
import { ClientColumnConfig } from "./ClientColumnConfig";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { EntityFieldsFieldEditor } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/EntityFieldsFieldEditor";
import { ColumnConfigConfig, ColumnDefinition } from "@crispico/foundation-react/entity_crud/EntityTableSimple";
import { COLUMN_DEFAULT_WIDTH } from "./dataStructures";
import { FIELDS_READ } from "@crispico/foundation-react/utils/Utils";
import { SelectExtOption } from "../selectExt/SelectExt";
import { getMessageForField } from "../fieldNameContentAssist/FieldNameContentAssist";
import { FieldEditorProps, fieldEditors } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FormikProps } from "formik";
import { FunctionComponentElement } from "react";
import { MAX_REFRESH_RATE, MIN_REFRESH_RATE, RefreshButton, RefreshInformation } from "../RefreshButton/RefreshButton";
import React from "react";

export let columnConfigEntityDescriptor: EntityDescriptor;

class MyEntityDescriptor extends EntityDescriptor {
    createNewEntity() {
        const entity: ClientColumnConfig = {
            id: null,
            dirty: false,
            entityName: '',
            name: '',
            configObject: { columns: [] },
            fromCrudSettings: false,
            organization: null,
            displayAsCards: false,
            autoRefreshInterval: 0
        };
        return entity;
    }

    getGraphQlFieldsToRequest(fields: string[]) {
        const newFields = fields.filter(field => field !== "configObject");
        return super.getGraphQlFieldsToRequest(newFields) + " configJson";
    }
}

addAfterStartupRunnable(() => {
    columnConfigEntityDescriptor = addEntityDescriptor(new MyEntityDescriptor({
        name: "ColumnConfig",
        miniFields: ["name"],
        showInUI: true,
        javaIdType: "Long",
        graphQlIdType: "Long"
    })
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "entityName", type: FieldType.entityName })
        .addFieldDescriptor({ name: "name", type: FieldType.string })
        
        /**
         * This field doesn't exist on the server. We do some overrides in the ColumnConfigEntityEditorPage.
         * 
         * @see ClientColumnConfig
         */
        .addFieldDescriptor({ name: "autoRefreshInterval", type: FieldType.number }, new class extends FieldDescriptor {
            protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                return <div style={{ display: "inline-flex" }}>{React.createElement(EditorClass, props)} <div style={{ margin: 10 }}><RefreshInformation /></div></div>;
            }
        }())
        .addFieldDescriptor({ name: "configObject", type: "columnConfigEntityFields", isInDefaultColumnConfigForTable: false, fieldForEntityName: "entityName", clientOnly: true, optional: true })
    );

    columnConfigEntityDescriptor.infoEditor.slice = sliceColumnConfigEntityEditorPage;
    columnConfigEntityDescriptor.infoEditor.wrappedComponentClass = ColumnConfigEntityEditorPage;

    sliceColumnConfigEntityEditorPage.setEntityDescriptor(columnConfigEntityDescriptor);

});


export class ColumnConfigFieldsFieldEditor extends EntityFieldsFieldEditor {
    protected getItemsFromValue(value: ColumnConfigConfig): any[] {
        return value ? value.columns! : [];
    }

    protected getOptions() {
        const entityName = this.props.formikProps.values[this.props.fieldDescriptor.fieldForEntityName];
        const fields = entityDescriptors[entityName].getAuthorizedFields(FIELDS_READ);
        const value = this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values) as ColumnConfigConfig;
        return Object.keys(fields).filter(field => fields[field].getAppearsInUi()).map(field => {
            const column = value.columns?.find(c => c.name === field);
            return {
                value: field,
                label: fields[field].getLabel(),
                width: column ? column.width : COLUMN_DEFAULT_WIDTH
            }
        });
    }

    protected setComposedField = (composedField: string, value: any) => {
        if (!value.columns.some((e: { width: number, name: string }) => e.name === composedField)) {
            let ccc: ColumnConfigConfig = { columns: [] }
            ccc.columns = value.columns.map((i: { width: any, name: string }) => {
                return { width: i.width, name: i.name }
            })
            ccc.columns!.push({ width: COLUMN_DEFAULT_WIDTH, name: composedField })
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, ccc)
        }
    }

    protected getValue(items: SelectExtOption[]): ColumnConfigConfig {
        let ccc: ColumnConfigConfig = { columns: [] }
        ccc.columns = items.map(i => {
            return { width: i.width, name: i.value }
        })
        return ccc;
    }

    protected getDropdownOptionFromItem(column: ColumnDefinition, entityName: string): SelectExtOption {
        return { value: column.name, label: `${getMessageForField(column.name, entityName)} [${column.name}]`, width: column.width }
    }
}

fieldEditors["columnConfigEntityFields"] = ColumnConfigFieldsFieldEditor;"../../entity_crud/EntityDescriptor""../../entity_crud/FieldType""../../entity_crud/entityCrudConstants""../../AppMetaTempGlobals""../../entity_crud/fieldRenderersEditors/EntityFieldsFieldEditor""../../entity_crud/EntityTableSimple""../../utils/Utils""../../entity_crud/fieldRenderersEditors"