import { createSliceFoundation, getBaseReducers, getBaseImpures, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import React from "react";
import { Dropdown, Button, Modal, Input, Confirm, Icon } from "semantic-ui-react";
import { sliceColumnConfigEntityEditorPage, ColumnConfigEntityEditorPage } from "./ColumnConfigEntityEditorPage";
import { apolloClient } from "@crispico/foundation-react/apolloClient";
import { FIND_BY_FILTER, FIND_BY_ID } from "./queries";
import { columnConfigService_findByFilter } from "@crispico/foundation-react/apollo-gen-foundation/columnConfigService_findByFilter";
import lodash from 'lodash';
import { EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { ColumnConfigConfig, ColumnDefinition } from "@crispico/foundation-react/entity_crud/EntityTableSimple";
import gql from "graphql-tag";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { ColumnConfigSource, ColumnConfigDropdownSource } from "./dataStructures";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { Filter } from "../CustomQuery/Filter";
import { ClientColumnConfig } from "./ClientColumnConfig";
import { ColumnConfig } from "@crispico/foundation-react/apollo-gen-foundation/ColumnConfig";
import { columnConfigService_findById } from "@crispico/foundation-react/apollo-gen-foundation/columnConfigService_findById";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { CrudGlobalSettings } from "@crispico/foundation-react/entity_crud/CrudGlobalSettings";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { EditMode } from "@crispico/foundation-react/entity_crud/EntityEditorPage";
import { CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY, ENT_DELETE, Utils } from "@crispico/foundation-react/utils/Utils";
import { customQueryService_findById } from "@crispico/foundation-react/apollo-gen-foundation/customQueryService_findById";
import { GET_CUSTOM_QUERY_BY_ID } from "../CustomQuery/queries";
import { ModalExt, Severity } from "../ModalExt/ModalExt";
import { MIN_REFRESH_RATE } from "../RefreshButton/RefreshButton";
import { createTestids } from "@famiprog-foundation/tests-are-demo";

export const currentColumnConfigId = 'currentColumnConfigId';

export const currentEditorColumnConfigId = 'currentEditorColumnConfigId';

export enum COLUMN_CONFIG_DROPDOWN_MODE {
    TABLE, TABLE_COMPACT, TABLE_COMPACT_MODAL
}

export function addColumnConfigToSessionStorage(entityCopy: ClientColumnConfig, forEditor?: boolean) {
    try {
        const sessionStorageKey = forEditor ? currentEditorColumnConfigId : currentColumnConfigId;
        let objectForSessionStorage: any = {};
        if (window.sessionStorage.getItem(sessionStorageKey)) {
            objectForSessionStorage = lodash.cloneDeep(JSON.parse(window.sessionStorage.getItem(sessionStorageKey)!));
        }
        objectForSessionStorage[entityCopy.entityName!] = entityCopy;
        window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(objectForSessionStorage));
    }
    catch (e) {
        console.log(e);
    }
}

function getColumnConfigFromSessionStorage(entityDescriptorName: string, forEditor?: boolean) {
    try {
        const sessionStorageKey = forEditor ? currentEditorColumnConfigId : currentColumnConfigId;
        let objectForSessionStorage = window.sessionStorage.getItem(sessionStorageKey);
        if (objectForSessionStorage) {
            return JSON.parse(objectForSessionStorage)[entityDescriptorName];
        } else {
            window.sessionStorage.setItem(sessionStorageKey, JSON.stringify({}));
            return undefined;
        }
    }
    catch (e) {
        console.log(e);
        return undefined;
    }
}

/**
 * @see ClientColumnConfig
 */
export function getColumnConfigForServer(clientColumnConfig: ClientColumnConfig) {
    return {
        id: clientColumnConfig.id,
        name: clientColumnConfig.name,
        entityName: clientColumnConfig.entityName,
        configJson: JSON.stringify(clientColumnConfig.configObject),
        organization: clientColumnConfig.organization,
        displayAsCards: clientColumnConfig.displayAsCards,
        autoRefreshInterval: clientColumnConfig.autoRefreshInterval
    } as ColumnConfig;
}

/**
 * @see ClientColumnConfig
 */
export function getColumnConfigForClient(columnConfig: ColumnConfig, fromCrudSettings?: boolean) {
    return {
        id: columnConfig.id,
        name: columnConfig.name,
        entityName: columnConfig.entityName,
        dirty: false,
        configObject: columnConfig.configJson ? JSON.parse(columnConfig.configJson) : { columns: [] },
        fromCrudSettings: fromCrudSettings ? true : false,
        organization: columnConfig.organization,
        displayAsCards: columnConfig.displayAsCards,
        autoRefreshInterval: columnConfig.autoRefreshInterval
    } as ClientColumnConfig;
}

export const sliceColumnConfigDropdown = createSliceFoundation(class SliceColumnConfigDropdown {

    initialState = {
        columnConfigs: [] as Array<ClientColumnConfig>,
        columnConfig: undefined as ClientColumnConfig | undefined,
        dropdownOpened: false as boolean,
        search: '',
        confirmPopup: {
            open: false,
            ccIndex: -1,
            ccId: -1
        },
        source: ColumnConfigDropdownSource.TABLE,
        editorEntitySource: ColumnConfigSource.NO_CC,
        defaultColumnConfig: undefined as ClientColumnConfig | undefined,
        crudSettingsCCId: undefined as number | undefined
    }

    nestedSlices = {
        columnConfigEntityEditorPage: sliceColumnConfigEntityEditorPage,
    }

    reducers = {
        ...getBaseReducers<SliceColumnConfigDropdown>(this),

        updateColumns(state: StateFrom<SliceColumnConfigDropdown>, columnConfigConfig: ColumnConfigConfig) {
            if (!lodash.isEqual(columnConfigConfig, lodash.cloneDeep(state.columnConfig?.configObject))) {
                state.columnConfig!.configObject = columnConfigConfig;
                addColumnConfigToSessionStorage(state.columnConfig!, state.source === ColumnConfigDropdownSource.EDITOR);
            }
        },

        initColumnConfig(state: StateFrom<SliceColumnConfigDropdown>, cc: ClientColumnConfig) {
            state.columnConfig = cc;
        },

        updateColumnConfig(state: StateFrom<SliceColumnConfigDropdown>, cc: ClientColumnConfig) {
            if (cc.id === state.crudSettingsCCId) {
                cc = {...cc, ...{fromCrudSettings: true}};
            }
            state.columnConfig = cc;
            addColumnConfigToSessionStorage(cc, state.source === ColumnConfigDropdownSource.EDITOR);
        },

        updateSearch(state: StateFrom<SliceColumnConfigDropdown>, search: string) {
            state.search = search;
        },

        updateColumnOrder(state: StateFrom<SliceColumnConfigDropdown>, event: any) {
            let currentColumns = state.columnConfig?.configObject.columns!
            var columns = currentColumns.filter((column) => {
                return column.name !== event.reorderColumn;
            });
            if (event.columnAfter) {
                var index = columns.findIndex(column => column.name === event.columnAfter);
                columns.splice(index, 0, currentColumns.find(column => column.name === event.reorderColumn) as ColumnDefinition);
            } else {
                if (columns.findIndex(column => column.name === event.reorderColumn) !== -1) {
                    columns.splice(currentColumns.length - 1, 0, currentColumns.find(column => column.name === event.reorderColumn) as ColumnDefinition)
                } else {
                    columns.push(currentColumns.find(column => column.name === event.reorderColumn) as ColumnDefinition);
                }
            }

            state.columnConfig!.configObject = { columns: columns };

            state.columnConfig!.dirty = true;
            addColumnConfigToSessionStorage(lodash.cloneDeep(state.columnConfig!), state.source === ColumnConfigDropdownSource.EDITOR);
        },

        updateColumnSize(state: StateFrom<SliceColumnConfigDropdown>, data: { width: number, name: string }) {
            let currentColumns = state.columnConfig?.configObject.columns!
            currentColumns.find(c => c.name === data.name)!.width = data.width;
            state.columnConfig!.configObject = { columns: currentColumns };

            state.columnConfig!.dirty = true;
            addColumnConfigToSessionStorage(lodash.cloneDeep({ ...state.columnConfig! }), state.source === ColumnConfigDropdownSource.EDITOR);
        },

        closeEditor(state: StateFrom<SliceColumnConfigDropdown>) {
            //@ts-ignore

            // LA: I don't understand why this is needed, but this happens before the save finishes and at that point we still need the previous cc
            //state.columnConfigEntityEditorPage = this.getSlice().nestedSlices.columnConfigEntityEditorPage.initialState

            state.search = '';

        },

    }

    impures = {
        ...getBaseImpures<SliceColumnConfigDropdown>(this),

        setDefaultColumnConfig(entityName: string, source: ColumnConfigDropdownSource) {
            this.getDispatchers().updateColumnConfig(entityDescriptors[entityName].getDefaultColumnConfig(source === ColumnConfigDropdownSource.EDITOR));
        },

        openEditor(newCC: boolean, source: ColumnConfigSource) {
            const columnConfigEntityEditorPage = { ...this.getState().columnConfigEntityEditorPage }
            this.getDispatchers().setInReduxState({ editorEntitySource: source });
            let currentColumns = this.getState().columnConfig?.configObject.columns!;
            let columnConfig = lodash.cloneDeep(this.getState().columnConfig);
            if (newCC) {
                columnConfig!.id = null;
                columnConfig!.name = columnConfig!.name + '-copy';
                columnConfig!.configObject = { columns: currentColumns };
            }

            columnConfigEntityEditorPage.modalOpen = true;
            columnConfigEntityEditorPage.entity = columnConfig;
            columnConfigEntityEditorPage.mode = EditMode.EDIT;

            this.getDispatchers().setInReduxState({ columnConfigEntityEditorPage: columnConfigEntityEditorPage })
        },

        async openDropdown(entityName: string, source: ColumnConfigDropdownSource, mode?: COLUMN_CONFIG_DROPDOWN_MODE) {
            this.loadColumnConfigs(entityName, source);
            this.getDispatchers().setInReduxState({ dropdownOpened: true });
        },

        async loadColumnConfigs(entityName: string, source: ColumnConfigDropdownSource) {
            const result = (await apolloClient.query<columnConfigService_findByFilter>({
                query: FIND_BY_FILTER,
                variables: FindByFilterParams.create().filter(Filter.create('entityName', FilterOperators.forString.equals, entityName)),
                context: { showSpinner: false }
            })).data;
            let columnConfigs = lodash.cloneDeep(result.columnConfigService_findByFilter!.results as Array<ColumnConfig>).map(cc => getColumnConfigForClient(cc));
            const defaultCC = entityDescriptors[entityName].getDefaultColumnConfig(source === ColumnConfigDropdownSource.EDITOR)
            if (columnConfigs && columnConfigs.length > 0) {
                defaultCC && columnConfigs.unshift(lodash.cloneDeep(defaultCC));
                this.getState().defaultColumnConfig && columnConfigs.unshift(lodash.cloneDeep(this.getState().defaultColumnConfig as ClientColumnConfig));
                this.getDispatchers().setInReduxState({ columnConfigs: columnConfigs });
            } else {
                if (this.getState().defaultColumnConfig) {
                    this.getDispatchers().setInReduxState({ columnConfigs: [this.getState().defaultColumnConfig as ClientColumnConfig, defaultCC] });
                } else {
                    this.getDispatchers().setInReduxState({ columnConfigs: [defaultCC] });
                }
            }
        },

        async deleteColumnConfig(id: number, entityName: string, source: ColumnConfigDropdownSource) {
            // Do not allow the user to remove the CC set in Settings.
            const crudSettingsCCFromCQId = await this.getCrudSettingsCustomId(entityDescriptors[entityName]);
            const crudSettingsCCId = entityDescriptors[entityName].entityDescriptorSettings?.defaultColumnConfig ? Number(entityDescriptors[entityName].entityDescriptorSettings?.defaultColumnConfig) : undefined;
            if (crudSettingsCCId == id || crudSettingsCCFromCQId == id) {
                Utils.showGlobalAlert({ message: _msg("ColumnConfigDropdown.errorRemovingSettingsCC"), severity: Severity.WARNING, title: _msg("general.warning") });
                return;
            }

            const replaceWithDefault = id === this.getState().columnConfig?.id;
            let columnConfigs = lodash.cloneDeep(this.getState().columnConfigs).filter(cc => cc.id !== id);
            this.getDispatchers().setInReduxState({ columnConfigs: columnConfigs });
            const removeMutation = gql(`mutation deleteEntity($id: Long){columnConfigService_deleteById(id: $id)}`);
            await apolloClient.mutate({ mutation: removeMutation, variables: { id: id } });
            if (replaceWithDefault) {
                this.setDefaultColumnConfig(entityName, source);
            }
            this.getDispatchers().columnConfigEntityEditorPage.setInReduxState({modalOpen: false});
        },

        async getColumnConfigFromCrudSettings(crudSettingsCCId: number | undefined) {
            if (crudSettingsCCId) {
                return await this.loadAndUpdateColumnConfig(crudSettingsCCId);
            }
            return undefined;
        },

        async initializeCC(entityDescriptor: EntityDescriptor, source: ColumnConfigDropdownSource) {
            const crudSettingsCCId = await this.getCrudSettingsCustomId(entityDescriptor);
            this.getDispatchers().setInReduxState({crudSettingsCCId: crudSettingsCCId});
            if (this.getState().columnConfig?.dirty && this.getState().columnConfig?.id !== -1) {
                return;
            }
            this.getDispatchers().setInReduxState({ source: source });
            const storedCC = getColumnConfigFromSessionStorage(entityDescriptor.name, source === ColumnConfigDropdownSource.EDITOR);
            const crudSettingsCC = await this.getColumnConfigFromCrudSettings(crudSettingsCCId).then(result => {return result;});
            let cc = entityDescriptor.getDefaultColumnConfig(source === ColumnConfigDropdownSource.EDITOR);
            if (storedCC && storedCC.id !== -1) {
                // if (storedCC.id === crudSettingsCC?.id) {
                //     cc = crudSettingsCC as ClientColumnConfig;
                // } else {
                //     cc = storedCC;
                // }
                cc = storedCC;
            } else {
                if (crudSettingsCC) {
                    cc = crudSettingsCC;
                }
            }
            addColumnConfigToSessionStorage(cc, source === ColumnConfigDropdownSource.EDITOR);
            this.getDispatchers().setInReduxState({ columnConfig: cc });
        },

        async getCrudSettingsCustomId(entityDescriptor: EntityDescriptor) {
            const crudSettingsCCId = entityDescriptor.entityDescriptorSettings?.defaultColumnConfig ? Number(entityDescriptor.entityDescriptorSettings?.defaultColumnConfig) : undefined;
            const crudSettingsCQId = await this.getCrudSettingsCustomQueryId(entityDescriptor);
            return crudSettingsCQId || crudSettingsCCId;
        },

        async getCrudSettingsCustomQueryId(entityDescriptor: EntityDescriptor) {
            const crudSettingsCQId = entityDescriptor.entityDescriptorSettings?.defaultCustomQuery ? Number(entityDescriptor.entityDescriptorSettings?.defaultCustomQuery) : undefined;

            // If a CQ with preferredColumnConfig is set as default in Settings, the CC from CQ has priority over the CC set in Settings.
            if (!crudSettingsCQId) {
                return null;
            }
            const customQuery = (await apolloClient.query<customQueryService_findById>({
                query: GET_CUSTOM_QUERY_BY_ID,
                variables: { id: crudSettingsCQId }
            })).data.customQueryService_findById;
            return customQuery?.preferredColumnConfig?.id;
        },

        async selectColumnConfigFromDropdown(id: number, entityDescriptor: EntityDescriptor) {
            let cc: ClientColumnConfig = lodash.cloneDeep(this.getState().columnConfigs.find(cc => cc.id === id) as ClientColumnConfig);
            cc.configObject.columns = cc.configObject.columns!.filter(c => entityDescriptor.getFieldDescriptorChain(c.name)[0])
            this.getDispatchers().updateColumnConfig(cc);
            this.getDispatchers().setInReduxState({ dropdownOpened: false, search: '' })
        },

        async revert(entityDescriptor: EntityDescriptor) {
            const cc = await this.getCurrentColumnConfig(entityDescriptor)
            cc && this.getDispatchers().updateColumnConfig(cc);
        },

        async loadAndUpdateColumnConfig(columnConfigId: number) {
            const result = (await apolloClient.query<columnConfigService_findById>({
                query: FIND_BY_ID,
                variables: { id: columnConfigId },
                context: {showSpinner: false}
            })).data.columnConfigService_findById;
            if (result) {
                return getColumnConfigForClient(result as ColumnConfig, true);
            }
            return undefined;
        },

        async getCurrentColumnConfig(entityDescriptor: EntityDescriptor) {            
            if (this.getState().columnConfig!.id === -1) {
                return entityDescriptor.getDefaultColumnConfig();
            }
            const result = (await apolloClient.query<columnConfigService_findById>({
                query: this.getSlice().nestedSlices.columnConfigEntityEditorPage.impures.getLoadQueryParams().loadQuery,
                variables: { id: this.getState().columnConfig!.id }
            })).data.columnConfigService_findById;
            if (result) {
                return getColumnConfigForClient(result);
            }
        },

        async onApply(entity: ClientColumnConfig, entityDescriptor: EntityDescriptor) {
            let cc: ClientColumnConfig;
            let dirty = false;
            if (entity.dirty) {
                cc = entity;
                dirty = true;
            } else {
                cc = lodash.cloneDeep(entity);
                const currentColumnConfig = await this.getCurrentColumnConfig(entityDescriptor);
                dirty = lodash.isEqual(cc, currentColumnConfig) ? false : true;
            }

            if (cc.name !== this.getState().columnConfig?.name) {
                this.getDispatchers().setInReduxState({ columnConfig: { ...this.getState().columnConfig!, name: cc.id !== -1 && cc.id !== -2 ? cc.name : this.getState().columnConfig!.name, dirty: true, fromCrudSettings: cc.id === this.getState().crudSettingsCCId} })
            }
            this.getDispatchers().updateColumns(cc.configObject);
            this.getDispatchers().setInReduxState({ columnConfig: { ...this.getState().columnConfig!, displayAsCards: entity.displayAsCards, autoRefreshInterval: entity.autoRefreshInterval < MIN_REFRESH_RATE ? 0 : entity.autoRefreshInterval, dirty: dirty } })
            this.getDispatchers().closeEditor();
        }
    }

});

export type ColumnConfigDropdownProps = PropsFrom<typeof sliceColumnConfigDropdown> & {entityDescriptor: EntityDescriptor, source: ColumnConfigDropdownSource};

export const columnConfigDropdownTestids = createTestids("ColumnConfigDropdown", {
    delete: "", deleteCancel: "", deleteOk: "",
    fieldLayout: "", option: "", options: ""
});

export class ColumnConfigDropdown<P extends ColumnConfigDropdownProps> extends React.Component<P>  {

    inputRef = React.createRef<any>();

    constructor(props: P) {
        super(props);
        this.props.dispatchers.initializeCC(this.props.entityDescriptor, this.props.source);
    }

    getOptions() {
        let options: {key: string | null, value: string | null, text: string | null, id: number, disabled: boolean | undefined, selected: boolean | undefined, onClick: () => void}[] = [];
        if (this.props.columnConfigs.length > 0) {
            options = this.props.columnConfigs.filter(cc => {
                if (this.props.search) {
                    return cc.name?.toUpperCase().includes(this.props.search.toUpperCase());
                } else {
                    return true;
                }
            }).map(cc => { return { key: cc.name, value: cc.name, text: cc.name, id: cc.id, disabled: this.props.columnConfig && this.props.columnConfig.id === cc.id, selected: this.props.columnConfig && this.props.columnConfig.id === cc.id, onClick: () => { this.props.dispatchers.selectColumnConfigFromDropdown(cc.id, this.props.entityDescriptor) } } });
        }
        return options;
    }

    onSearchChange = (e: any) => this.props.dispatchers.setInReduxState({ search: e.target.value });

    onOpenDropdown = (e: any) => {
        if (!AppMetaTempGlobals.appMetaInstance.hasPermission(CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY) || this.props.dropdownOpened) {
            return;
        }
        this.props.dispatchers.openDropdown(this.props.entityDescriptor.name, this.props.source);
        setTimeout(() => {
            if (this.inputRef.current) {
                this.inputRef.current.autocomplete = 'off';
                this.inputRef.current.focus();
            }
        }, 100);
    }

    onCloseDropdown = (e: any, d: any) => {
        if (e === undefined && this.props.dropdownOpened) {
            this.props.dispatchers.setInReduxState({ dropdownOpened: false });
        }
    }

    onBlur = (e: any) => {
        if (e.relatedTarget && e.relatedTarget.id === 'ColumnConfigDropdown_inputID') { return; }
        this.props.dispatchers.setInReduxState({ dropdownOpened: false, search: '' })
    }

    onClose = (e: any) => {
        if (e && e.key === 'Escape') {
            this.props.dispatchers.setInReduxState({ dropdownOpened: false, search: '' })
        }
    }

    // CZ: I wanted to use Form to use the onSubmit handler, but inside Dropdown, pressing 'enter' didnt fire 'onSubmit' so I used this approach
    onKeyDown(e: any, id: any) {
        if (e.key === 'Enter') {
            this.props.dispatchers.selectColumnConfigFromDropdown(id, this.props.entityDescriptor);
        }
    }

    openConfirm = (e: any, index: number, id: number) => {
        e.stopPropagation();
        this.props.dispatchers.setInReduxState({ confirmPopup: { open: true, ccIndex: index, ccId: id } })
    }

    closeConfirm = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.dispatchers.setInReduxState({ confirmPopup: { open: false, ccIndex: -1, ccId: -1 } })
    }

    onConfirm = (e: any) => {
        if (this.isDeleteAuthorized()) {
            this.props.dispatchers.deleteColumnConfig(this.props.confirmPopup.ccId, this.props.entityDescriptor.name, ColumnConfigDropdownSource.TABLE);
        }
        this.closeConfirm(e);
        setTimeout(() => {
            document.getElementById('ColumnConfigDropdown_inputID')?.focus()
        });
    }

    isDeleteAuthorized(): boolean {
        const entityDescriptorName = this.props.dispatchers.getSlice().nestedSlices.columnConfigEntityEditorPage.entityDescriptor.name;
        const permission = Utils.pipeJoin([ENT_DELETE, entityDescriptorName]);
        return AppMetaTempGlobals.appMetaInstance.hasPermission(permission, true);
    }

    renderMenuEntries(options: any[], showDeleteButton?: boolean) {
        return options.map((option, index) => (
            <Dropdown.Item data-testid={columnConfigDropdownTestids.option + "_" + option.text} {...option} content={
                <div className="DropdownMenuItemWrapper_ColumnConfig"><div className="DropdownMenuItem">
                    <label className="DropdownMenuItem_Name">{option.text}</label>
                    {showDeleteButton && option.id !== -1 && <Button data-testid={columnConfigDropdownTestids.delete + "_" + option.text} className='AddCustomQueryDropdown_itemButton DropdownMenuItem_Button' color='red' onClick={(e) => this.openConfirm(e, index, option.id)} floated='right' size='mini' icon="delete" />}
                </div></div>
            } />
        ))
    }

    renderMenu() {
        const options = this.getOptions();
        return <Dropdown.Menu className="DropdownMenu">
            <Dropdown.Header icon='table' content={<h4 className="DropdownMenuHeader">{_msg('ColumnConfigDropdown.savedColumnConfigs')}</h4>} />
            <Dropdown.Divider />
            <Dropdown.Header>
                <Input ref={this.inputRef} fluid onKeyDown={(e: any) => { options.length > 0 && this.onKeyDown(e, options[0].id) }} placeholder={_msg('ColumnConfigDropdown.searchLabel')} id='ColumnConfigDropdown_inputID' icon='search' iconPosition='left' value={this.props.search} onChange={this.onSearchChange} ></Input>
            </Dropdown.Header>
                {this.renderMenuEntries(options, true)}
            <ModalExt
                severity={Severity.INFO}
                open={this.props.confirmPopup.open}
                header={_msg("general.info")}
                content={_msg('ColumnConfigDropdown.confirmMessage')}
                onClose={this.closeConfirm}
                actions={[
                    <Button data-testid={columnConfigDropdownTestids.deleteCancel} key="cancel" onClick={this.closeConfirm}>{_msg("general.cancel")}</Button>,
                    <Button data-testid={columnConfigDropdownTestids.deleteOk} key="ok" primary onClick={this.onConfirm}>{_msg("general.ok")}</Button>
                ]}
            />
        </Dropdown.Menu>
    }

    render() {
        let value = this.props.columnConfig ? this.props.columnConfig!.name! : '';
        if (this.props.columnConfig?.fromCrudSettings) {
            value = _msg('general.default') + " (" + value + ")";
        }
        value = this.props.columnConfig?.dirty ? value + '*' : value;
        return (
            <>
                {AppMetaTempGlobals.appMetaInstance.showCrudButtons.showColumnConfigButton &&
                    <div>
                        <>{_msg("ColumnConfig.label") + ":"}&nbsp;&nbsp;</>
                        <Button.Group>
                            <Button data-testid={columnConfigDropdownTestids.fieldLayout + "_" + this.props.source} onClick={(e) => this.props.dispatchers.openEditor(false, ColumnConfigSource.EDIT)}><Icon name="table" className="icon small-margin-right" /> {value}</Button>
                            <Dropdown data-testid={columnConfigDropdownTestids.options} onClose={this.onClose} onBlur={this.onBlur} onOpen={this.onOpenDropdown} open={this.props.dropdownOpened}
                                upward={false} floating className='button icon DropdownMenu_Darker' >
                                {this.renderMenu()}
                            </Dropdown>
                        </Button.Group>
                    </div>
                }
                <ColumnConfigEntityEditorPage {...this.props.columnConfigEntityEditorPage} dispatchers={this.props.dispatchers.columnConfigEntityEditorPage}
                    entityDescriptor={this.props.entityDescriptor} embeddedMode={true} renderHeaderParams={{columnConfig: false}}
                    updateColumnConfig={(cc: ClientColumnConfig) => this.props.dispatchers.updateColumnConfig(cc)} closeEditor={this.props.dispatchers.closeEditor}
                    modalProps={{ onClose: this.props.dispatchers.closeEditor, open: this.props.columnConfigEntityEditorPage.modalOpen, centered: false }}
                    openConfirm={this.openConfirm}
                    // LA: APPLY for SAVE_AS is disabled for the moment due to issues with duplicated ids in react state, to be addressed
                    onApply={this.props.editorEntitySource === ColumnConfigSource.EDIT /*|| this.props.editorEntitySource === ColumnConfigSource.SAVE_AS*/ ? (entity: any) => this.props.dispatchers.onApply(entity, this.props.entityDescriptor) : undefined}
                    onSave={() => this.props.dispatchers.setInReduxState({ columnConfig: { ...this.props.columnConfig!, dirty: false }}) }
                />
            </>
        );
    }
};"../../reduxHelpers""../../apolloClient""../../apollo-gen-foundation/columnConfigService_findByFilter""../../entity_crud/EntityDescriptor""../../entity_crud/EntityTableSimple""../../entity_crud/entityCrudConstants""../../apollo-gen-foundation/ColumnConfig""../../apollo-gen-foundation/columnConfigService_findById""../../entity_crud/FindByFilterParams""../../entity_crud/CrudGlobalSettings""../../AppMetaTempGlobals""../../entity_crud/EntityEditorPage""../../utils/Utils""../../apollo-gen-foundation/customQueryService_findById"