import _ from "lodash";
import { PuppeteerMessageWaitingForNextStep, PuppeteerMessageWriteMarkdownReport } from "../common-lib-and-node/common-types";
import { FINISH_WAITING_CALLBACK_ERROR_DURING_WAITING, TestsAreDemoMaster } from "./TestsAreDemoMaster";
import { SourceCodeInfo } from "./TraceMapCache";
import { Utils } from "../copied/Utils";
import { processComment } from "./feature-book/FeatureBookUi";

interface ReportSourceFile {
    sourceCode: string;
    screenshots: { [line: number]: ReportScreenshot[] };
}

interface ReportScreenshot {
    invocationIndex: number;
    file: string;
}

export class Report {
    protected files: { [sourceFile: string]: ReportSourceFile } = {};
    protected lastInvocationIndex = 0;
    visibleLinesForFirstAndLastChunk = 10;
    protected lastSourceCodeState?: SourceCodeInfo;
    protected waitForErrorPromise?: Promise<string>;
    protected waitForErrorPromiseResolve?: (error: string) => void;
    protected error?: string;
    protected preparingToSendScreenshot?: PuppeteerMessageWaitingForNextStep;

    constructor(protected master: TestsAreDemoMaster) {
        // nop
    }

    onShowSpotlight(sourceCodeState: SourceCodeInfo | undefined, screenshotFileName: string) {
        if (!sourceCodeState) {
            if (!this.lastSourceCodeState) {
                // the source map code failed. Fallback: we'll add the screenshots, w/o code
                this.lastSourceCodeState = { sourceFile: "Source file not available", sourceCode: "", sourceLine: 1, sourceColumn: 1 };
            } // else: happens for the "type" action. We do intercept the code, but the stack trace doesn't lead any more to the test. 
            // For the moment, the workaround to use the last line: seems OK

            sourceCodeState = this.lastSourceCodeState;
        } else {
            this.lastSourceCodeState = sourceCodeState;
        }
        const { sourceFile, sourceCode, sourceLine } = sourceCodeState;

        let reportSourceFile = this.files[sourceFile];
        if (!reportSourceFile) {
            reportSourceFile = { sourceCode, screenshots: {} };
            this.files[sourceFile] = reportSourceFile;
        }
        let screenshots = reportSourceFile.screenshots[sourceLine];
        if (!screenshots) {
            screenshots = [];
            reportSourceFile.screenshots[sourceLine] = screenshots;
        }
        screenshots.push({ invocationIndex: ++this.lastInvocationIndex, file: screenshotFileName });
    }

    waitForError() {
        if (this.waitForErrorPromise) {
            throw new Error("Illegal! waitForError() was called, errorArrived() was NOT called, and then waitForError() was called again");
        }
        this.waitForErrorPromise = new Promise(resolve => this.waitForErrorPromiseResolve = resolve);
    }

    errorArrived(error: string) {
        this.waitForErrorPromiseResolve?.(error);
    }

    async getError(): Promise<string | undefined> {
        // respectively: error arrived, error will arrive, no error
        return this.error || this.waitForErrorPromise || undefined;
    }

    async printMarkdown(dir: string) {
        const c = Utils.substringBefore(dir, ".", true);
        const f = Utils.substringAfter(dir, ".", true);
        const clazz = this.master.state.tests.find(d => d.name === c);
        const func = clazz?.functions.find(d => d.functionName === f);
        let result = `
# ${dir}()

### ${func?.scenario}
`;
        if (func?.comments) {
            result += "\n```\n";
            func?.comments?.forEach(c => {
                processComment(c).forEach(l => { result += `// ${l.trim()}\n`; });
            });
            result += "```\n";
        }
        result += `
---
`;
        const error = await this.getError();
        (error !== undefined) && (result += `
## The following error was caught while running the test:

\`\`\`
${error}
\`\`\`

---

`);
        result +=
            `
There are ${this.lastInvocationIndex} screenshots. [Go to first](#screenshot-1)
`;

        for (let sourceFile in this.files) {
            const reportSourceFile = this.files[sourceFile];
            result += `
## ${sourceFile}
`;
            const screenshotsAsNumbers = Object.getOwnPropertyNames(reportSourceFile.screenshots).map(pn => parseInt(pn));
            screenshotsAsNumbers.sort((a, b) => a - b);

            const lines = reportSourceFile.sourceCode.split("\n");
            const digits = lines.length.toString().length;
            lines.forEach((line, i) => lines[i] = "/*" + (i + 1).toString().padStart(digits) + "*/" + line);

            let previousLine = 0;

            // hide lines at the beginning
            const firstLine = screenshotsAsNumbers.length > 0 && screenshotsAsNumbers[0];
            if (firstLine !== false && firstLine > this.visibleLinesForFirstAndLastChunk) {
                previousLine = firstLine - this.visibleLinesForFirstAndLastChunk;
                result += this.printChunk(dir, lines, 0, previousLine);
            }

            // print chunks = code + screenshots
            for (let line of screenshotsAsNumbers) {
                result += this.printChunk(dir, lines, previousLine, line, reportSourceFile.screenshots[line]);
                previousLine = line;
            }

            // hide lines at the end
            result += this.printChunk(dir, lines, previousLine, undefined);
        }
        return result;
    }

    protected printChunk(dir: string, lines: string[], startIndex: number, endIndex: number | undefined, screenshots?: ReportScreenshot[]) {
        let result = `${screenshots ? "" : "\n<details><summary>Click to expand the hidden lines of code</summary>\n"}
\`\`\`tsx
${lines.slice(startIndex, endIndex).join("\n")}
\`\`\`
${screenshots ? "" : "\n</details>\n"}`;
        if (!screenshots) { return result; }

        for (let screenshot of screenshots) {
            result += `
<table><tr>
<td>

### Screenshot ${screenshot.invocationIndex}

${screenshot.invocationIndex <= 1 ? "" : `[Go to previous](#screenshot-${screenshot.invocationIndex - 1})`}${screenshot.invocationIndex <= 1 || screenshot.invocationIndex >= this.lastInvocationIndex ? "" : " | "
                }${screenshot.invocationIndex >= this.lastInvocationIndex ? "" : ` [Go to next](#screenshot-${screenshot.invocationIndex + 1})`}

<details><summary>Click to expand full image</summary>

${dir}/${screenshot.file}.png <br/>
![](${screenshot.file}.png)

</details>
</td>
<td>

![](${screenshot.file}_small.png)

</td>
</tr></table>
`;
        }
        return result;
    }

    prepareToSendScreenshotToPuppeteer(message: PuppeteerMessageWaitingForNextStep) {
        if (this.preparingToSendScreenshot) {
            throw new Error("prepareToSendScreenshotToPuppeteer() called twice. First call = " + JSON.stringify(this.preparingToSendScreenshot) + ", last call = " + JSON.stringify(message));
        }
        this.preparingToSendScreenshot = message;
    }

    async sendScreenshotToPuppeteer(message: PuppeteerMessageWaitingForNextStep) {
        if (!_.isEqual(this.preparingToSendScreenshot, message)) {
            throw new Error("sendScreenshotToPuppeteer() was called w/ a different arg as prepareToSendScreenshotToPuppeteer(). First call = " + JSON.stringify(this.preparingToSendScreenshot) + ", last call = " + JSON.stringify(message));
        }
        if (this.master.slave.lastElementCaptured) {
            const rectSrc = this.master.slave.lastElementCaptured.getBoundingClientRect();
            message.rect = { x: rectSrc.x, y: rectSrc.y, width: rectSrc.width, height: rectSrc.height };
        }
        // this is how the puppeteer script will know that know is the time to take
        // a screenshot, and then "press" on next
        this.master.sendMessageToPuppeteer("waitingForNextStep", message);
        this.preparingToSendScreenshot = undefined;
    }

    async sendMarkdownToPuppeteer(dir: string) {
        let resolve: Function;
        const f = async () => {
            this.master.sendMessageToPuppeteer("writeMarkdownReport", {
                dir,
                markdown: await this.printMarkdown(dir)
            } as PuppeteerMessageWriteMarkdownReport);
            resolve?.apply(null);
        }
        if (this.master.finishWaitingCallbackTestAndScreenshotNumber) {
            // call to puppeteer still in progress
            if (this.master.finishWaitingCallbackTestAndScreenshotNumber === FINISH_WAITING_CALLBACK_ERROR_DURING_WAITING) { // set in ...Reporter
                // the test has failed; some async code, e.g. something within a `setTimeout()`
                // let's wait until puppeteer returns; then we'll continue
                // we want to stop the communication w/ puppeteer for the moment, to let it properly finish its
                // current job (i.e. printing images). Not doing so, we'll start 2 "pseudo"-threads
                console.log("We want to print the Markdown report. But the call from puppeteer didn't return. So we'll wait, and then we'll continue w/ writing the report");
                this.master.setState({ finishWaitingCallback: f });
                return new Promise<void>(r => resolve = r);
            } else {
                throw new Error("Trying to write the Markdown report, but puppeteer didn't return yet, to finish our waiting (case 1)");
            }
        } else if (this.preparingToSendScreenshot) {
            if (this.master.slave.reporterFromSlaveToMaster.testFailed) { // set in ...Reporter
                // a call in TADSlave.showSpotlight() started, but didn't finished. Probably now that methods does the async stuff related to source map
                console.log("We want to print the Markdown report. But the screenshot is in progress in JS/not yet sent to puppeteer. So we'll wait for the function to finish in JS, " +
                    "then we'll wait for puppeteer, and then we'll continue w/ writing the report");
                this.master.setState({ finishWaitingCallback: f });
                return new Promise<void>(r => resolve = r);
            } else {
                throw new Error("Trying to write the Markdown report, but puppeteer wasn't called + didn't return yet, to finish our waiting (case 2)");
            }
        } else {
            await f();
        }
    }
}