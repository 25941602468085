import { SelectExtOption, SelectExtMenuProps, SelectExt, SelectExtProps } from "@crispico/foundation-react/components/selectExt/SelectExt";
import { CrudViewer } from "@crispico/foundation-react/entity_crud/CrudViewer";
import { EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { components, MenuProps } from "react-select";
import { Container, Table } from "semantic-ui-react";
import { entityDescriptors } from "../entityCrudConstants";
import { FieldEditorProps } from "../fieldRenderersEditors";
import { EntityFieldsFieldEditor, EntityFieldsFieldEditorProps } from "./EntityFieldsFieldEditor";

const CrudViewerAsDropdownMenu = (entityDescriptor: EntityDescriptor, entity: any, selected: SelectExtOption[], inputValue: string, onRowClick?: (field: string) => void, showOnlyLabelColumn?: boolean) => 
    (p: SelectExtMenuProps) => (props: MenuProps<any>) => {
        const selectedValues = selected.map(s => s.value);
        const optionsFilteredBySearch = props.options.filter(o => {
            const input = inputValue.toLowerCase();
            const label = o.label.toLowerCase();
            const value = o.value.toLowerCase();
            if (label && label !== value && label.indexOf(input) > -1) {
                return true;
            } else if (value && value.indexOf(input) > -1) {
                return true;
            } else {
                return false;
            }
        });
        const optionsWithoutSelected = optionsFilteredBySearch.map(o => o.value).filter(o => !selectedValues.includes(o));

        return (
            <components.Menu {...props}>
                <Container className="CrudViewerAsDropdown">
                    {p.appendDoneMenuEntry ?
                        <Table attached="top" celled padded compact style={{ borderBottom: "0px" }}><Table.Body>
                            <Table.Row className="SelectorExt_DoneTableRow" onClick={() => p.onDoneMenuEntryClick && p.onDoneMenuEntryClick()}>
                                <Table.Cell key="done"><div className="flex-container-row flex-wrap">{_msg("DropdownExt.done")}</div></Table.Cell>
                            </Table.Row>
                        </Table.Body></Table> : null}
                    <CrudViewer entityDescriptor={entityDescriptor} entity={entity} fields={optionsWithoutSelected} showOnlyLabelColumn={showOnlyLabelColumn} onRowClick={onRowClick} {...{ tableProps: { attached: p.appendDoneMenuEntry ? "bottom" : undefined } }} />
                </Container>
            </components.Menu>
        );
    }

export type EntityFieldsWithCrudViewerFieldEditorProps = EntityFieldsFieldEditorProps & {
    entity?: any;
    menuPosition?: string;
    showOnlyLabelColumn?: boolean;
    onFieldsChange?: (field: string, value: string) => void
}

export class EntityFieldsWithCrudViewerFieldEditor extends EntityFieldsFieldEditor<EntityFieldsWithCrudViewerFieldEditorProps> {

    componentDidUpdate(prevProps: FieldEditorProps) {
        const { formikProps, fieldDescriptor } = this.props;
        if (prevProps.formikProps.values[fieldDescriptor.name] !== formikProps.values[fieldDescriptor.name]) {
            this.props.onFieldsChange?.call(null, fieldDescriptor.name, formikProps.values[fieldDescriptor.name]);
        }
    }

    renderButtons() {
        return <></>;
    }

    render() {
        const { entityName, selected, options, inputValue } = this.getDropdownProps();
        const ed = entityDescriptors[entityName];
        const additionalProps = { } as SelectExtProps;
        additionalProps.customMenu = CrudViewerAsDropdownMenu(ed, this.props.entity, selected, inputValue, (field) => {
            const value = this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values);
            const items = this.getItemsFromValue(value);
            if (!items.includes(field)) {
                this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, items.concat([field]).join(","));
            }
            this.setState({ inputValue: "" });
        }, this.props.showOnlyLabelColumn);
        return this.renderMain(selected, options, inputValue, additionalProps);
    }
}
"../../components/selectExt/SelectExt""../CrudViewer""../EntityDescriptor"