import React, { ReactNode } from "react";
import { TestsAreDemoFunctions } from "./TestsAreDemoFunctions";
import { TestsAreDemoMaster } from "./TestsAreDemoMaster";
import { TestsAreDemoSlave } from "./TestsAreDemoSlave";

interface Props {
    app: ReactNode,
    importTestsCallback: () => Promise<any>,
    createTestsAreDemoFunctions?: () => TestsAreDemoFunctions,

    /**
     * Set to `true` for projects that don't use Semantic UI React natively. Otherwise, if your app already 
     * includes `semantic-ui-css/semantic.min.css` (or maybe a customized LESS css): don't set it (the default
     * is `false`).
     */
    importSemanticUiCss: boolean

    serverUrlPrefix: string
};

/**
 * Is the root of the application. Based on the query string, it renders the main app, the master or the slave.
 */
export class TestsAreDemoAppWrapper extends React.Component<Props> {

    static defaultProps: Partial<Props> = {
        importSemanticUiCss: false,
        serverUrlPrefix: ""
    }

    protected static INSTANCE: TestsAreDemoAppWrapper;

    async componentDidMount() {
        if (TestsAreDemoAppWrapper.INSTANCE) {
            throw new Error("Multiple instances of TestsAreDemoAppWrapper are not allowed.");
        }
        TestsAreDemoAppWrapper.INSTANCE = this;
        if (this.props.importSemanticUiCss) {
            // @ts-ignore
            import("semantic-ui-css/semantic.min.css");
        }
    }

    render() {
        const props = this.props;
        const search = new URLSearchParams(window.location.search);
        if (search.has("TestsAreDemo")) {
            return <TestsAreDemoMaster puppeteer={search.has("puppeteer")} forceStepByStep={search.has("forceStepByStep")} serverUrlPrefix={props.serverUrlPrefix} />
        } else if (search.has("testsAreDemoSlave")) {
            return <TestsAreDemoSlave importTestsCallback={props.importTestsCallback} createTestsAreDemoFunctions={props.createTestsAreDemoFunctions} />
        } else {
            return props.app; // if using a functional comp (instead of a class comp), it doesn't accept ReactNode as return type; I think there's some kind of error
        }
    }
}