import React, { useEffect, useState } from "react";
import { Button, Input, Label, Segment } from "semantic-ui-react";
import { ClassLabel, FailureLabel, MethodLabel, ReportLabel, TestsTreeItem } from "./FeatureBookServer";
import { MarkdownExt } from "../MarkdownExt";

export const TestsTreeComponent = (props: { linearizedItems?: TestsTreeItem[], selectedIndexOnce?: number, onSelect?: (item: TestsTreeItem) => void, onSetCurrent?: (item: TestsTreeItem) => void }) => {
    const [hovered, setHovered] = useState(-1);
    const [selected, setSelected] = useState(-1);
    const [filter, setFilter] = useState("");

    useEffect(() => setSelected(props.selectedIndexOnce || -1), [props.selectedIndexOnce]);

    let lastMatchIndent = -1;

    return (<div className="FeatureComponent_wrapper">
        {props.onSetCurrent && <>
            <Input icon="search" iconPosition="left" placeholder="Search..." value={filter} action={!filter ? undefined : { icon: "delete", color: "red", onClick: () => setFilter("") }}
                onChange={(e, data) => setFilter(data.value)} /> (For found items, shows children, but doesn't show parents)
        </>}
        <Segment.Group>
            {props.linearizedItems?.map((item, i) => {
                if (filter) {
                    if (lastMatchIndent < 0
                        || item.indent <= lastMatchIndent) { // i.e. a sibbling or a parent; so the "match" broke; let's cancel it
                        lastMatchIndent = -1;
                        if (item.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
                            // we have a match; this and children will be shown
                            lastMatchIndent = item.indent;
                        } // no match => this item won't be displayed
                    } // else a child of a previous match => we'll display it
                    if (lastMatchIndent < 0) {
                        return null;
                    }
                }
                return <Segment key={i} secondary={i === hovered} tertiary={i === selected}
                    onMouseEnter={() => setHovered(i)} onMouseLeave={() => setHovered(-1)} onClick={() => {
                        setSelected(i);
                        props.onSelect?.(item);
                    }}>
                    <Padding width={item.indent} />
                    {item.kind === "CLASS" ? <ClassLabel /> : <MethodLabel />}&nbsp;
                    {item.report && <><ReportLabel />&nbsp;</>}
                    {item.failure && <><FailureLabel />&nbsp;</>}
                    <span style={{ color: item.kind === "METHOD" ? "#b1b1b1" : undefined }}>{item.name}</span> <i style={{ color: "#2185d0" }}><MarkdownExt>{item.description}</MarkdownExt></i>
                    {props.onSetCurrent && <Button size="mini" floated="right" primary content="Set current" onClick={() => props.onSetCurrent?.(item)} />}
                </Segment>
            })}
        </Segment.Group>
    </div>)
}

const Padding = ({ width }: { width: number }) => <>{new Array(width * 4).fill(0).map((e, i) => <React.Fragment key={i}>&nbsp;</React.Fragment>)}</>