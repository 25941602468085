import chai from "chai";
import chaiSubset from "chai-subset";
import ErrorStackParser from "error-stack-parser";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import ReactFloater from "react-floater";
import { Divider, Label, Modal } from "semantic-ui-react";
import { PuppeteerMessageWaitingForNextStep } from "../common-lib-and-node/common-types";
import { MarkdownExt } from "./MarkdownExt";
import { Report } from "./Report";
import { ReporterFromSlaveToMaster } from "./ReporterFromSlaveToMaster";
import { TraceMapCache } from "./TraceMapCache";
import { TestsAreDemoFunctions, setTad, tad } from "./TestsAreDemoFunctions";
import { StepByStepMode, TestsAreDemoMaster } from "./TestsAreDemoMaster";
import { UiApiHelper } from "./uiApi/UiApiHelper";

/**
 * Made a data structure (instead of a simple message) because maybe in the future we'll
 * want to have additional graphical params, e.g. color, etc.
 */
export interface SpotlightParams {
    message?: ReactNode;
    focusOnLastElementCaptured: boolean;
}

interface SlaveState {
    spotlightParams?: SpotlightParams,
    inspectedElement?: HTMLElement,
    inspectedElementPopup?: ReactElement,
    hideSpotlightTimerInProgress?: boolean,
    invokeAfterHideSpotlightTimer?: Function,
}

interface SlaveProps {
    /**
     * We force the tests to be imported async (via this callback), to make sure that they are imported only by the "slave".
     * Importing tests in "master" or in normal app, would trigger importing from "instrumentedFunctions", which throws error
     * if not "slave" mode.
     */
    importTestsCallback: () => Promise<any>;

    createTestsAreDemoFunctions?: () => TestsAreDemoFunctions;
}

declare global {
    namespace Mocha {
        interface TestFunction {
            onlyUntil: ExclusiveTestFunction;
        }
    }
}

export enum DemoForEndUser { SHOW, HIDE, HIDE_NEXT }

/**
 * Runs the tests (via `mocha.run()`) and displays the spotlight.
 */
export class TestsAreDemoSlave extends React.Component<SlaveProps, SlaveState> {

    static _INSTANCE: TestsAreDemoSlave;

    state: SlaveState = {};

    master!: TestsAreDemoMaster;

    uiApiHelper = UiApiHelper.INSTANCE;

    /**
     * I don't store this in the state, because it's a high traffic item. And I'm affraid
     * that the state is proxified (e.g. to output warnings in the dev mode), hence slower.
     */
    stepByStep = false;

    /**
     * Idem as `stepByStep`. W/ the difference that there it's a precaution. And here is a certitude,
     * because the access to this attribute is in write mode. So every set would generate additional logic
     * being executed.
     */
    lastElementCaptured?: HTMLElement | null;
    protected nextElementCaptured?: HTMLElement | null;

    lastElementCapturedNumber!: number;
    lastElementCapturedFileName?: string | null;
    testIdChainToCountForFileName: { [testId: string]: number } = {};

    /**
     * Recreated before each test, IF puppeteer mode AND screenshots mode (aka forceStepByStep)
     */
    report?: Report;

    static mochaInitialized = false;
    protected runRequestedWhileMochaInitializing = false;
    reporterFromSlaveToMaster!: ReporterFromSlaveToMaster;

    currentComment: ReactNode;
    currentCommentSlideIndex?: number;

    onlyCalled = false;
    onlyUntilCalled = false;
    demoForEndUser = DemoForEndUser.SHOW;
    demoForEndUserSavedCurrentCommentBeforeHide: ReactNode;

    setLastElementCaptured(lastElementCaptured: HTMLElement | null) {
        if (this.state.hideSpotlightTimerInProgress) {
            this.nextElementCaptured = lastElementCaptured;
        } else {
            this.lastElementCaptured = lastElementCaptured;
        }
    }

    constructor(props: SlaveProps) {
        super(props);
        this.onDocumentMouseMove = this.onDocumentMouseMove.bind(this);
        this.onDocumentClick = this.onDocumentClick.bind(this);
    }

    /**
     * After the execution, either the connection to the parent window / TestsAreDemoSlave is correctly
     * established or an error is thrown.
     */
    async componentDidMount() {
        // @see TestsAreDemoMaster.componentDidMount(); curious thing though, the double invocation didn't happen here as well
        if (TestsAreDemoSlave._INSTANCE) {
            throw new Error("Cannot create more than one instances of TestsAreDemoSlave");
        }
        TestsAreDemoSlave._INSTANCE = this;

        if (!window.parent?.testsAreDemoMaster) {
            throw new Error("Looking at the parent of this IFrame to find TestsAreDemoMaster. But was not found.");
        }
        this.master = window.parent.testsAreDemoMaster;
        this.master.slave = this;

        const tad = this.props.createTestsAreDemoFunctions?.() || new TestsAreDemoFunctions();
        setTad(tad);

        this.initMocha();
    }

    protected async initMocha() {
        // In some webpack based setups, the polyfill (for node) cf. below is not enough:
        // resolve: {
        //     fallback: {
        //       "stream": false,
        // These are added via: add patches until it works; we didn't find a doc to do it.
        if (!window.process) {
            // @ts-ignore
            window.process = { browser: true }
        }
        if (!window.Buffer) {
            window.Buffer = {
                // @ts-ignore
                isBuffer: () => false
            }
        }

        // for a "normal" import, @ts-ignore wasn't needed; however it seems to be needed here
        // @ts-ignore
        // await import("script-loader!mocha/mocha-es2018"); // eslint-disable-line import/no-webpack-loader-syntax
        await import("mocha"); // eslint-disable-line import/no-webpack-loader-syntax
        mocha.setup({
            ui: "bdd", // I don't exactly known what this is; but is needed
            timeout: 1000000, // for the moment a high value, for the "step by step case"
        });

        const that = this;
        mocha.reporter(class extends Mocha.reporters.Base {
            constructor(runner: Mocha.Runner, options: Mocha.MochaOptions) {
                super(runner, options);
                that.reporterFromSlaveToMaster = that.master.onMochaReporterCreated(this, runner, options);
            }
        });

        // @ts-ignore 
        mocha.cleanReferencesAfterRun(false); // needed to be able to rerun the tests
        // This doesn't work. I didn't look if we could do something or not.
        // mocha.checkLeaks();

        this.monkeyPatchMocha();

        // Configure global "hooks" for mocha

        beforeEach(() => {
            this.onlyUntilCalled = false;
            this.demoForEndUser = DemoForEndUser.SHOW;
            this.lastElementCapturedNumber = 0;
            this.testIdChainToCountForFileName = {};
            if (that.master.props.puppeteer && that.master.props.forceStepByStep) {
                this.report = new Report(this.master);
            }
        });

        afterEach(async () => {
            // When this is called, the code from the reporter (which un-indents "path") has already been executed.
            // Initially, because of this, we had this code in a runner callback defined above. But then came the
            // need for this to be async because of the error decoding. The runner callback doesn't wait after async callbacks.
            // But hooks (like this) do.
            await this.report?.sendMarkdownToPuppeteer(that.reporterFromSlaveToMaster.previousDir);
        });

        TestsAreDemoSlave.mochaInitialized = true;

        await this.props.importTestsCallback();

        // copied from common.test.ts
        chai.use(chaiSubset);

        if (this.runRequestedWhileMochaInitializing) {
            this.run();
        }
        // I don't see any reason not to activate this. It's common to have long stack traces for errors that begin from the test
        // file. And w/o this, we won't always see the place where the error originated from
        Error.stackTraceLimit = Infinity;
    }

    protected monkeyPatchMocha() {
        const oldIt = globalThis.it;
        if ((oldIt as any).onlyUntil) {
            // I was affraid of HMR; that's why I put this as a fail fast mechanism; however it didn't ever trigger
            throw new Error("'it' already monkeypatched");
        }
        const it: typeof oldIt = (...args: any) => {
            if (!this.onlyUntilCalled) {
                return oldIt.apply(null, args)
            } else {
                // here we break the contract. If someone relies on the return value, then :(
                return null as unknown as Mocha.Test;
            }
        }
        globalThis.it = it;
        // we should loop and copy all, except the "normal" functions; maybe in the future more functions will be added to "it"
        it.only = oldIt.only;
        it.skip = oldIt.skip;
        it.retries = oldIt.retries;

        it.onlyUntil = (...args: any) => {
            if (!this.onlyCalled) {
                // this is not bullet proof. Maybe another "describe" has only, and the check would pass.
                // but it's difficult to add proper validation
                throw new Error("'it.onlyUntil()' detected, but the parent suite should be 'describe.only()'");
            }
            const result = it.apply(null, args);
            this.onlyUntilCalled = true;
            return result;

        }

        const oldDescribe = globalThis.describe;
        const describe: typeof oldDescribe = (...args: any) => {
            return oldDescribe.apply(null, args);
        }
        globalThis.describe = describe;
        describe.skip = oldDescribe.skip;
        describe.only = (...args: any) => {
            this.onlyCalled = true;
            return oldDescribe.only.apply(null, args);
        }

    }

    getMochaConstants(): any {
        // this is not exposed in the .d.ts files
        // @ts-ignore
        return Mocha.Runner.constants;
    }

    /**
     * So that the master can access it.
     */
    getTestsAreDemoFunctions() {
        return tad;
    }

    static get INSTANCE() {
        if (!TestsAreDemoSlave._INSTANCE) {
            throw new Error("There is no TestsAreDemoSlave singleton available. This might happen if somehow you run tests that are NOT in the IFrame of TestsAreDemoMaster (i.e. in TestsAreDemoSlave)");
        }
        return TestsAreDemoSlave._INSTANCE;
    }

    run() {
        // this probably happens if "auto run" is checked
        if (!TestsAreDemoSlave.mochaInitialized) {
            this.runRequestedWhileMochaInitializing = true;
            return;
        }
        mocha.run(() => {
            this.master.setState({ running: false });
            this.master.readStepByStep(); // because maybe clicked on "Run normally", which changed the attribute, but not local storage
        }).addListener("suite", () => { // before a suite
            // If importing "@testing-library/react", such a cleanup() is called at the end of the test. This is not 
            // OK, because usually we want to continue to see UI that was just tested. That's why we import "@testing-library/react/pure".
            // Then we moved the clean() at the beginning of the suite. But since the introduction of groups (hence sub-suites), this is
            // not OK any more. But anyway, I don't see why this is actually needed, since every test calls render()
            // cleanup();
        });
    }

    /**
     * This function was small but now is big and ugly. Reason: the report may exist or not.
     * If we have time to refactor, we should always instantiate the report (not only in puppeteer mode). Add move there the async stuff.
     */
    async showSpotlight(spotlightParams: SpotlightParams, finishWaitingCallback: Function) {
        // #region Calculate name for the last captured screenshot
        let screenshotFileName = this.lastElementCaptured?.getAttribute(this.master.testIdProp) ? "" : "_LAP"; // means "looked at parents"
        let current = this.lastElementCaptured;
        while (current) {
            const currentTestid = current.getAttribute(this.master.testIdProp);
            if (currentTestid) {
                if (!screenshotFileName) {
                    screenshotFileName = currentTestid
                } else {
                    screenshotFileName = currentTestid + "_" + screenshotFileName;
                }
            }
            current = current.parentElement;
        }

        if (this.testIdChainToCountForFileName[screenshotFileName] !== undefined) {
            this.testIdChainToCountForFileName[screenshotFileName]++;
            screenshotFileName += "_REP" + this.testIdChainToCountForFileName[screenshotFileName];
        } else {
            this.testIdChainToCountForFileName[screenshotFileName] = 0;
        }
        this.lastElementCapturedNumber++;
        this.lastElementCapturedFileName = screenshotFileName;
        // #endregion

        const messageForPuppeteer = this.createPuppeteerMessageWaitingForNextStep();
        this.report?.prepareToSendScreenshotToPuppeteer(messageForPuppeteer);

        // #region Parse the source code, to update the source code view (in UI) and the markdown report(in puppeteer)
        let screenshotAddedWithCode = false;
        try {
            /*
            * Cf. https://udn.realityripple.com/docs/Archive/Web/JavaScript/Microsoft_Extensions/Error.stackTraceLimit
            * by default Error.stackTraceLimit = 10 and we can have the *TestsAreDemo.* file deeper in stack trace
            * 
            * UPDATE: now, we set in this file, at init Error.stackTraceLimit = Infinity. But let's keep this code. Maybe the
            * dev has changed this global setting.
            */
            const stackTraceLimit = Error.stackTraceLimit;
            Error.stackTraceLimit = Infinity;
            let stackFrames: ErrorStackParser.StackFrame[];
            try {
                stackFrames = ErrorStackParser.parse(new Error());
            } finally {
                Error.stackTraceLimit = stackTraceLimit;
            }

            for (let stackFrame of stackFrames) {
                if (!stackFrame.getFileName() || !stackFrame.getLineNumber() || !stackFrame.getColumnNumber()) {
                    // we never saw this case; but based on the return types we conclude that there may be cases when the
                    // values are not available
                    continue;
                }
                const sourceCodeInfo = await TraceMapCache.INSTANCE.getSourceCodeState(stackFrame.getFileName()!, stackFrame.getLineNumber()!, stackFrame.getColumnNumber()!);
                if (sourceCodeInfo && (sourceCodeInfo.sourceFile.includes("TestsAreDemo.") || sourceCodeInfo.sourceFile.includes("TestsAreDemoInner."))) {
                    this.master.setState({ sourceCodeInfo });
                    if (this.report) {
                        this.report.onShowSpotlight(sourceCodeInfo, screenshotFileName);
                        screenshotAddedWithCode = true;
                    }
                    break;
                }
            }
        } catch (e) {
            // This breaks my policy of "fail fast". Explanation:
            //
            // I don't know how robust will be the "source map" code in the future. But for the moment
            // I observe that bugs keep appearing. It's already the second time when this throws an error
            // and breaks TAD completely. 
            // The idea of this "catch" is to allow TAD to continue, even if the "source map" code fails.
            //
            // UPDATE: the parsing lib was changed; the code has now more "ifs". In theory it should be robust, 
            // and the program won't arrive here any more. However, I'm still keeping (at least for the moment) this.
            console.error("Error caught while trying to process the source map.", e);
        }
        if (this.report && !screenshotAddedWithCode) {
            // fallback; the "source map" code failed
            this.report.onShowSpotlight(undefined, screenshotFileName);
        }
        // #endregion

        // #region Original logic. A bit too complicated because of the "highlight" timer.
        // Which is currently disabled. No idea if we'll keep this feature
        const run = () => {
            if (this.reporterFromSlaveToMaster.testFailed) {
                // if test failed, the globals pointing to the current test are not pointing any more to the failed test; so let's use the saved values
                // if test failed, there may be already a waiting callback. So we don't want to overwrite it 
                this.master.enableNextStepButton(undefined, messageForPuppeteer.dir + messageForPuppeteer.screenshotNumber);
                this.report?.sendScreenshotToPuppeteer(messageForPuppeteer);
            } else {
                this.master.enableNextStepButton(this.reporterFromSlaveToMaster.testFailed ? undefined : finishWaitingCallback, this.reporterFromSlaveToMaster.currentDir + this.lastElementCapturedNumber);
                this.report?.sendScreenshotToPuppeteer(this.createPuppeteerMessageWaitingForNextStep());
            }
            this.setState({ spotlightParams });
        };
        if (this.state.hideSpotlightTimerInProgress) {
            this.setState({ invokeAfterHideSpotlightTimer: run });
        } else {
            run();
        }
        // #endregion
    }

    createPuppeteerMessageWaitingForNextStep(): PuppeteerMessageWaitingForNextStep {
        return {
            dir: this.reporterFromSlaveToMaster.currentDir,
            screenshotNumber: this.lastElementCapturedNumber,
            fileWithoutExtension: this.lastElementCapturedFileName!,
        };
    }

    async hideSpotlight() {
        tad.miniDb.removeHighlight();
        this.setState({ hideSpotlightTimerInProgress: true });
        // 1/ This interferes w/ puppeteer + screenshots
        // 2/ It's not that useful, especially now that a user doesn't really use the UI. It's only for the main dev.
        // Other devs & users will look at the MD report. 
        // I'm not yet removing it though
        // await Utils.setTimeoutPromise(undefined, 500);
        this.setState({ spotlightParams: undefined });
        if (this.nextElementCaptured) {
            this.lastElementCaptured = this.nextElementCaptured;
            this.nextElementCaptured = null;
        }
        this.setState({ hideSpotlightTimerInProgress: false });
        if (this.state.invokeAfterHideSpotlightTimer) {
            this.state.invokeAfterHideSpotlightTimer();
            this.setState({ invokeAfterHideSpotlightTimer: undefined });
        }
    }

    public enableStepByStepProgrammatically() {
        if (this.master.state.stepByStepMode === StepByStepMode.OFF) {
            throw new Error("You are calling 'enableStepByStepProgrammatically()', but 'Step by step' is not 'Controlled by program'. This function is meant to be used temporarily; maybe you forgot to remove it?");
        } // else "controlled by program" or "on". For "on", this practically doesn't do anything
        // if a comment was stored, we need to empty it, because it wasn't consumed by the UI
        this.currentComment = null;
        this.stepByStep = true;
    }

    protected onDocumentMouseMove(event: MouseEvent) {
        let current = event.target as HTMLElement | null;
        let inspectedElements: HTMLElement[] | undefined = [];
        while (current) {
            const testid = current.getAttribute(this.master.testIdProp);
            if (testid) {
                inspectedElements.push(current);
            }
            current = current.parentElement
        }
        if (inspectedElements.length === 0) {
            this.setState({ inspectedElement: undefined });
            inspectedElements = undefined;
        } else {
            this.setState({ inspectedElement: inspectedElements[0] });
        }
        this.master.setState({ inspectedElements });
    }

    protected onDocumentClick() {
        this.toggleInspect();
    }

    public toggleInspect() {
        if (this.master.state.inspecting) {
            document.removeEventListener("mousemove", this.onDocumentMouseMove);
            document.removeEventListener("click", this.onDocumentClick);
        } else {
            document.addEventListener("mousemove", this.onDocumentMouseMove);
            document.addEventListener("click", this.onDocumentClick);
        }
        this.setState({ inspectedElement: undefined });
        this.master.setState({ inspecting: !this.master.state.inspecting });
    }

    protected renderSpotlight(spotlightRect: DOMRect, additionalClassNames: string = "") {
        const OFFSET = 5;
        spotlightRect.x -= OFFSET;
        spotlightRect.y -= OFFSET;
        spotlightRect.width += 2 * OFFSET;
        spotlightRect.height += 2 * OFFSET;

        return <div className={"TestsAreDemo_spotlight " + additionalClassNames} style={{ left: `${spotlightRect!.x}px`, top: `${spotlightRect!.y}px`, width: `${spotlightRect!.width}px`, height: `${spotlightRect!.height}px` }} />
    }

    protected renderFloater(target: HTMLElement, content: any) {
        return <ReactFloater content={content} open styles={{ options: { zIndex: 10001 } /* TestsAreDemo_overlay + 1 */, container: { borderRadius: "4px" }, floater: { maxWidth: "800px" } }}
            target={target} // it accepts a class name also (e.g. for the spotlight), which would have been more convenient; however, when the spotlight moves, the popup doesn't follow
        />
    }

    render() {
        const messageAndComment = <MessageAndComment tads={this} spotlightParams={this.state.spotlightParams} />;
        return <>
            <div id="rtl" className="flex-container flex-grow" />
            <div id="mocha" />
            {this.state.spotlightParams && this.lastElementCaptured && this.state.spotlightParams.focusOnLastElementCaptured && <>
                <div className="TestsAreDemo_overlay">
                    {this.renderSpotlight(this.lastElementCaptured.getBoundingClientRect())}
                    <div className="TestsAreDemo_screenshotPath">Screenshot dir/file: {this.reporterFromSlaveToMaster.currentDir}/{this.lastElementCapturedFileName}</div>
                </div>
                {!this.master.props.forceStepByStep && this.renderFloater(this.lastElementCaptured, messageAndComment)}
            </>}
            {this.state.spotlightParams && (!this.lastElementCaptured || !this.state.spotlightParams.focusOnLastElementCaptured) && <>
                {/* <ModalExt open={true}> */}
                <Modal open>
                    <Modal.Content>{messageAndComment}</Modal.Content>
                </Modal>
                {/* </ModalExt> */}
            </>}
            {this.state.inspectedElement && <>
                <div className="TestsAreDemo_overlay">
                    {this.renderSpotlight(this.state.inspectedElement.getBoundingClientRect())}
                </div>
                {this.state.inspectedElementPopup && this.renderFloater(this.state.inspectedElement, this.state.inspectedElementPopup)}
            </>}
        </>
    }
}

/**
 * `spotlightParams` is optional, because maybe the user only wants to show the comment.
 */
function MessageAndComment({ tads, spotlightParams }: { tads: TestsAreDemoSlave, spotlightParams?: SpotlightParams }) {
    const [comment, setComment] = useState<ReactNode>(null);
    const [lastSlideIndex, setLastSlideIndex] = useState<number | undefined>(undefined);
    useEffect(() => {
        if (typeof tads.currentComment === "string") {
            setComment(<MarkdownExt>{tads.currentComment}</MarkdownExt>);
        } else {
            // an element
            setComment(tads.currentComment);
        }
        setLastSlideIndex(tads.currentCommentSlideIndex);

        // clear lastCapturedSlide after it's used; but we saved it in the state; so in case of
        // re-render, we'll still have the value
        tads.currentComment = undefined;
        tads.currentCommentSlideIndex = undefined
    }, []);
    let message = spotlightParams?.message;
    // use divider only when message is not empty;
    // for example, if we capture a slide and we want to show only the content of that slide we don't have an
    // additional message
    const hasMessage = message ? true : false;
    if (typeof message === "string") {
        message = <MarkdownExt>{message}</MarkdownExt>
    } // else is an element
    return <>
        {/* {lastSlideIndex && <Label circular color="brown" content={lastSlideIndex} />} */}
        {<Label circular color="brown" content={tads.lastElementCapturedNumber} />}
        {message}
        {comment &&
            <>
                {hasMessage && <Divider />}
                <p />
                {comment}
            </>}
    </>
}