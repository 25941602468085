
import { Messages } from "@crispico/foundation-gwt-js";
import React from "react";
import { FieldRendererProps } from "../fieldRenderersEditors";
import { Label } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { Utils } from "@crispico/foundation-react/utils/Utils";

export type StringFieldRendererProps = FieldRendererProps & {
    asLabel?: boolean, 
    asLink?: boolean, 
    showTooltip?: boolean, 
    showIcon?: boolean, 
    showMeasurementUnit?: boolean, 
    showMessageIfNoContent?: boolean
};

export class StringFieldRenderer<P extends StringFieldRendererProps, S = {}> extends React.Component<P, S> {

    /**
     * @see FieldDescriptor.castcastAdditionalFieldRendererProps Because of the glitch
     */
    constructor(props: P) {
        super(props);
    }

    protected getValue() {
        return this.props.value;
    }

    protected getUrl() {
        return this.props.fieldDescriptor.parent?.getEntityEditorUrl(this.props.entity?.id);
    }

    protected getText(): String {
        const { props } = this;
        const value = props.fieldDescriptor.getFieldValueConvertedToMeasurementUnit(this.getValue());
        const showMU: boolean = props.showMeasurementUnit || false;
        let text = '';
        if (value !== undefined && value !== null) {
            if (typeof value !== "string") {
                text = JSON.stringify(value);
            } else {
                text = Messages.getInstance().maybeTranslateByUser(value);
            }
        }
        if (props.fieldDescriptor.prepareValue) {
            text = props.fieldDescriptor.prepareValue(text);
        }
        let muLabel;
        if (showMU) {
            muLabel = props.fieldDescriptor.getMeasurementUnitLabel();
        }
        text = (text + (muLabel ? " " + Messages.getInstance().maybeTranslateByUser(muLabel) : "")).trim();
        if (Utils.isNullOrEmpty(text)) {
            return props.showMessageIfNoContent ? _msg("general.no.data") : '';
        }
        return text;
    }

    protected getContent() {
        const { props } = this;
        const text = this.getText();
        return !Utils.isNullOrEmpty(text) ? <div data-testid="StringFieldRenderer" className="StringFieldRenderer" style={props.fieldDescriptor.getFieldColors(props.value)}
            data-tooltip={props.showTooltip && props.fieldDescriptor.getLabel()} data-position="top center">
            {props.showIcon && props.fieldDescriptor.getIcon()}{text}
        </div> : null;
    }

    protected renderLabel(props: any, content: any) {
        return content || (Utils.isNullOrEmpty(this.getValue()) && props.showMessageIfNoContent) ? <Label key={props.entity?.id + "." + props.fieldDescriptor.name}
            style={props.fieldDescriptor.getFieldColors(props.value)}>
            {props.fieldDescriptor.wrapComponent(props.value, content)}
        </Label> : null;
    }

    protected getNavLinkExtraProps() {
        return {};
    }

    render(): JSX.Element | null {
        const { props } = this;
        let content = this.getContent();

        if (props.asLink) {
            const url = this.getUrl();
            content = content ? <NavLink {...this.getNavLinkExtraProps()} to={url ? url : ""}>{content}</NavLink> : null;
        }
        if (props.asLabel) {
            return this.renderLabel(props, content);
        }
        return content || (Utils.isNullOrEmpty(this.getValue()) && props.showMessageIfNoContent) ? props.fieldDescriptor.wrapComponent(props.value, content) : null;
    }
}

export default StringFieldRenderer;
"../../utils/Utils"