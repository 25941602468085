import { getMessageForField } from "@crispico/foundation-react/components/fieldNameContentAssist/FieldNameContentAssist";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { Label } from "semantic-ui-react";

export const EntityFieldsFieldRenderer = (props: FieldRendererProps) => {
    const { value, entity, fieldDescriptor } = props;
    return <>
        {value ? value.split(',').map((field: string) =>
            <Label>{getMessageForField(field, entity[fieldDescriptor.fieldForEntityName])}</Label>
        ) : []}
    </>
}"../../components/fieldNameContentAssist/FieldNameContentAssist"