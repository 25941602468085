
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, EntityDescriptor, EntityEditorFormSimple, FieldDescriptor, TestUtils, Utils } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { SelectExt, SelectExtOption } from "@crispico/foundation-react/components/selectExt/SelectExt";
import { Wizard, WizardRRC, WizardStep } from "@crispico/foundation-react/components/Wizard/Wizard";
import { AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/AssociationFieldEditor";
import { entityDescriptors, ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { dashboardEntityDescriptor } from "@crispico/foundation-react/FoundationEntityDescriptors";
import { FormikProps } from "formik/dist/types";
import _ from "lodash";
import React, { RefObject } from "react";
import { Button, Grid, Icon, Segment } from "semantic-ui-react";
import { PopupWithHelpTooltip } from "../../../components/semanticUiReactExt";
import { COPY, MOVE } from "../DashboardContants";
import { DashboardWidgetFactories, DashboardWidgetType } from "../DashboardWidgetFactory";
import { DashboardTab, DashboardTabProps } from "./DashboardTab";
import { createTestids } from "@famiprog-foundation/tests-are-demo";

export const dashboardWidgetWizardTestids = createTestids("DashboardWidgetWizard", {
    copy: "", move: ""
});

export class DashboardWidgetWizard extends React.Component<{ dashboardTabRef: RefObject<DashboardTab>, wizardRef: RefObject<Wizard> }> {

    entityEditorForm = React.createRef<EntityEditorFormSimple>();

    render() {
        const props = this.props;
        const dashboardType = props.dashboardTabRef.current?.props.dashboardEntity?.forEditor ? DashboardWidgetType.ENTITY : DashboardWidgetType.FILTER;
        const steps: WizardStep[] = [
            {
                title: _msg('Dashboard.widget.wizard.selectWidgetType'),
                icon: "question circle outline",
                nextDisabled: () => props.dashboardTabRef.current!.props.s.widgetActionOrType === undefined,
                render: () => {
                    let disabledWidgets: any = {}
                    Object.keys(props.dashboardTabRef.current!.props.s.widgetWrapperConfigs).forEach(w => {
                        if (!DashboardWidgetFactories.INSTANCE.widgets[props.dashboardTabRef.current!.props.s.widgetWrapperConfigs[w].type].allowMultiple) {
                            disabledWidgets[props.dashboardTabRef.current!.props.s.widgetWrapperConfigs[w].type] = true
                        }
                    })
                    return <Segment style={{ marginTop: '0px' }}><Grid style={{ padding: '0 10px' }} stackable columns='3'><Grid.Row>
                        <Grid.Column key={'move-copy'} stretched className='DashboardWizard_WidgetWrapper'>
                            <Segment>
                                <div className='DashboardWizard_MoveCopyWidget'>
                                    <Button data-testid={dashboardWidgetWizardTestids.copy} className='DashboardWizard_MoveCopyWidgetButton' positive size='huge' onClick={() => props.dashboardTabRef.current!.selectWidgetType(COPY)}>{_msg('Dashboard.wizard.button.copy')}</Button>
                                    <p />
                                    <Button data-testid={dashboardWidgetWizardTestids.move} className='DashboardWizard_MoveCopyWidgetButton' primary size='huge' onClick={() => props.dashboardTabRef.current!.selectWidgetType(MOVE)}>{_msg('Dashboard.wizard.button.move')}</Button>
                                </div>
                            </Segment>
                        </Grid.Column>
                        {Object.keys(DashboardWidgetFactories.INSTANCE.widgets).map(type => {
                            const factory = DashboardWidgetFactories.INSTANCE.widgets[type];
                            if (factory.type !== DashboardWidgetType.ANY && factory.type !== dashboardType) {
                                return null
                            }
                            const info = factory.getWizardInfo()
                            const disabled = disabledWidgets[type] ? true : false
                            let widget = null
                            if (info.storybook && !TestUtils.storybookMode) {
                                return null
                            } else if (info.image) {
                                widget = <img style={{ objectFit: 'cover', width: '100%', height: '100%', }} src={info.image} alt='widgetImage' />
                            } else if (info.component && info.testState) {
                                widget = <info.component {...info.testState} />
                            }
                            return <Grid.Column key={type} data-testid={type} stretched className='DashboardWizard_WidgetWrapper'>
                                <Segment attached="top" className="flex-center flex-container-row less-padding" >
                                    <h4 className="flex-grow-shrink-no-overflow no-margin">{info.title}</h4>
                                    <div>
                                        <Button positive disabled={disabled} onClick={() => props.dashboardTabRef.current!.selectWidgetType(type)}><Icon name='add' />{_msg("general.add")}</Button>
                                        <PopupWithHelpTooltip tooltip={info.description} />
                                    </div>
                                </Segment>
                                <Segment secondary className={disabled ? " disabledItem" : undefined} style={{ height: '220px', padding: '5px', overflow: 'hidden' }} attached='bottom'>
                                    {widget}
                                </Segment>
                            </Grid.Column>
                        })}
                    </Grid.Row></Grid></Segment>
                }
            },
            {
                title: props.wizardRef.current?.getCurrentStepIndex() === 1 && props.dashboardTabRef.current!.props.s.widgetActionOrType == COPY ? _msg('Dashboard.wizard.tab.copy') : props.dashboardTabRef.current!.props.s.widgetActionOrType == MOVE ? _msg('Dashboard.wizard.tab.move') : _msg('Dashboard.widget.wizard.configWidget'),
                icon: props.dashboardTabRef.current!.props.s.widgetActionOrType == COPY ? 'copy' : props.dashboardTabRef.current!.props.s.widgetActionOrType == MOVE ? 'arrow alternate circle right' : 'edit outline',
                render: () => {
                    if (props.dashboardTabRef.current!.props.s.widgetActionOrType === COPY || props.dashboardTabRef.current!.props.s.widgetActionOrType === MOVE) {
                        return <EntityEditorFormSimple ref={this.entityEditorForm} hideButtonBar entity={{}}
                            entityDescriptor={new EntityDescriptor({ name: 'Dashboard.' + props.dashboardTabRef.current!.props.s.widgetActionOrType })
                                .addFieldDescriptor({ name: 'dashboard', type: 'Dashboard' }, new class extends FieldDescriptor {
                                    protected renderFieldEditorInternal(EditorClass: any, filedEditorProps: FieldEditorProps) {
                                        const newProps = {
                                            ...filedEditorProps,
                                            innerEntityDescriptor: entityDescriptors['Dashboard'],
                                            onChange: (e: any) => props.dashboardTabRef.current!.selectDashboard(e ? e.id : undefined),
                                            additionalProps: props.dashboardTabRef.current!.props
                                        }
                                        return React.createElement(DashboardAssociationFieldEditor as any, newProps as FieldEditorProps)
                                    }
                                }())
                                .addFieldDescriptor({ name: 'widgets', type: 'Widgets' }, new class extends FieldDescriptor {
                                    renderFieldEditor(formikProps: FormikProps<any>) {
                                        return <SelectExt options={props.dashboardTabRef.current!.props.s.wizardCopyMoveWidgetsAll} defaultValue={props.dashboardTabRef.current!.props.s.wizardCopyMoveWidgetsSelected} onSelectedOptionsChange={(selected: SelectExtOption[]) => props.dashboardTabRef.current!.props.r.setInReduxState({ wizardCopyMoveWidgetsSelected: selected })} />
                                    }
                                }())
                            } />
                    } else {
                        return props.dashboardTabRef.current!.props.s.widgetActionOrType ? <EntityEditorFormSimple ref={this.entityEditorForm} hideButtonBar entity={{}}
                            entityDescriptor={DashboardWidgetFactories.INSTANCE.widgets[props.dashboardTabRef.current!.props.s.widgetActionOrType as string].getEntityDescriptor({}, props.dashboardTabRef.current!.props.dashboardEntity)}
                            onSubmitHandler={values => props.dashboardTabRef.current!.props.r.setInReduxState({ values })} /> : null;
                    }
                }
            }
        ];
        return <WizardRRC id="dashboardWidgetWizard" ref={props.wizardRef} steps={steps}
            onCancelClick={async() => props.dashboardTabRef.current!.props.r.setInReduxState({ wizardOpen: false })}
            onFinishClick={async () => {
                if (props.dashboardTabRef.current!.props.s.widgetActionOrType === COPY || props.dashboardTabRef.current!.props.s.widgetActionOrType === MOVE) {
                    if (props.dashboardTabRef.current!.props.s.wizardCopyMoveDashboardId && props.dashboardTabRef.current!.props.s.wizardCopyMoveWidgetsSelected.length > 0) {
                        if (props.dashboardTabRef.current!.props.s.widgetActionOrType === COPY) {
                            props.dashboardTabRef.current!.copyWidgets();
                        } else if (props.dashboardTabRef.current!.props.s.widgetActionOrType === MOVE) {
                            props.dashboardTabRef.current!.props.r.setInReduxState({ wizardMoveConfirmation: true });
                        }
                    }
                } else {
                    await this.entityEditorForm.current?.submit()
                    props.dashboardTabRef.current!.props.r.addItem({ dashboardEntity: props.dashboardTabRef.current!.props.dashboardEntity })
                }
            }} />
    }
}

class DashboardAssociationFieldEditor extends AssociationFieldEditor<FieldEditorProps & { value?: any, placeholder?: any, onChange?: (entity: any) => void, additionalProps: DashboardTabProps }> {
    protected async performQuery(searchQuery: string, operationName?: string) {
        const { name, query } = this.createQuery(operationName);

        const filter = Filter.create(ID, FilterOperators.forString.notEquals,
            (this.props.additionalProps.dashboardEntity.id).toString())
        let params = this.props.additionalProps.s.widgetActionOrType == COPY ?
            this.createFindByStringParams(searchQuery) :
            this.createFindByStringParams(searchQuery).filter(filter)
        let result = (await apolloClient.query({ query, variables: params })).data[name];

        this.setState({ entities: result });
    }

    render() {
        return <div className='flex-container-row'>
            <div className='flex-grow-shrink-no-overflow' style={{ paddingRight: 10 }}>
                {super.render()}
            </div>
            <Button disabled={!this.props.additionalProps.s.wizardCopyMoveDashboardId} onClick={() => window.open(Utils.adjustUrlToServerContext('#' + dashboardEntityDescriptor.getEntityEditorUrl(this.props.additionalProps.s.wizardCopyMoveDashboardId) + '/dashboard'), '_blank')}>
                {_msg('Dashboard.wizard.newTab')}
            </Button>
        </div>
    }
}"../../..""../../../components/CustomQuery/Filter""../../../components/selectExt/SelectExt""../../../components/Wizard/Wizard""../../../entity_crud/AssociationFieldEditor""../../../entity_crud/entityCrudConstants""../../../entity_crud/fieldRenderersEditors""../../../FoundationEntityDescriptors"