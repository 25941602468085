import { Optional } from "@crispico/foundation-react/CompMeta";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DateFieldRenderer";
import DropdownFieldEditor from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DropdownFieldEditor";
import NumberFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/NumberFieldRenderer";
import StringFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/StringFieldRenderer";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import { FormikProps } from "formik";
import { default as _ } from "lodash";
import React from "react";
import { ColorResult, SketchPicker } from "react-color";
//@ts-ignore
import TimezonePicker from 'react-timezone-select';
import {
    Button, Checkbox, CheckboxProps, Dropdown, 
    DropdownProps, Form, Input, InputProps, Modal, Progress, StrictCheckboxProps, StrictDropdownProps, StrictInputProps, TextArea
} from "semantic-ui-react";
import { AppMetaTempGlobals } from "../AppMetaTempGlobals";
import { RangePickerExtFieldEditor } from "../components/RangePickerExt/RangePickerExtFieldEditor";
import { entityDescriptors } from "./entityCrudConstants";
import { DummyToRememberPeopleToCast, EntityDescriptor, FieldDescriptor } from "./EntityDescriptor";
import { CronFieldEditor } from "./fieldRenderersEditors/CronFieldEditor";
import { SortFieldEditor, SortFieldRenderer } from "./fieldRenderersEditors/EntitySort";
import PieCountColorPaletteFieldEditor from "./fieldRenderersEditors/PieCountColorPaletteFieldEditor";
import PieCountColorPaletteFieldRenderer from "./fieldRenderersEditors/PieCountColorPaletteFieldRenderer";
import { FieldType } from "./FieldType";
import { DatePickerFieldEditor } from "../components/DatePicker/DatePickerFieldEditor";
import { TestsAreDemoCheat } from "@famiprog-foundation/tests-are-demo";
import { EntityFieldsFieldEditor } from "./fieldRenderersEditors/EntityFieldsFieldEditor";
import { EntityFieldsFieldRenderer } from "./fieldRenderersEditors/EntityFieldsFieldRenderer";
import { AssociationEditorProps, AssociationExtraProps, AssociationFieldEditor } from "./AssociationFieldEditor";
import { AssociationFieldRenderer } from "./AssociationFieldRenderer";
import { DropdownFieldRenderer } from "./fieldRenderersEditors/DropdownFieldRenderer";

//#region 
export interface FieldRendererProps extends DummyToRememberPeopleToCast {
    entity: any,
    value: any; // field value
    fieldDescriptor: FieldDescriptor;
    innerEntityDescriptor?: EntityDescriptor;
}

class TextFieldRenderer extends React.Component<FieldRendererProps, { modalOpen: boolean }> {

    constructor(props: FieldRendererProps) {
        super(props);
        this.state = { modalOpen: false };
    }

    render() {
        const text = this.props.value ? String(this.props.value) : "";
        return <>
            <span onDoubleClick={(evt) => { this.setState({ modalOpen: true }); evt.stopPropagation() }} >{text}</span>
            <ModalExt open={this.state.modalOpen} closeOnDocumentClick onClose={() => this.setState({ modalOpen: false })} size='tiny'>
                <Modal.Content>
                    <Form className="wh100">
                        <TextArea rows={5} >{text}</TextArea>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.setState({ modalOpen: false })}>Close</Button>
                </Modal.Actions>
            </ModalExt>
        </>
    }
}

export const fieldRenderers: { [key: string]: any } = {
    [FieldType.defaultManyToOne]: (props: FieldRendererProps) => {
        return <AssociationFieldRenderer {...props} asLink />
    },
    [FieldType.defaultOneToMany]: (props: FieldRendererProps) => {
        return <AssociationFieldRenderer {...props} asLabel />
    },
    [FieldType.number]: NumberFieldRenderer,
    [FieldType.double]: NumberFieldRenderer,
    [FieldType.string]: StringFieldRenderer,
    [FieldType.text]: TextFieldRenderer,
    [FieldType.boolean]: (props: FieldRendererProps) => {
        const checked: boolean = props.value != null && props.value as boolean;
        return <Checkbox checked={checked} />
    },
    [FieldType.date]: DateFieldRenderer,
    [FieldType.color]: (props: FieldRendererProps) => <div className="ColorRenderer" style={{ backgroundColor: props.value ? (props.fieldDescriptor.colorType === "number" ? Utils.convertColorToHex(props.value) : props.value) : undefined }}></div>,
    [FieldType.sort]: SortFieldRenderer,
    [FieldType.password]: (props: FieldRendererProps) => <>***</>,
    [FieldType.entityName]: (props: FieldRendererProps) => <>{props.value ? String(props.value) : ""}</>,
    [FieldType.entityFields]: EntityFieldsFieldRenderer,
    [FieldType.timeZone]: (props: FieldRendererProps) => <>{props.value?.label || ""}</>,
    [FieldType.progress]: (props: FieldRendererProps) => <Progress percent={props.value && !isNaN(props.value) ? props.value : 0} indicating={(props.value && !isNaN(props.value) ? props.value : 0) < 100}
        progress color={(props.value && !isNaN(props.value) ? props.value : 0) == 100 ? 'green' : undefined} />,
    [FieldType.cron]: (props: FieldRendererProps) => <>{props.value}</>,
    [FieldType.dropdown]: DropdownFieldRenderer,
    [FieldType.pieCountColorPalette]: PieCountColorPaletteFieldRenderer
}

//#endregion

//#region 

export interface FieldEditorProps extends DummyToRememberPeopleToCast {
    formikProps: FormikProps<any>;
    fieldDescriptor: FieldDescriptor;
    innerEntityDescriptor?: EntityDescriptor;
    autoFocus?: boolean;

    /**
     * Used by the filter form to command the value editor to focus.
     * The "normal" input uses this. It's rather optional for others; some editors may not be focusable.
     * 
     * TODO: not used; to be removed, see #28226
     */
    refForFocus?: React.RefObject<any>,
}

export class StringFieldEditor<P extends FieldEditorProps & StrictInputProps> extends React.Component<P> {

    constructor(props: P) {
        super(props);
    }

    protected getValue() {
        const value = this.props.fieldDescriptor.getFieldValue((this.props as FieldEditorProps).formikProps.values);
        return value ? String(value) : "";
    }

    protected setFieldValue(value: string | number | undefined) {
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.getFieldName(), value);
    }

    protected onChange = (event: any, data: InputProps) => {
        this.setFieldValue(data.value);
    }

    render = () => {
        const { props } = this;
        return <>
            <TestsAreDemoCheat objectToPublish={this} />
            <Input autoFocus={props.autoFocus} ref={this.props.refForFocus} name={props.fieldDescriptor.getFieldName()} value={this.getValue()} onChange={this.onChange} {...this.props} />
        </>
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    ////// Test functions
    ////////////////////////////////////////////////////////////////////////////////////////
    tadType = (value: string) => {
        this.setFieldValue(value);
    }
}

// TODO by CS: nu-mi place de loc cum e extinsa clasa parinte
export class NumberFieldEditor<P extends FieldEditorProps & StrictInputProps> extends StringFieldEditor<P> {

    protected getValue() {
        const value = this.props.fieldDescriptor.getFieldValue((this.props as FieldEditorProps).formikProps.values);
        return value || value === 0 ? value : "";
    }

    protected onChange = (event: any, data: InputProps) => {
        this.setFieldValue(event.target.valueAsNumber);
    }

    getInputProps(): Optional<InputProps> {
        return undefined;
    }

    render = () => {
        const fieldName = this.props.fieldDescriptor.getFieldName();
        return <Input autoFocus={this.props.autoFocus} ref={this.props.refForFocus} type='number' {...this.getInputProps()}
            name={fieldName} value={this.getValue()}
            onChange={this.onChange} {...this.props} />
    }
}

export class DoubleFieldEditor<P extends FieldEditorProps> extends NumberFieldEditor<P> {

    getInputProps(): Optional<InputProps> {
        return { step: "any" };
    }
}

export class EntityNameFieldEditor extends React.Component<FieldEditorProps & StrictDropdownProps> {
    constructor(props: FieldEditorProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        // this is needed when we want to pre-fill the selected option
        this.handleChange({} as any, { value: this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values) })
    }

    handleChange(event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) {
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, data.value);
    }

    render = () => {
        const value = this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values);
        let options: any[] = [];
        Object.keys(AppMetaTempGlobals.appMetaInstance.getUIEntityDescriptors()).sort((a, b) => entityDescriptors[a].getLabel() < entityDescriptors[b].getLabel() ? -1 : (entityDescriptors[a].getLabel() > entityDescriptors[b].getLabel() ? 1 : 0)).forEach((name: string) => {
            const ed = entityDescriptors[name];
            options.push({ key: name, text: ed.getLabel(), value: name });
        });
        return <Dropdown data-cy={"entityTypeDropdown"} clearable fluid selection search={true} options={options} onChange={this.handleChange} value={value ? value : ''} {...this.props} />
    }
}

export class BooleanFieldEditor extends React.Component<FieldEditorProps & StrictCheckboxProps> {

    onChange = (event: any, data: CheckboxProps) => {
        this.props.formikProps.setFieldValue(this.props.fieldDescriptor.getFieldName(), data.checked);
    }

    render() {
        const { formikProps, fieldDescriptor } = this.props;
        let checked = fieldDescriptor.getFieldValue(formikProps.values) || false;
        if (typeof checked === FieldType.string) {
            checked = JSON.parse(checked);
        } 
        return <Checkbox data-testid="BooleanFieldEditor" ref={this.props.refForFocus} checked={checked} onChange={this.onChange} {...this.props} />
    }
}

//#region 
export const fieldEditors: { [key: string]: any } = {
    [FieldType.double]: DoubleFieldEditor,
    [FieldType.number]: NumberFieldEditor,
    [FieldType.string]: StringFieldEditor,
    [FieldType.text]: ({ formikProps, fieldDescriptor, refForFocus, autoFocus }: FieldEditorProps) => <TextArea autoFocus={autoFocus} ref={refForFocus} rows={5} name={fieldDescriptor.getFieldName()} value={fieldDescriptor.getFieldValue(formikProps.values) || ""} onChange={formikProps.handleChange} />,
    [FieldType.password]: ({ formikProps, fieldDescriptor, refForFocus, autoFocus }: FieldEditorProps) => <Input autoComplete='off' autoFocus={autoFocus} ref={refForFocus} type='password' name={fieldDescriptor.getFieldName()} value={fieldDescriptor.getFieldValue(formikProps.values) || ""} onChange={formikProps.handleChange} />,
    [FieldType.timeZone]: ({ formikProps, fieldDescriptor, refForFocus }: FieldEditorProps) => {
        if (!fieldDescriptor.getFieldValue(formikProps.values)) {
            formikProps.setFieldValue(fieldDescriptor.getFieldName(), { value: "GMT", label: "(GMT+0:00) Dublin, Edinburgh, Lisbon, London \n            (GMT)", abbrev: "GMT", altName: "GMT" });
        }
        return <TimezonePicker fluid value={fieldDescriptor.getFieldValue(formikProps.values)}
            onChange={(tz: any) => formikProps.setFieldValue(fieldDescriptor.getFieldName(), tz)} labelStyle={'abbrev'} />
    },
    [FieldType.progress]: ({ formikProps, fieldDescriptor, refForFocus }: FieldEditorProps) => <Progress percent={fieldDescriptor.getFieldValue(formikProps.values) && !isNaN(fieldDescriptor.getFieldValue(formikProps.values)) ? fieldDescriptor.getFieldValue(formikProps.values) : 0}
        indicating={(fieldDescriptor.getFieldValue(formikProps.values) && !isNaN(fieldDescriptor.getFieldValue(formikProps.values)) ? fieldDescriptor.getFieldValue(formikProps.values) : 0) < 100} progress
        success={(fieldDescriptor.getFieldValue(formikProps.values) && !isNaN(fieldDescriptor.getFieldValue(formikProps.values)) ? fieldDescriptor.getFieldValue(formikProps.values) : 0) == 100} />,

    // formikProps.onChange() is not compatible w/ Checkbox.onChange(); the corresponding event doesn't point as expected towards the HTML input, so formik
    // doesn't detect the field name; hence we need to use formikProps.setFieldValue(), i.e. an adaptation. Hence I created a class comp to avoid an inline
    // function (which would be recreated at each render)
    [FieldType.color]: class extends React.Component<FieldEditorProps> {

        state = {
            displayColorPicker: false,
            color: undefined as Optional<string>
        };

        handleClick = () => {
            this.setState({ displayColorPicker: !this.state.displayColorPicker })
        };

        handleClose = () => {
            this.setState({ displayColorPicker: false })
        };

        onChange = (color: ColorResult) => {
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, this.prepareValueToSave(color.hex, this.props.fieldDescriptor.colorType))
            this.setState({ color: color.hex })
        };

        getCurrentValue = (): Optional<string | number> => {
            return this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values);
        }

        getColor = (): string => {
            return this.state.color || this.prepareValueToDisplay(this.getCurrentValue());
        }

        prepareValueToDisplay(color: Optional<string | number>): string {
            if (!color) {
                return "#FFFFFF"; // white, if not defined
            }
            if (typeof color === 'number') {
                return Utils.convertColorToHex(color);
            }
            return color;
        }

        prepareValueToSave(color: string | number, requestedType: string) {
            if (typeof color === 'number' && requestedType === 'string') {
                return Utils.convertColorToHex(color);
            }
            if (typeof color === 'string' && requestedType === 'number') {
                return Utils.convertColorFromHex(color);
            }
            return color;
        }

        render() {
            return (
                <div>
                    <div className="ColorPicker_swatch" onClick={this.handleClick}>
                        <div className="ColorPicker_color" style={{ backgroundColor: this.getColor() }} />
                    </div>
                    {this.state.displayColorPicker ? <div className="ColorPicker_popover">
                        <div className="ColorPicker_cover" onClick={this.handleClose} />
                        <SketchPicker disableAlpha color={this.getColor()} onChange={this.onChange} />
                    </div> : null}
                </div>
            )
        }
    },

    [FieldType.boolean]: BooleanFieldEditor,
    [FieldType.rangeDate]: RangePickerExtFieldEditor,
    [FieldType.date]: DatePickerFieldEditor,
    [FieldType.defaultManyToOne]: (props: FieldEditorProps & AssociationEditorProps & AssociationExtraProps) => {
        return <AssociationFieldEditor {...props} isMulti={false} />;
    },
    [FieldType.defaultOneToMany]: (props: FieldEditorProps & AssociationEditorProps & AssociationExtraProps) => {
        return <AssociationFieldEditor {...props} isMulti={true} />;
    },
    [FieldType.sort]: SortFieldEditor,
    [FieldType.entityName]: EntityNameFieldEditor,
    [FieldType.entityFields]: EntityFieldsFieldEditor,
    [FieldType.cron]: CronFieldEditor,
    [FieldType.dropdown]: DropdownFieldEditor,
    [FieldType.pieCountColorPalette]: PieCountColorPaletteFieldEditor
}
//#endregion"../CompMeta""../components/ModalExt/ModalExt""./fieldRenderersEditors/DateFieldRenderer""./fieldRenderersEditors/DropdownFieldEditor""./fieldRenderersEditors/NumberFieldRenderer""./fieldRenderersEditors/StringFieldRenderer""../utils/Utils"