import React from "react";
import SyntaxHighlighter, { SyntaxHighlighterProps } from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Button, Label, Popup } from "semantic-ui-react";
import { Utils } from "../copied/Utils";
import { createTestids } from "./TestsAreDemoFunctions";
import { SourceCodeInfo } from "./TraceMapCache";

const CURRENT_LINE_ID = "currentLine";
const CODE_ID = "code";

export const sourceCodeTestIds = createTestids("TestsAreDemoMaster", {
    line: "", code: ""
});

interface Props {
    sourceCodeInfo?: SourceCodeInfo;
}

export class SourceCode extends React.Component<Props> {

    private adjustScrollbarsIfNeededToHaveCurrentLineElementInViewport() {
        const line = document.getElementById(CURRENT_LINE_ID);
        if (!line) {
            return;
        }
        const parent = document.getElementById(CODE_ID) as HTMLElement;
        var pageTop = parent.scrollTop;
        var pageBottom = pageTop + parent.offsetHeight;
        let scrollBarHeight = parent.offsetHeight - parent.clientHeight;
        var elementTop = line.offsetTop;
        var elementBottom = elementTop + line.offsetHeight + scrollBarHeight;

        if (pageTop > elementTop || pageBottom < elementBottom) {
            line.scrollIntoView();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        const state = this.props.sourceCodeInfo;
        if (state && prevProps.sourceCodeInfo != this.props.sourceCodeInfo) {
            this.adjustScrollbarsIfNeededToHaveCurrentLineElementInViewport();
        }
    }

    render() {
        const state = this.props.sourceCodeInfo;
        return state?.sourceCode
            ? <>
                <span>File@line: <b>{Utils.substringAfter(state.sourceFile!, "/", true)}</b>@{state.sourceLine} &nbsp;
                    <Popup trigger={<Button icon="bars" size="mini" />}
                        flowing on='click' pinned
                        content={<>
                            Full file name: <b>{state.sourceFile?.split("..").pop()}</b>
                            <p />
                            <Button size="mini" content="Print it to console" onClick={() => {
                                console.log("Printing the file name, in a clickable form:");
                                if (state.sourceFile) {
                                    console.log(window.location.origin + state.sourceFile?.split("..").pop() + ":" + state.sourceLine);
                                } else {
                                    console.log("The source file is undefined");
                                }
                            }
                            } />
                            Then open DevTools (e.g. via <Label size="mini" content="F12" />) and click on the file name (to open it in DevTools).
                        </>}
                    />
                </span>
                <SyntaxHighlighterExt highlightSourceLine={state.sourceLine} >{state.sourceCode}</SyntaxHighlighterExt>
            </>
            : <>SourceCode not yet loaded</>;
    }
}

export function SyntaxHighlighterExt(props: { highlightSourceLine?: number } & SyntaxHighlighterProps) {
    const { highlightSourceLine, ...otherProps } = props;
    return <SyntaxHighlighter id="SyntaxHighlighter" className="no-padding-margin flex-container flex-grow" codeTagProps={{ "data-testid": sourceCodeTestIds.code, id: CODE_ID, className: "language-javascript flex-grow" } as React.HTMLProps<HTMLElement>} language="javascript" style={docco} wrapLines showLineNumbers lineProps={lineNumber => {
        let style = { background: lineNumber === highlightSourceLine ? '#dbffdb' : '#0000' };
        return { "data-testid": sourceCodeTestIds.line + "_" + lineNumber, style, id: lineNumber === highlightSourceLine ? CURRENT_LINE_ID : undefined };
    }} {...otherProps} />
}
