import { Optional } from "@crispico/foundation-react/CompMeta";
import { FieldInterval } from "@crispico/foundation-react/entity_crud/CrudSettings";
import { FIELDS_WRITE } from "@crispico/foundation-react/utils/Utils";
import { createTestids } from "@famiprog-foundation/tests-are-demo";
import { Formik, FormikConsumer, FormikContextType, FormikProps } from "formik";
import React, { ReactNode } from "react";
import { Button, Divider, Form, Grid, Icon, Segment } from "semantic-ui-react";
import { AppMetaTempGlobals } from "../AppMetaTempGlobals";
import { TabbedPageProps } from "../components/TabbedPage/TabbedPage";
import { ConnectedComponentInSimpleComponent } from "../reduxHelpers/ConnectedPageHelper";
import { ID } from "./entityCrudConstants";
import { EntityDescriptor, FieldDescriptor } from "./EntityDescriptor";
import { ColumnDefinition } from "./EntityTableSimple";
import { FieldEditorProps } from "./fieldRenderersEditors";

export const entityEditorFormSimpleTestids = createTestids("EntityEditorFormSimple", {
    fields: "", field: "", fieldLabel: "",
    save: "", cancel: ""
});

export type EntityEditorFormSimpleProps = TabbedPageProps & {
    ref?: React.RefObject<any>,
    entity: any,
    entityDescriptor: EntityDescriptor,

    hideButtonBar?: boolean,
    onSubmitHandler?: (values: any) => void,
    onCancelHandler?: () => void,
    saveDisabled?: boolean,

    columnsVisibleMap?: { [field: string]: boolean };
    columns?: ColumnDefinition[]

    /**
     * Dictates how the fields should be layed out in the grid. Each element
     * in the list reprents a row (0, 1, 2, ...). For each row, we have a list of
     * field names that are in that particular row.
     * 
     * E.g.:
     * 
     * ```js
     * [
     *  ["id", "name", "type"], // row 0
     *  ["field1", "field2", "field3"] // row 1
     * ]
     * ```
     * 
     * If nothing is provided, a single column will be created, with all the fields defined by the `entityDescriptor`.
     */
    fieldsLayout?: Array<Array<string>>;

    autoFocusOnField?: string;
};

// IMPORTANT! 
// This class will be renamed to CrudForm; it should be used stand alone
// Another one exists already: CrudFormInEditor
// EntityEditorPage will be renamed to CrudEditorPage
//
// Don't add logic here, if it's related to the CrudEditorPage (EntityCrudditorPage) actually. That
// kind of logic belongs from now on in CrudFormInEditor
export class EntityEditorFormSimple<P extends EntityEditorFormSimpleProps = EntityEditorFormSimpleProps> extends React.Component<P> {

    formik = React.createRef();
    protected ref = React.createRef<ConnectedComponentInSimpleComponent>();

    formikContext!: FormikContextType<any>;

    onSubmit(values: any) {
        this.props.onSubmitHandler && this.props.onSubmitHandler(values);
    }

    constructor(props: P) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.renderFormikChild = this.renderFormikChild.bind(this);
        this.renderFormikChildDuplicate = this.renderFormikChildDuplicate.bind(this);
    }

    renderFormikChild(formikProps: FormikProps<any>, duplicateFromId?: boolean) {
        return (<>
            {/* WARNING: if the buttons were inside => on each click, they'd trigger submit; we would have had to do .preventDefault() */}
            {this.renderButtonBar(formikProps)}
            <Form onSubmit={formikProps.handleSubmit}>
                {this.renderForm(formikProps, duplicateFromId)}
            </Form>
            <FormikConsumer>{context => { this.formikContext = context; return null; }}</FormikConsumer>
        </>)
    }

    renderFormikChildDuplicate(formikProps: FormikProps<any>) {
        return this.renderFormikChild(formikProps, true);
    }

    /**
     * These buttons are meant for simple usage only. When this is contained in the `CrudEditorPage`, 
     * `hideButtonBar` is true, thus this is disabled. The page has its own button bar.
     */
    renderButtonBar(formikProps: FormikProps<any>): ReactNode {
        const { props: { onCancelHandler } } = this;

        return this.props.hideButtonBar ? null : <Segment className="buttonBar EntityEditorFormSimple_bar">
            <Button data-testid={entityEditorFormSimpleTestids.save} primary disabled={this.props.saveDisabled} onClick={() => formikProps.submitForm()}>{_msg("entityCrud.editor.save")}</Button>

            {/* embedded mode, so call handler */}
            {onCancelHandler && <Button data-testid={entityEditorFormSimpleTestids.cancel} onClick={() => onCancelHandler()} >{_msg("general.cancel")}</Button>}
        </Segment>
    }

    renderForm(formikProps: FormikProps<any>, duplicateFromId?: boolean) {
        const { entityDescriptor } = this.props;
        // the EntityEditorPage_grid => adds some space between the saveButton segment and the form 
        //                              and also align the form items left and right to be at the same position as the other elements(e.g. the saveButton segment )
        // the stackable prop => allows on mobile a configuration of the form with only one column
        let lastFieldIsCustom = false;
        return <Grid stackable columns='equal' className="EntityEditorPage_grid" data-testid={entityEditorFormSimpleTestids.fields}>
            {this.renderHeaderEditor(formikProps)}
            {this.props.fieldsLayout ? this.props.fieldsLayout.map((row, i) =>
                <Grid.Row key={i} className="EntityEditorPage_grid_row" verticalAlign="middle">
                    {row.map(field =>
                        this.renderFieldEditor(field, formikProps)
                    )}
                </Grid.Row>)
                // old logic unchanged for the moment; see #24154 for proposal
                : Object.keys(entityDescriptor.getAuthorizedFields(FIELDS_WRITE))
                    // TODO by CS: inefficient chaining 2 sort, 2 filters; for filters: can move code in renderFieldEditor()    
                    .sort((x, y) => x === y || !this.props.columns ? 0 : this.props.columns?.findIndex(c => c.name === x) > this.props.columns?.findIndex(c => c.name === y) ? 1 : -1)
                    .filter(field => this.props.columnsVisibleMap ? this.props.columnsVisibleMap[field] === true : true) // if embed mode => look at the map; else show all
                    .filter(field => duplicateFromId && field === ID ? false : true)
                    .map(field => {
                        const result = this.renderFieldEditor(field, formikProps);
                        if (!result) {
                            return null;
                        } else {
                            const showDivider = !lastFieldIsCustom && entityDescriptor.getField(field).isCustomField;
                            lastFieldIsCustom = entityDescriptor.getField(field).isCustomField;
                            const row = <Grid.Row key={field} data-testid={entityEditorFormSimpleTestids.field + "_" + field} className="EntityEditorPage_grid_row" verticalAlign="middle">{result}</Grid.Row>;
                            return showDivider ? <>
                                <Divider />
                                { row }
                            </> : row
                        }
                    }
                    )}
        </Grid>
    }

    renderHeaderEditor(formikProps: FormikProps<any>): ReactNode {
        return null;
    }

	/**
	 * WARNING: if you use this, have in the descriptor `field.descriptor = enabled`, and
	 * in this code, based on a condition => set to false. Don't do the opposite (false, and here true)
	 * becaues there will be issues in GraphQL. If `field.descriptor = disabled`, the field is not sent
	 * to the server.
	 */
    protected isFieldEnabled(fieldDescriptor: FieldDescriptor) {
        return fieldDescriptor.enabled;
    }

    renderFieldEditor(field: string, formikProps: FormikProps<any>): ReactNode {
        const { entityDescriptor } = this.props;
        if (field) {
            const fieldDescriptor = entityDescriptor.getField(field)
            let fieldLabel = fieldDescriptor.getLabel(true);

            const fi: Optional<FieldInterval> = fieldDescriptor.getFieldInterval(fieldDescriptor.getFieldValue(formikProps.values));
            const color = fi && fi.color ? AppMetaTempGlobals.appMetaInstance.getColor(fi.color) : undefined;

            return (<Grid.Column key={field} className="EntityEditorPage_grid_row_column" data-testid={entityEditorFormSimpleTestids.fieldLabel + "_" + fieldLabel}>
                <Form.Field key={fieldDescriptor.getFieldName()} disabled={!this.isFieldEnabled(fieldDescriptor)} data-cy={"field" + field} data-testid={entityEditorFormSimpleTestids.field + "_" + field}>
                    <label style={{ color: color }}>{fieldDescriptor.getIcon()}{fieldLabel}</label>
                    {fieldDescriptor.renderFieldEditor(formikProps, (this.props.autoFocusOnField ? { autoFocus: field === this.props.autoFocusOnField } : undefined) as FieldEditorProps)}
                </Form.Field>
            </Grid.Column>)
        } else {
            return (<Grid.Column key={'empty'} className="EntityEditorPage_grid_row_column">
                <div data-cy={"undefined-field"}></div>
            </Grid.Column>)
        }

    }

    // TODO CS/SET: de ce returnam Promise? banuiesc ca asta returneaza si submitForm(); insa faptul ca aici e cu any
    // nu e bine; nu pot da ctrl click; dar de fapt e ref catre form-ul nativ, nu? vad ca e in <Formik innerRef; caz
    // in care numele "formik" nu e bun; caci nu facem ref catre un obiect Formik; ci catre un form nativ. Deci "submitForm()"
    // e al unui form nativ? totusi nu pare la o cautare rapida pe net; sigur nu: https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement
    // vad ca Formik.innerRef nu e documentat; deci e o nebuloasa pt mine cine e asta
    submit(): Promise<void | undefined> {
        return (this.formik as any).current.submitForm();
    }

    render() {
        const { props } = this;

        // CS: there was a reason why I did this, i.e. filter out the props not in the descriptor
        // but I don't remember it; and I didn't document it :(; I leave this code here, to re-enable it
        // if needed; WARNING: doesn't support nested data; needs to be adjusted

        // const entityDescriptor = props.entityDescriptor;
        // const values = props.entity && Object.keys(entityDescriptor.fields).reduce((obj, field) => {
        //     obj[field] = entityDescriptor.fields[field].getFieldValue(props.entity);
        //     return obj;
        // }, {} as any);

        const values = props.entity;

        return (<Formik innerRef={this.formik as any} initialValues={values} enableReinitialize onSubmit={this.onSubmit} >
            {this.renderFormikChild}
        </Formik>);
    }
}

"../CompMeta""./CrudSettings""../utils/Utils"