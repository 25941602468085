import React, { useState } from "react";
import { Grid, Header, Label, Segment, SegmentGroup } from "semantic-ui-react";
import { SplitPaneExt } from "../../copied/ReactSplitPaneExt/ReactSplitPaneExt";
import { TestClassDescriptor } from "../TestsAreDemoMaster";
import "./styles.css";
import { FeaturesList } from "./FeatureList";

export const FeatureBookUi = () => {
    const [testClass, setFeature] = useState<TestClassDescriptor>(window.testsAreDemoMaster!.state.tests[0]);
    return (<>
        <SplitPaneExt size={"25%"} split="vertical">
            <FeaturesList items={window.testsAreDemoMaster!.state.tests} onGetLabel={feature => (feature as TestClassDescriptor).name}
                onSelect={feature => setFeature(feature as TestClassDescriptor)} />
            <FeatureComponent testClass={testClass} />
        </SplitPaneExt>
    </>)
}

const FeatureComponent = (props: { testClass?: TestClassDescriptor }) => (<div className="wh100">
    {props.testClass?.comments && <SegmentGroup size="mini" className="FeatureComponent">
        {props.testClass?.comments?.map((comment, i) => <Segment key={i} className="FeatureComponent_comment">
            <CommentComponent comment={comment} />
        </Segment>)}
    </SegmentGroup>}
    {props.testClass?.functions.map((testFunction, i) =>
        <SegmentGroup key={i} size="mini" className="FeatureComponent">
            {testFunction.comments?.map((comment, i) => (
                <Segment key={i} className="FeatureComponent_comment">
                    <CommentComponent comment={comment} />
                </Segment>
            ))}
            <Segment secondary>
                {/* <Header as='h4' content={scenario.field} subheader={scenario.description} /> */}
                <Label horizontal>{testFunction.functionName}</Label>{testFunction.scenario}
            </Segment>
        </SegmentGroup>)}
</div>)

export function processComment(comment: string) {
    let processedComment = comment;
    processedComment = processedComment.replace(/^\s*/, ""); // remove all white space like, from the beginning until the first "printable" character
    processedComment = processedComment.replace(/\s*$/, ""); // remove all white space like, from the last "printable" character until the end
    return processedComment.split(/\n/); // split by new line
}

/**
 * Each line of text is rendered on a new line. To try to keep the layout from the code.
 * Trailing white lines (at the beginning/end) are removed. But not the ones in the middle.
 */
const CommentComponent = (props: { comment: string }) => {
    return <>{processComment(props.comment).map((line, i) => (<React.Fragment key={i}>
        {i > 0 && <br />}
        {line.trim()}
    </React.Fragment>))}</>
}