import { addEntityDescriptor, EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { CustomQueryEntityEditorPage, sliceCustomQueryEntityEditorPage } from "./CustomQueryEntityEditorPage";
import { addAfterStartupRunnable, entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient } from "@crispico/foundation-react/apolloClient";
import { Filter } from "./Filter";
import React from "react";
import { ClientCustomQuery } from "./ClientCustomQuery";
import { DEFAULT_COMPOSED_FILTER } from "./BlocklyEditorTab";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/AssociationFieldEditor";

export let customQueryEntityDescriptor: EntityDescriptor;

class MyEntityDescriptor extends EntityDescriptor {
    createNewEntity() {
        const entity: ClientCustomQuery = {
            id: null,
            name: '',
            screen: '',
            customQueryDefinitionObject: {
                sorts: [],
                filter: DEFAULT_COMPOSED_FILTER
            },
            preferredColumnConfig: undefined,
            enabled: true,
            dirty: false,
            color: '',
            fromCrudSettings: false,
            emailScheduleRecipientList: undefined,
            emailScheduleCron: '',
            organization: null
        };
        return entity;
    }

}

addAfterStartupRunnable(() => {
    customQueryEntityDescriptor = addEntityDescriptor(new MyEntityDescriptor({
        name: "CustomQuery",
        miniFields: ["name"],
        showInUI: true,
        javaIdType: "Long",
        graphQlIdType: "Long"
    })
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "screen", type: FieldType.entityName })
        .addFieldDescriptor({ name: "name", type: FieldType.string })
        .addFieldDescriptor({ name: "customQueryDefinition", type: FieldType.string, isInDefaultColumnConfigForTable: false })
        .addFieldDescriptor(new class extends FieldDescriptor {
            name = "preferredColumnConfig"
            type = "ColumnConfig"
            protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                const newProps = {
                    ...props,
                    innerEntityDescriptor: entityDescriptors['ColumnConfig'],
                    filter: Filter.create("entityName", FilterOperators.forString.equals, props.formikProps.values.screen)
                }
                return React.createElement(AssociationFieldEditor as any, newProps as FieldEditorProps)
            }
        })
        .addFieldDescriptor({ name: "emailScheduleRecipientList", type: "RecipientList" })
        .addFieldDescriptor({ name: "emailScheduleCron", type: FieldType.cron, optional: true })
    );

    customQueryEntityDescriptor.infoEditor.slice = sliceCustomQueryEntityEditorPage;
    customQueryEntityDescriptor.infoEditor.wrappedComponentClass = CustomQueryEntityEditorPage;

    sliceCustomQueryEntityEditorPage.setEntityDescriptor(customQueryEntityDescriptor);
});"../../entity_crud/EntityDescriptor""../../entity_crud/fieldRenderersEditors""../../entity_crud/entityCrudConstants""../../apolloClient""../../entity_crud/FieldType""../../entity_crud/AssociationFieldEditor"