import { EntityEditorFormSimple } from "../../entity_crud/EntityEditorFormSimple";
import { apolloClient } from "@crispico/foundation-react/apolloClient";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { CrudFormInEditor } from "@crispico/foundation-react/entity_crud/CrudFormInEditor";
import { addAfterStartupRunnable, entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { EntityEditorPage, EntityEditorPageProps, SaveParams, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react/entity_crud/EntityEditorPage";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { SaveFieldsParams } from "@crispico/foundation-react/entity_crud/SaveFieldsParams";
import { createSliceFoundationFromCallback, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY, ENT_DELETE, Utils } from "@crispico/foundation-react/utils/Utils";
import { MutationOptions } from "apollo-client";
import { FormikProps } from "formik";
import gql from "graphql-tag";
import _ from "lodash";
import lodash from 'lodash';
import React from "react";
import { Button, Container, Message, Segment, Tab, TabProps } from "semantic-ui-react";
import { OverrideableElement } from "../TabbedPage/TabbedPage";
import { BlocklyEditorTab, BlocklyEditorTabRRC, DEFAULT_COMPOSED_FILTER } from "./BlocklyEditorTab";
import { ClientCustomQuery } from "./ClientCustomQuery";
import { getCustomQueryForClient, getCustomQueryForServer } from "./CustomQueryDropdown";
import { CustomQuerySource } from "./dataStructures";
import { Filter } from "./Filter";
import { FilterAsText, FilterBar, sliceFilterBar } from "./FilterBar";
import { sliceSortBar, Sort, SortAsText, SortBar } from "./SortBar";
import { SortTab } from "./SortTab";

export const sliceCustomQueryEntityEditorPage = createSliceFoundationFromCallback(() => class SliceCustomQueryEntityEditorPage extends SliceEntityEditorPage {

    initialState = {
        ...sliceEntityEditorPageOnlyForExtension.initialState,
        entitySource: CustomQuerySource.NO_CQ,
        entityDescriptorName: '',
        filter: DEFAULT_COMPOSED_FILTER
    }

    nestedSlices = {
        ...sliceEntityEditorPageOnlyForExtension.nestedSlices,
        sortTab: sliceSortBar,
        filterBar: sliceFilterBar
    }

    reducers = {
        ...sliceEntityEditorPageOnlyForExtension.reducers,
        ...getBaseReducers<SliceCustomQueryEntityEditorPage>(this),

        initializeEditors(state: StateFrom<SliceCustomQueryEntityEditorPage>, customQuery: ClientCustomQuery) {
            let customQueryDefinition = customQuery.customQueryDefinitionObject;
            let filter = lodash.cloneDeep(customQueryDefinition.filter);
            let sorts = lodash.cloneDeep(customQueryDefinition.sorts);
            state.filter = filter ? filter as Filter : DEFAULT_COMPOSED_FILTER;
            state.sortTab.sorts = sorts ? sorts as Array<Sort> : [];
        },

        reset(state: StateFrom<SliceCustomQueryEntityEditorPage>) {
            state.filter = DEFAULT_COMPOSED_FILTER;
            state.sortTab.sorts = [];
            if (state.entity) {
                (state.entity as ClientCustomQuery).customQueryDefinitionObject = { sorts: [], filter: DEFAULT_COMPOSED_FILTER }
            }
        },

        commitSorts(state: StateFrom<SliceCustomQueryEntityEditorPage>, sorts: Array<Sort>) {            
            (state.entity as ClientCustomQuery).customQueryDefinitionObject.sorts = sorts;
        },

        commitFilter(state: StateFrom<SliceCustomQueryEntityEditorPage>, filter: Filter) {           
            (state.entity as ClientCustomQuery).customQueryDefinitionObject.filter = filter;
        },

        commitEmailSchedule(state: StateFrom<SliceCustomQueryEntityEditorPage>, values: any) {
            if (values) {
                state.entity.emailScheduleRecipientList = values.emailScheduleRecipientList;
                state.entity.emailScheduleCron = values.emailScheduleCron;
            }
        },

        clearStateBeforeAddOrEditSuper: sliceEntityEditorPageOnlyForExtension.reducers.clearStateBeforeAddOrEdit,
        clearStateBeforeAddOrEdit(state: StateFrom<SliceCustomQueryEntityEditorPage>) {
            this.clearStateBeforeAddOrEditSuper(state);
            this.reset(state);
        },

        onModeAddSuper: sliceEntityEditorPageOnlyForExtension.reducers.onModeAdd,
        onModeAdd(state: StateFrom<SliceCustomQueryEntityEditorPage>) {
            this.onModeAddSuper(state, null);
            this.initializeEditors(state, state.entity);
            state.entitySource = CustomQuerySource.NEW_CQ;
        },

        onModeLoadedSuper: sliceEntityEditorPageOnlyForExtension.reducers.onModeLoaded,
        onModeLoaded(state: StateFrom<SliceCustomQueryEntityEditorPage>, entity: any) {
            const entityForClient: ClientCustomQuery = getCustomQueryForClient(entity);
            this.onModeLoadedForClientCustomQuery(state, entityForClient);
        },

        onModeLoadedForClientCustomQuery(state: StateFrom<SliceCustomQueryEntityEditorPage>, entityForClient: ClientCustomQuery) {
            this.onModeLoadedSuper(state, entityForClient);
            this.initializeEditors(state, entityForClient);
            state.entityDescriptorName = entityForClient.screen;
            state.entitySource = CustomQuerySource.EDIT;
        }
    }

    impures = {
        ...sliceEntityEditorPageOnlyForExtension.impures,
        ...getBaseImpures<SliceCustomQueryEntityEditorPage>(this),

        invokeSaveMutationSuper: sliceEntityEditorPageOnlyForExtension.impures.invokeSaveMutation,
        async invokeSaveMutation(options: MutationOptions<any, { params: SaveFieldsParams }>) {
            const fieldsAndValuesBeingSent = options.variables!.params.fieldsAndValues as ClientCustomQuery;
            options.variables!.params.fieldsAndValues = getCustomQueryForServer(fieldsAndValuesBeingSent);
            return await this.invokeSaveMutationSuper(options);
        },

        saveSuper: sliceEntityEditorPageOnlyForExtension.impures.save,
        async save(entity: any, navigateToTable = true, customFieldsToUpdate?: string[], params?: SaveParams) {
            // this field is not among what's being sent, because it doesn't have a fieldDescriptor
            this.saveSuper(entity, navigateToTable, customFieldsToUpdate, {
                ...params, initialFieldsAndValues: {
                    customQueryDefinitionObject: (entity as ClientCustomQuery).customQueryDefinitionObject
                }
            })
        },
        
        async delete(id: number) {
            const { entityDescriptor } = this.getSlice();
            const permission = Utils.pipeJoin([ENT_DELETE, entityDescriptor.name]);
            if (!AppMetaTempGlobals.appMetaInstance.hasPermission(permission, true)) {
                return;
            }

            const removeOperationName = `${lodash.lowerFirst(entityDescriptor.name)}Service_deleteById`;
            const removeMutation = gql(`mutation deleteEntity($id: ${this.getSlice().getGraphQlIdType()}){${removeOperationName}(id: $id)}`);

            this.getDispatchers().setInReduxState({modalOpen: false});
            await apolloClient.mutate({ mutation: removeMutation, variables: { id: id } });
        },

        async sendEmail() {
            const query = gql(`query q($id: Long) { customQueryService_sendEmailNow(id: $id) }`);
            await apolloClient.query({ query: query, variables: {id: this.getState().entity.id} });
        }
    }

});

interface CustomQueryEntityEditorPageProps {
    closeEditor?: () => void,
    updateCustomQuery?: (cq: ClientCustomQuery) => void,
    entityDescriptor: string,
    applyColumnConfigFromCustomQuery?: (customQuery: ClientCustomQuery) => void
    openConfirm: (e: any, index: number, id: number) => void,
    sortDisabled?: boolean
}

export let CustomQueryEntityEditorPage: new () => EntityEditorPage<PropsFrom<typeof sliceCustomQueryEntityEditorPage> & CustomQueryEntityEditorPageProps & EntityEditorPageProps>;

addAfterStartupRunnable(() => {

    CustomQueryEntityEditorPage = class extends EntityEditorPage<PropsFrom<typeof sliceCustomQueryEntityEditorPage> & CustomQueryEntityEditorPageProps & EntityEditorPageProps>  {

        blocklyEditorTabRef = React.createRef<BlocklyEditorTab>();
        emailScheduleEditorTabRef = React.createRef<any>();

        constructor(props: Readonly<PropsFrom<typeof sliceCustomQueryEntityEditorPage> & CustomQueryEntityEditorPageProps>) {
            super(props);
            this.props.dispatchers.setInReduxState({ entityDescriptorName: this.props.entityDescriptor })
            const that = this;
            this.editorFormSimpleClass = class extends CrudFormInEditor {
                renderFormikChild(formikProps: FormikProps<any>) {
                    return (<>
                        {this.props.entity?.id === -1 && <Message header={_msg('EntityEditorPage.saveDisabled')} content={_msg('EntityEditorPage.saveDisabledMessage', _msg('CustomQuery.label'))} />}
                        {super.renderFormikChild(formikProps)}
                    </>)
                }

                renderFieldEditor(field: string, formikProps: FormikProps<any>) {
                    if ("customQueryDefinition" === field || "version" === field || ("screen" === field && that.props.embeddedMode)
                        || ("id" === field && that.props.embeddedMode) || field === "emailScheduleRecipientList" || field === "emailScheduleCron") {
                        return null;
                    }
                    if (field === "preferredColumnConfig" && formikProps.values.preferredColumnConfig === null && formikProps.values.emailScheduleRecipientList) {
                        return <>
                            {super.renderFieldEditor(field, formikProps)}
                            <Message warning visible compact style={{margin: '0.5em'}}>{_msg("EmailSchedule.defaultColumnConfig")}</Message>
                        </>
                    }
                    return super.renderFieldEditor(field, formikProps);
                }
            }

        }

        // CS: nu sterge te rog comentariile de mai jos; vreau sa folosesc ultima linie pentru demo folosire ReduxTools
        componentDidUpdate(prevProps: Readonly<PropsFrom<typeof sliceCustomQueryEntityEditorPage> & CustomQueryEntityEditorPageProps>) {
            super.componentDidUpdate(prevProps);
            // if (!_.isEqual(this.props.filter, this.filterBarRef.current?.blocklyEditorRef.current?.rootFilter)) {
            //     if (!_.isEqual(prevProps.filter, this.props.filter)) {
            //         // CS: I think in theory could happen; but in practice, nobody will change the input filter while this page (modal) is shown
            //         this.filterBarRef.current?.blocklyEditorRef.current?.props.r.setInReduxState({rootFilter: this.props.filter});
            //     } else {
            //         // this.props.dispatchers.setInReduxState({filter: this.filterBarRef.current?.blocklyEditorRef.current?.rootFilter});
            //     }  
            // }
        }

        protected onModalClose() {
            if (AppMetaTempGlobals.appMetaInstance.hasPermission(CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY)) {
                this.onApply();
            }
        }

        protected async onRevert() {
            const id = this.props.entity.id;
            if (id !== -1 && id !== -2) {
                this.load(this.props.entity.id);
            } else {
                this.props.dispatchers.onModeLoaded(getCustomQueryForServer(entityDescriptors[this.props.entityDescriptor].getDefaultCustomQuery()));
            }
        }

        protected isSaveEnabled(): boolean {
            return this.props.entity?.id !== -1 && this.props.entity?.id !== -2 && super.isSaveEnabled();
        }

        protected isDeleteEnabled(): boolean {
            // disable for default entity/shared cc;
            const entityDescriptorName: string = (this.props.dispatchers.getSlice() as SliceEntityEditorPage).entityDescriptor.name;
            const permission = Utils.pipeJoin([ENT_DELETE, entityDescriptorName]);
            return this.props.entity?.id !== -1 && this.props.entity?.id !== -2 && AppMetaTempGlobals.appMetaInstance.hasPermission(permission, false);
        }

        protected commitMain() {
            super.commitMain();
            // TODO by CS: I think it's for the case when we use the editor as popup, and it doesn't go through the load() functions
            // we should have a clearer mechanism for this case as well
            // UPDATE: this is also usefull when adding a new CQ from CRUD/Add, and 1) set entity name, 2) set sorts, 3) change again entity name
            // => 4) the next tabs are reset, because the info that was inputted is no longer value
            if (this.refFormSimple.current && this.refFormSimple.current.formikContext.values.screen !== this.props.entityDescriptorName) {
                this.props.dispatchers.reset();
                this.props.dispatchers.setInReduxState({ entityDescriptorName: this.refFormSimple.current.formikContext.values.screen })
            }
        }

        protected getTitle(): string | { icon: JSX.Element | string, title: JSX.Element | string } {
            if (this.props.embeddedMode) {
                return {icon: "filter", title: _msg("CustomQuery.label") + ": " + this.props.entity?.name + (this.props.entity?.dirty ? (" (" + _msg("EntityEditorPage.modified") + "*)") : "")}
            } else {
                return super.getTitle();
            }
        }

        protected embeddedModeRenderTab(props: TabProps) {
            if (this.embeddedMode) {
                props = {...props, ...{ defaultActiveIndex: 1 }};
            }
            return React.createElement(Tab, props);
        }

        protected async onSaveAs() {
            await Utils.setTimeoutAsync();
            this.triggerCommitForAll();
            if (this.props.onSave) {
                const entity = _.cloneDeep(this.props.entity);
                // So that we don't create 2 program defaults, the unique constraint on name will fail
                // for Program default, because it is not stored in the db
                if (entity.name === "Program default") {
                    entity.name = "Program default-copy";
                }
                entity.id = null;
                this.props.dispatchers.save(entity);
            }
            if (this.modalProps) {
                this.props.dispatchers.setModalOpen(false);
            }
        }

        protected preRenderButtons(params: {}): Array<OverrideableElement> {
            const buttons: Array<OverrideableElement> = super.preRenderButtons(params).map((button: any) => {
                if (button && button.props.key === "apply" && !AppMetaTempGlobals.appMetaInstance.hasPermission(CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY)) {
                    button.props.disabled = true;
                }
                return button;
            });
            const indexForEmbeddedButtons = buttons.findIndex(b => (b?.valueOf() as any)?.props?.key === "save") + 1;
            if (this.embeddedMode) {
                this.isDeleteEnabled() && buttons.splice(indexForEmbeddedButtons, 0, { elementType: Button, props: { key: "delete", color: "red", onClick: (e: any) => this.props.openConfirm(e, this.props.entity.id, this.props.entity.id), content: <>{_msg("entityCrud.table.delete")}</>, "data-testid": "CustomQueryEntityEditorPage_delete" } });
                super.isSaveEnabled() && buttons.splice(indexForEmbeddedButtons, 0, { elementType: Button, props: { key: "saveAs", color: "blue", onClick: () => this.onSaveAs(), content: <>{_msg("general.saveAs")}</>, "data-testid": "CustomQueryEntityEditorPage_saveAs" } });
            }
            return buttons;
        }

        protected getExtraTabPanes() {
            const extraTabs = [{
                routeProps: { path: "/filterEditor" }, menuItemProps: { icon: "filter", content: _msg("CustomQueryEntityEditorPage.tab.filter") },

                // in case of issues, take a look at #24316
                commit: () => {
                    if (this.blocklyEditorTabRef.current) {
                        this.props.dispatchers.commitFilter(this.blocklyEditorTabRef.current?.rootFilter);
                    }
                },
                
                render: () => {
                    const cls = this.getContainerCssClasses();
                    return <Container className={cls.outer} fluid>
                        <Segment className={cls.inner}>
                            {this.renderHeader({})}
                            <BlocklyEditorTabRRC id="blocklyEditorTab" initialFilter={this.props.filter} entityDescriptor={this.props.entityDescriptorName}  ref={this.blocklyEditorTabRef} ownRef={this.blocklyEditorTabRef} />
                        </Segment>
                    </Container>
                }
            }];

            if (AppMetaTempGlobals.appMetaInstance.showCrudButtons.showCustomQueryEmailSchedule) {
                extraTabs.push({
                    routeProps: { path: "/emailSchedule" }, menuItemProps: { icon: "clock outline", content: _msg("EmailSchedule.label") },

                    commit: () => {
                        if (this.emailScheduleEditorTabRef.current) {
                            this.props.dispatchers.commitEmailSchedule(this.emailScheduleEditorTabRef.current.formikContext.values)
                        }
                    },

                    render: () => {
                        const emailScheduleEntityDescriptor = new EntityDescriptor({name: "EmailSchedule"})
                            .addFieldDescriptor({ name: "emailScheduleRecipientList", type: "RecipientList" })
                            .addFieldDescriptor({ name: "emailScheduleCron", type: FieldType.cron })
                        const cls = this.getContainerCssClasses();
                        return <Container className={cls.outer} fluid>
                            <Segment className={cls.inner}>
                                {this.renderHeader({})}
                                <EntityEditorFormSimple entityDescriptor={emailScheduleEntityDescriptor} entity={this.props.entity} 
                                    ref={this.emailScheduleEditorTabRef} hideButtonBar />
                                <Button primary disabled={!this.props.entity || this.props.entity.id === null || this.props.entity.id === -1} onClick={() => this.props.dispatchers.sendEmail()}>{_msg("EmailSchedule.sendEmail")}</Button>
                            </Segment>
                        </Container>
                    }
                });
            }

            if (!this.props.sortDisabled) {
                extraTabs.unshift({
                    routeProps: { path: "/sortEditor" }, menuItemProps: { icon: "sort", content: _msg("CustomQueryEntityEditorPage.tab.sort") },
    
                    commit: () => {
                        this.props.dispatchers.commitSorts(this.props.sortTab.sorts)
                    },
    
                    render: () => {
                        const cls = this.getContainerCssClasses();
                        return <Container className={cls.outer} fluid>
                            <Segment className={cls.inner}>
                                {this.renderHeader({})}
                                <SortTab noApply={this.props.entitySource === CustomQuerySource.DROPDOWN_LIST || this.props.entitySource === CustomQuerySource.SAVE_AS} entityDescriptor={this.props.entityDescriptorName} {...this.props.sortTab} dispatchers={this.props.dispatchers.sortTab} />
                            </Segment>
                        </Container>
                    }
                });
            }

            if (this.props.embeddedMode) {
                extraTabs.unshift({
                    routeProps: { path: "/sortFilterEditor" },  menuItemProps: { icon: "filter", content: _msg("CustomQueryEntityEditorPage.tab.basic") },
    
                    commit: () => {
                        this.props.dispatchers.commitSorts(this.props.sortTab.sorts)
                        this.props.dispatchers.commitFilter(this.props.filter)
                    },
    
                    render: () => {
                        const cls = this.getContainerCssClasses();
                        const filter = this.props.filter;
                        return <Container className={cls.outer} fluid>
                            <Segment data-testid="CustomQueryEntityEditorPage_basicSortFilterTab" className={cls.inner}>
                                {this.renderHeader({})}

                                {!this.props.sortDisabled ? <>
                                    <SortAsText entityDescriptor={this.props.entityDescriptor} sorts={this.props.sortTab.sorts} />
                                    <span className="tiny-margin-right"/>
                                </> : null}
                                {filter ? <FilterAsText ed={entityDescriptors[this.props.entityDescriptor]} filter={filter} /> : null}
                                {!this.props.sortDisabled ? <div className="CustomQueryBar_div CustomQueryBar_row">
                                    <SortBar entityDescriptor={this.props.entityDescriptor} {...this.props.sortTab} dispatchers={this.props.dispatchers.sortTab} />
                                </div> : null}
                                <div className="CustomQueryBar_div CustomQueryBar_row">
                                    <FilterBar entityDescriptor={this.props.entityDescriptor} rootFilter={filter} {...this.props.filterBar} dispatchers={this.props.dispatchers.filterBar} setFilterInCustomQuery={(filter) => this.props.dispatchers.setInReduxState({filter: filter})} showTooltip={false} />
                                </div>
                            </Segment>
                        </Container>
                    }
                });
            } 

            return extraTabs;
        }

    } as any; // I don't know whay the types are incompatible; hence forcing
});"../../apolloClient""../../AppMetaTempGlobals""../../entity_crud/CrudFormInEditor""../../entity_crud/entityCrudConstants""../../entity_crud/EntityDescriptor""../../entity_crud/EntityEditorPage""../../entity_crud/FieldType""../../entity_crud/SaveFieldsParams""../../reduxHelpers""../../utils/Utils"