import { FilterOperators } from "@crispico/foundation-gwt-js";
import { Organization } from "@crispico/foundation-react";
import { apolloClientHolder } from "@crispico/foundation-react/apolloClient";
import { Optional } from "@crispico/foundation-react/CompMeta";
import { PeriodPickerRRC, PeriodPicker } from "@crispico/foundation-react/components/periodPicker/PeriodPicker";
import { entityDescriptors, ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { dashboardCalculateForRecordsWidgetEntityDescriptor } from "@crispico/foundation-react/FoundationEntityDescriptors";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import { Datum, DatumValue, Serie } from "@nivo/line";
import gql from "graphql-tag";
import _ from "lodash";
import lodash from 'lodash';
import moment from "moment";
import React from "react";
import { NavLink } from "react-router-dom";
import SplitPane from "react-split-pane";
import { Button, Container, Dimmer, Icon, Input, Label, Loader, Segment } from "semantic-ui-react";
import { ChartCurrentSelection } from '../ChartCurrentSelection/ChartCurrentSelection';
import { Filter } from "../CustomQuery/Filter";
import { ResponsiveLineExt } from "../nivoExt";
import { ResponsiveLineChart, ResponsiveLineChartRRC } from "../responsiveLineChart/ResponsiveLineChart";
import { MessageExt } from "../semanticUiReactExt";
import { CalculateForRecordsWidgetConfig, COMMA_SEPARATOR, createChartValuesForParentOrganizations, isCompatibleWithDataExplorerFilter, ORGANIZATION } from "./CalculateForRecords";
import { GET_SAVED_DATA } from "./queries";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";

export class CalculateForRecordsChartState extends State {
        data = [] as Array<Serie>;
        selectedPoint = undefined as Optional<number>;
        ids = {} as { [key: string]: string };
        details = undefined as Optional<Array<any>>;
        status = "";
        quickSearch = "";
        widgetId = undefined;
        average = 0;
};

export class CalculateForRecordsChartReducers<S extends CalculateForRecordsChartState = CalculateForRecordsChartState> extends Reducers<S> { };

export type CalculateForRecordsChartProps = RRCProps<CalculateForRecordsChartState, CalculateForRecordsChartReducers> & {
    dashboardId: number, widgetIdIfReferenced: string, widgetConfig: CalculateForRecordsWidgetConfig, onlyChart?: boolean, format: string, selectedGroupByKey: string, groupByLabel: React.ReactNode,
    valueIndex: number, percentageMode: Optional<boolean>, dataExplorerFilter?: Filter, expandedOrganization: Optional<Organization>
}

export class CalculateForRecordsChart extends React.Component<CalculateForRecordsChartProps> {

    protected periodPickerRef = React.createRef<PeriodPicker>();
    entityDescriptor = entityDescriptors[this.props.widgetConfig.entityType];
    protected responsiveLineChartRef = React.createRef<ResponsiveLineChart>();

        async getEntityMiniFields(entityDescriptor: EntityDescriptor, widgetConfig: CalculateForRecordsWidgetConfig, selectedGroupByKey: string) {
            if (!this.props.s.ids[this.props.s.selectedPoint!]) {
                this.props.r.setInReduxState({ details: undefined });
                return;
            }

            this.props.r.setInReduxState({ status: "loadingDetails" });
            const loadOperationName = `${lodash.lowerFirst(entityDescriptor.name)}Service_findByFilter`;
            const query = gql(`query q($params: FindByFilterParamsInput) { 
                ${loadOperationName}(params: $params) {
                    results { ${ID} ${entityDescriptor.getGraphQlFieldsToRequest(entityDescriptor.miniFields)} }
                }
            }`);
            let filter = Filter.create(ID, FilterOperators.forNumber.in, this.props.s.ids[this.props.s.selectedPoint!]);

            if (selectedGroupByKey !== "") {
                filter = Filter.createComposed(FilterOperators.forComposedFilter.and, [filter]);

                const keys = selectedGroupByKey.split(COMMA_SEPARATOR);
                const groupByFieldsArray = widgetConfig.groupByFields.split(COMMA_SEPARATOR);

                for (var i = 0; i < keys.length; i++) {
                    if (widgetConfig.groupBySuborganizations && i === 0) {
                        filter.filters!.push(Filter.create(ORGANIZATION, FilterOperators.forNumber.equals, keys[i]));
                    } else {
                        let incPos = 0;
                        if (widgetConfig.groupBySuborganizations) {
                            incPos = 1;
                        }

                        if (!groupByFieldsArray || i - incPos >= groupByFieldsArray.length) {
                            continue;
                        }

                        filter.filters!.push(Filter.create(groupByFieldsArray[i - incPos], FilterOperators.forNumber.equals, keys[i]));
                    }
                }
            }

            const result = (await apolloClientHolder.apolloClient.query({ query: query, variables: FindByFilterParams.create().filter(filter), context: { showSpinner: false } })).data[loadOperationName];
            this.props.r.setInReduxState({ details: result.results, status: "" });
        }

    componentDidMount() {
        this.validateAndGetData();
    }

    componentDidUpdate(prevProps: CalculateForRecordsChartProps) {
        const props = this.props;
        if ((!props.s.data[0]?.data.length || (props.s.data[0].data.length === 1 && props.s.data[0]?.data[0].y === 0)) && (!prevProps || !(prevProps.groupByLabel as any)?.props?.children[0]?.props) && (props.groupByLabel as any)?.props?.children[0]?.props) {
            props.r.setInReduxState({ data: [{ id: "default", data: [{ x: moment().unix() * 1000, y: (props.groupByLabel as any)?.props?.children[0]?.props?.children }] }] });
        } else if (!props.s.data[0]?.data.length) {
            props.r.setInReduxState({ data: [{ id: "default", data: [{ x: moment().unix() * 1000, y: 0 }] }] });
        }
    }

    getSelectedGroupByKeyForFilter() {
        if (this.props.dataExplorerFilter && this.props.widgetConfig.groupBySuborganizations && this.props.widgetConfig.groupByFields) {
            const selectedGroupByKeyForFilter: string[] = [];
            this.props.widgetConfig.groupByFields.split(",").forEach(field => {
                const value = getGroupingForField(this.props.dataExplorerFilter!, field)[0];
                if (!value) {
                    return;
                }
                selectedGroupByKeyForFilter.push(value.split("|/|")[0]);
            });
            return selectedGroupByKeyForFilter.join(",");
        }
        return "";
    }

    renderDetails() {
        if (this.props.s.status !== "") {
            return (
                <Segment className="wh100">
                    <Dimmer active><Loader size='medium'>{_msg("general.loading")}</Loader></Dimmer>
                </Segment>);
        }

        if (!this.props.s.selectedPoint) {
            return <p>{_msg("CalculateForRecords.noPointSelected")}</p>
        }

        return this.props.s.details?.map((value, i) => {
            const label = this.entityDescriptor.toMiniString(value);
            if (this.props.s.quickSearch && label.toLowerCase().indexOf(this.props.s.quickSearch.toLowerCase()) < 0) {
                return null;
            }
            return <NavLink key={i} to={this.entityDescriptor.getEntityEditorUrl(value[ID])}>
                <Label style={{ marginBottom: "5px" }} basic horizontal color="blue">
                    {typeof this.entityDescriptor.icon === 'string' ? <Icon name={this.entityDescriptor.icon} /> : this.entityDescriptor.icon}
                    {label}
                </Label>
            </NavLink>
        }
        );
    }

    protected formatY = (datum: DatumValue) => {
        return this.props.percentageMode ? datum.valueOf() + "%" : (this.props.format ? (moment.duration(datum.valueOf(), 'milliseconds') as any).format(this.props.format) : datum.valueOf());
    }

    protected async getData(dashboardId: number, widgetIdIfReferenced: string, calculationLogic: string, selectedGroupByKey: string, valueIndex: number, currentOrganization: Optional<Organization>) {
        this.props.r.setInReduxState({ status: "loadingHeader" });

        const widgetId = (await apolloClientHolder.apolloClient.query({
            query: gql`
                query dashboardCalculateForRecordsWidgetService_findByFilter($params: FindByFilterParamsInput) {
                    dashboardCalculateForRecordsWidgetService_findByFilter(params: $params) {
                        results {
                            id
                        }
                    }
                }`,
            variables: FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, [
                Filter.create("dashboard.id", FilterOperators.forNumber.equals, dashboardId.toString()),
                Filter.create("widgetIdIfReferenced", FilterOperators.forNumber.equals, widgetIdIfReferenced),
            ])),
            context: { showSpinner: false }
        })).data.dashboardCalculateForRecordsWidgetService_findByFilter.results[0]?.id;
        if (!widgetId || !this.periodPickerRef.current) {
            this.props.r.setInReduxState({ status: "" });
            return;
        }
        this.props.r.setInReduxState({ widgetId: widgetId });

        const params = FindByFilterParams.create().sorts([{ field: "timestamp", direction: "ASC" }])
            .filter(Filter.createComposed(FilterOperators.forComposedFilter.and, [
                Filter.create("widget.id", FilterOperators.forNumber.equals, widgetId.toString()),
                Filter.create("timestamp", FilterOperators.forDate.greaterThanOrEqualTo, moment(this.periodPickerRef.current!.getStartDate()).toISOString()),
                Filter.create("timestamp", FilterOperators.forDate.lessThanOrEqualTo, moment(this.periodPickerRef.current!.getEndDate()).toISOString())
            ]));

        const result = (await apolloClientHolder.apolloClient.query({ query: GET_SAVED_DATA, variables: params, context: { showSpinner: false } })).data.dashboardCalculateForRecordsValueService_findByFilter

        const data: Datum[] = [];
        const ids: { [key: string]: string } = {};

        const orgId = this.props.expandedOrganization ? this.props.expandedOrganization.id : global.currentOrganizationToFilterBy ? global.currentOrganizationToFilterBy.id : currentOrganization?.id;
        const points = createChartValuesForParentOrganizations(result.results, calculationLogic, currentOrganization);
        let sum = 0;
        for (const point of points) {
            const date = moment(point.timestamp * 1000).toDate().getTime();
            let value = 0;
            if (orgId && selectedGroupByKey.length > 0 && point.result[orgId + "," + selectedGroupByKey]) {
                value = point.result[orgId + "," + selectedGroupByKey];
            } else if (orgId && selectedGroupByKey.length === 0) {
                value = point.result[orgId];
            } else if (point.result[selectedGroupByKey]) {
                value = point.result[selectedGroupByKey];
            }
            data.push({ x: date, y: value });
            sum += value;
            ids[date] = point.records;
        }

        // WARNING: id = 0 => not accepted any more by nivo
        this.props.r.setInReduxState({ data: [{ id: "default", color: "var(--blue)", data }] as Serie[], ids, status: "", average: data.length === 0 ? 0 : Math.round(100 * sum / data.length) / 100.0 });
    }

    validateAndGetData() {
        if (!this.props.dataExplorerFilter || isCompatibleWithDataExplorerFilter(entityDescriptors[this.props.widgetConfig.entityType], this.props.dataExplorerFilter).length === 0) {
            this.getData(this.props.dashboardId, this.props.widgetIdIfReferenced, this.props.widgetConfig.calculationLogic, this.getSelectedGroupByKeyForFilter(), this.props.valueIndex, AppMetaTempGlobals.appMetaInstance.getCurrentOrganization());
        }
    }

    render() {
        if (this.props.dataExplorerFilter && isCompatibleWithDataExplorerFilter(entityDescriptors[this.props.widgetConfig.entityType], this.props.dataExplorerFilter).length > 0) {
            return <Container fluid>{_msg("CalculateForRecords.error.history")} {isCompatibleWithDataExplorerFilter(entityDescriptors[this.props.widgetConfig.entityType], this.props.dataExplorerFilter!).join(", ")}.</Container>;
        }
        const chart = <div className="flex-container flex-grow wh100">
            <Dimmer inverted active={this.props.s.status === "loadingHeader"}><Loader size='medium'>{_msg("general.loading")}</Loader></Dimmer>
            <div className={(this.props.onlyChart ? "CalculateForRecords_hide" : "")}><MessageExt>
                {/* this.props.onlyChart is needed because this RRC is used twice when we enable show chart in widget */}
                <PeriodPickerRRC id={'periodPicker' + this.props.onlyChart + this.props.dashboardId + this.props.widgetIdIfReferenced + this.props.expandedOrganization?.id} ref={this.periodPickerRef}
                    onChange={() => this.validateAndGetData()}
                />
                &nbsp;&nbsp;<Label basic style={{ fontSize: "inherit" }}>{_msg("CalculateForRecords.chart.average")}: {this.props.s.average}</Label>
                <Button size={"small"} as={NavLink} disabled={!this.props.s.widgetId} to={dashboardCalculateForRecordsWidgetEntityDescriptor.getEntityEditorUrl(this.props.s.widgetId)} style={{ float: "right" }} >{_msg("CalculateForRecords.chart.button")}</Button>
            </MessageExt>
            </div>
            {this.props.onlyChart ? <ResponsiveLineExt data={this.props.s.data} legends={undefined} margin={{ top: 20, bottom: 20, right: 20, left: 40 }}
                yFormat={this.formatY} axisLeft={{ format: this.formatY }} curve="stepAfter"
                onClick={e => {
                    if (this.props.onlyChart || !e?.data) {
                        return;
                    }
                    this.props.r.setInReduxState({ selectedPoint: moment(e.data.x).toDate().getTime() });
                    setTimeout(() => this.getEntityMiniFields(this.entityDescriptor, this.props.widgetConfig, this.props.selectedGroupByKey));
                }}
            /> : <Segment basic={this.props.onlyChart} className="flex-grow" style={{ overflow: "hidden" }}>
                <ResponsiveLineChartRRC id={'CRLC' + this.props.dashboardId + this.props.widgetIdIfReferenced + this.props.expandedOrganization?.id}
                    ref = {this.responsiveLineChartRef}
                    series={this.props.s.data}
                    startDate={moment(this.periodPickerRef.current?.getStartDate()).valueOf()}
                    endDate={moment(this.periodPickerRef.current?.getEndDate()).valueOf()}
                    hasZoomSlider={!this.props.onlyChart} yFormat={this.formatY} axisLeft={{ format: this.formatY }} curve="stepAfter"
                    onClick={e => {
                        if (this.props.onlyChart || !e?.data) {
                            return;
                        }
                        this.props.r.setInReduxState({ selectedPoint: moment(e.data.x).toDate().getTime() });
                        setTimeout(() => this.getEntityMiniFields(this.entityDescriptor, this.props.widgetConfig, this.props.selectedGroupByKey));
                    }}
                />
            </Segment>}
        </div>;
        if (this.props.onlyChart) {
            return chart;
        }
        return (<SplitPane className="flex-container flex-grow" split="horizontal" defaultSize="45%" style={{ position: "static" }} primary="second">
            {chart}
            <div className="flex-container flex-grow">
                <ChartCurrentSelection date={this.props.s.selectedPoint ? moment(this.props.s.selectedPoint).format(Utils.dateTimeFormat) : undefined}
                    contentHeader={<>
                        {this.props.groupByLabel && <Label basic>
                            {this.props.groupByLabel}
                            <Icon name='triangle down' />
                        </Label>}
                        &nbsp;
                        {_msg("CalculateForRecords.quickSearch")} <Input style={{ fontSize: "12px" }} icon='search' value={this.props.s.quickSearch} onChange={(e, data) => this.props.r.setInReduxState({ quickSearch: data.value })} />
                    </>}
                    contentMain={
                        <Segment className="HistogramPresenceInTerritoriesTab_segment flex-grow">
                            {this.renderDetails()}
                        </Segment>} />
            </div>
        </SplitPane>);
    }
}

export const CalculateForRecordsChartRRC = ReduxReusableComponents.connectRRC(CalculateForRecordsChartState, CalculateForRecordsChartReducers, CalculateForRecordsChart);

function getGroupingForField(filter: Filter, field: string) {
    if (!filter.enabled) {
        return [];
    }
    if (filter.field === field) {
        return typeof filter.value === "string" ? [filter.value] : [];
    }
    if (filter.filters) {
        let value: string[] = [];
        filter.filters.forEach(filter => {
            value = value.concat(getGroupingForField(filter, field));
        });
        return value;
    }
    return [];
}"../..""../../apolloClient""../../CompMeta""../periodPicker/PeriodPicker""../../entity_crud/entityCrudConstants""../../entity_crud/EntityDescriptor""../../entity_crud/FindByFilterParams""../../FoundationEntityDescriptors""../../reduxReusableComponents/ReduxReusableComponents""../../utils/Utils""../../AppMetaTempGlobals"