import React from "react";
import { SyntaxHighlighterExt } from "../SourceCode";
import { TestsAreDemoMaster } from "../TestsAreDemoMaster";
import { Button } from "semantic-ui-react";

interface Props {
    master: TestsAreDemoMaster;
    capturedUiApiCalls?: string;
}

interface State {
    captureEnabled: boolean;
}

export class CaptureUiApiEvents extends React.Component<Props, State> {

    state: Readonly<State> = {
        captureEnabled: true
    }

    async onCapture(domElement: HTMLElement, generatedSourceCode: string): Promise<boolean> {
        if (!this.state.captureEnabled) {
            return true;
        }
        const result = await new Promise<boolean>(resolve => {
            this.props.master.slave.setState({ inspectedElement: domElement, inspectedElementPopup: this.renderQuestion(generatedSourceCode, resolve) })
        });
        this.props.master.slave.setState({ inspectedElement: undefined, inspectedElementPopup: undefined });
        if (result) {
            const src = this.props.capturedUiApiCalls ? this.props.capturedUiApiCalls + "\n" : "";
            this.props.master.setState({ capturedUiApiCalls: src + generatedSourceCode });
        }
        return result;
    }

    renderQuestion(generatedSourceCode: string, resolve: (value: boolean) => void) {
        return <>
            <SyntaxHighlighterExt className="" showLineNumbers={false}>{generatedSourceCode}</SyntaxHighlighterExt>
            <Button positive content="Continue" onClick={() => resolve(true)} />
            <Button negative content="Cancel" onClick={() => resolve(false)} />
        </>
    }

    render() {
        const content = this.props.capturedUiApiCalls || `/**
* "Capture UiApi events" is enabled when this tab is opened 
* and "Capture enabled" == true
*
* 1/ Perform some actions in the UI
* 2/ Captured events will appear here
* 3/ Copy/paste in your project/TAD file(s)
*/`;
        return <>
            {this.props.master.state.running ? <>This UI is disabled because a test is running. </> : <>
                <div>
                    <Button labelPosition="left" content="Capture enabled" onClick={() => this.setState({ captureEnabled: !this.state.captureEnabled })}
                        icon={"square outline" + (this.state.captureEnabled ? " check" : "")} color={this.state.captureEnabled ? "green" : undefined}
                    />
                    <Button icon="erase" content="Clear" onClick={() => {this.props.master.setState({ capturedUiApiCalls: undefined })}} />
                </div>
                <SyntaxHighlighterExt>{content}</SyntaxHighlighterExt>
            </>}
        </>
    }
}