import { ReduxReusableComponents, RRCProps } from '@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents';
import { MultiCsvEditorState, MultiCsvEditor, MultiCsvEditorReducers, PropsNotFromState, EntityType, RowsType, csvNewLine, multiCsvEditorTestids, Entity } from "@crispico/foundation-react/components/multiCsvEditor/MultiCsvEditor"
import { Button, Checkbox, Form, Icon, Label, Popup } from 'semantic-ui-react';
import { addAfterStartupRunnable, entityDescriptors } from '@crispico/foundation-react/entity_crud/entityCrudConstants';
import _, { values } from 'lodash';
import gql from 'graphql-tag';
import { apolloClient, apolloClientHolder, ApolloContext, CatchedGraphQLError } from '@crispico/foundation-react/apolloClient';
import { FindByFilterParams } from '@crispico/foundation-react/entity_crud/FindByFilterParams';
import { Utils } from '@crispico/foundation-react/utils/Utils';
import { Filter } from '../CustomQuery/Filter';
import { Sort } from '../CustomQuery/SortBar';
import { FilterOperators } from '@crispico/foundation-gwt-js';
import { createTestids, TestsAreDemoCheat } from '@famiprog-foundation/tests-are-demo';
import { Cell, Column } from 'fixed-data-table-2';
import { addEntityDescriptor, EntityDescriptor } from '@crispico/foundation-react/entity_crud/EntityDescriptor';
import { JPA_GOODIES_SERVICE_GET_MULTI_CSV_SQL } from './queries'
import { FieldType } from '@crispico/foundation-react/entity_crud/FieldType';

function typeIsOneToMany(type: string) {
    return type[0] === '[';
}

function getType(type: string) {
    if (typeIsOneToMany(type))
        return type.slice(1, -1);
    return type;
}

export type EntryType = { [key: number]: boolean }

export class CsvExporterState extends MultiCsvEditorState {
    openExportModal: boolean = false;
    selectedEntries: { [key: string]: EntryType } = {};
    loadedEnitities: { [key: number]: boolean } = {};
    columnsInfo: { [key: string]: { oneToMany: boolean, type: FieldType } } = {};
    recursiveMode = false;
    printLogs = true;
}
export class CsvExporterReducers<S extends CsvExporterState = CsvExporterState> extends MultiCsvEditorReducers<S> {

    protected _getCurrentEntity() {
        return this.s.entities[this.s.currentTabIndex];
    }

    toggleEntry(rowIndex: number) {
        let copy = _.cloneDeep(this.s.selectedEntries);
        if (!this.s.selectedEntries[this._getCurrentEntity().entityName]) {
            this.s.selectedEntries[this._getCurrentEntity().entityName] = {}
        }
        copy = _.cloneDeep(this.s.selectedEntries);
        this.s.selectedEntries[this._getCurrentEntity().entityName][rowIndex] ?
            delete this.s.selectedEntries[this._getCurrentEntity().entityName][rowIndex] : this.s.selectedEntries[this._getCurrentEntity().entityName][rowIndex] = true;
        copy = _.cloneDeep(this.s.selectedEntries);

    }

    selectOrDeselectAll(checked: boolean, rowsNumber: number, idx?: number) {
        let entityName = idx !== undefined ? this.s.entities[idx].entityName : this._getCurrentEntity().entityName;
        if (!this.s.selectedEntries[entityName]) {
            this.s.selectedEntries[entityName] = {}
        }
        if (!checked) {
            for (const key in this.s.selectedEntries[entityName]) { delete this.s.selectedEntries[entityName][key] }
        } else {
            for (let i = 0; i < rowsNumber; i++) { this.s.selectedEntries[entityName][i] = true }
        }
    }

    setEntityRows(rows: RowsType, index?: number) {
        /**
         * manyToOne entities from table store more than just the id (ex: for Employee, department field is
         * {id: -200, qualifiedName: 'company1', __typename: 'Organization'} but we only need to export the id)
         * so we have to get rid of the object and keep only the id
         */
        let tabIndex = index ? index : this.s.currentTabIndex;

        this.s.entities[tabIndex].rows = rows;
        this.s.loadedEnitities[tabIndex] = true;
        this.selectOrDeselectAll(true, rows.length, tabIndex);
    }
}

type ExtraProps = {
    entities: EntityType,
    filter: Filter,
    sorts: Sort[],
    loadQueryParams: {
        loadOperationName: string;
        loadQuery: any;
    }
};

type GlobalProps = RRCProps<CsvExporterState, CsvExporterReducers> & PropsNotFromState & ExtraProps;

export const csvExporterTestids = createTestids("CsvExporter", {
    load: "", loadReferencedIds: "", loadReferencedIdsRecursive: "",  export: "", exportFromServer: ""
});

export class CsvExporter<T extends GlobalProps = GlobalProps> extends MultiCsvEditor<T> {
    async componentDidMount() {
        this.props.r.setInReduxState({ entities: this.props.entities, loadedEnitities: { 0: true } });
        await this.loadEntity();
    }

    prepareRows(rows: RowsType, entityName: string) {
        Object.entries(rows).map(([key, row]) => {
            Object.entries(row).map(([field, fieldValue]: ([key2: any, value2: any])) => {
                const idFields = entityDescriptors[entityName]?.idFields;
                if (!this.props.s.recursiveMode && idFields.length > 1) {
                    alert(_msg("CsvExporter.composedPKAlert.label", entityName))
                    return;
                }
                const idField = idFields[0];
                if (fieldValue instanceof Array) {
                    let ids = "[";
                    for (let i = 0; i < fieldValue.length; i++) {
                        if (fieldValue[i][idField]) {
                            ids += fieldValue[i][idField];
                            if (i !== fieldValue.length - 1) ids += ', ';
                        }
                    };
                    ids += ']';
                    ids !== "[]" ? row[field] = ids : row[field] = null;
                } else if (typeof fieldValue === 'object' && fieldValue !== null) {
                    row[field] = fieldValue[idFields[0]];
                }
            })
        })

        return rows;
    }

    addReferencedIds(index?: number, entitis?: EntityType, loadedEnititis?: { [key: number]: boolean }, selectedEntris?: { [key: string]: EntryType }) {
        let tabIndex = index ? index : this.props.s.currentTabIndex;
        let loadedEnitities = loadedEnititis ? loadedEnititis : _.cloneDeep(this.props.s.loadedEnitities);
        let entities = entitis ? entitis : _.cloneDeep(this.props.s.entities);
        let selectedEntries = selectedEntris ? selectedEntris : _.cloneDeep(this.props.s.selectedEntries);
        const mainEntity = _.cloneDeep(entities[tabIndex]);

        const selectedRows: RowsType = [];
        const entityDescriptor = entityDescriptors[mainEntity.entityName]
        Object.keys(selectedEntries[mainEntity.entityName]).forEach((key) => selectedRows.push(mainEntity.rows[+key]));
        const fields = entityDescriptor.fields;
        let noEntityReferenced = true;
        let idsMap: any = {}
        let newAddedIds: any = {}
        Object.entries(fields).forEach(([field, fieldInfo]) => {
            let ed = entityDescriptors[getType(fieldInfo.type)];
            if (ed && ed.idFields.length === 1) {
                const idFields = ed.idFields;
                // if that key is an ed then it must refrence some ids
                let ids = new Set<any>([])
                // here we need to check if a field is a collection
                // get the ids
                selectedRows.forEach((row) => {
                    if (typeof row[field] === 'string' && row[field][0] === '[') {
                        let fieldCopy: string = _.cloneDeep(row[field])
                        let idsMulti = fieldCopy.slice(1, -1).split(', ')
                        if (!idsMap[getType(fieldInfo.type)]) {
                            idsMap[getType(fieldInfo.type)] = {}
                        }
                        idsMulti.forEach((id) => {
                            idsMap[getType(fieldInfo.type)][id] = row[idFields[0]]
                            ids.add(id)
                        });
                    }
                    else {
                        ids.add(row[field])
                        if (!idsMap[getType(fieldInfo.type)]) {
                            idsMap[getType(fieldInfo.type)] = {}
                        }
                        idsMap[getType(fieldInfo.type)][row[field]] = row[idFields[0]]
                    }
                })
                ids.delete(null);
                ids.delete('');
                ids.delete(undefined);
                // if there are refrenced ids
                if (ids.size > 0) {
                    noEntityReferenced = false;
                    let rows: RowsType = []
                    let exists = false;
                    Object.entries(entities).forEach(([entityIndex, entity]) => {
                        if (!entity.entityName.localeCompare(getType(fieldInfo.type))) {
                            exists = true; // entity already in tab, only need to add extra rows if any new
                            let currentIds: { [key: number]: { [key: string]: any; } } = {}
                            Object.values(entities[Number.parseInt(entityIndex)].rows).forEach((row) => currentIds[row.id] = row)
                            if (mainEntity.entityName.localeCompare(ed.name)) {
                                // not a refrence to itself (most cases)
                                // add all ids refrenced, if the id was loaded before keep the data, otherwise add an "empty line" containing the id
                                Object.keys(currentIds).map((key) =>
                                    rows.push(currentIds[Number(key)]))
                                ids.forEach(id => {
                                    if (!currentIds[id]) {
                                        // if we add at least one new id, we should mark the entity as "not loaded" and uncheck all checkboxes
                                        // as you can't have them checked if an entity is not fully loaded
                                        let row: any = {}
                                        if (!newAddedIds[entity.entityName]) {
                                            newAddedIds[entity.entityName] = {}
                                        }
                                        newAddedIds[entity.entityName][`${entity.entityName}(${id})`] = `${mainEntity.entityName}(${idsMap[ed.name][id]})`;

                                        row[idFields[0]] = id;
                                        rows.push(row)
                                        selectedEntries[entity.entityName] = {}
                                        delete loadedEnitities[Number.parseInt(entityIndex)];
                                    }
                                })
                                // if there were entries that were removed (they are not refrenced anymore) uncheck all
                                if (rows.length < Object.keys(currentIds).length) {
                                    selectedEntries[entity.entityName] = {}
                                }
                            } else {
                                // has refrence to itself
                                // if it has refrence to itself, you have to add the refrenced ids (those which are not yet added) and not overwrite
                                // in short, we should keep all previous ids even if they are not refrenced, as the refrenced comes from itself
                                // Ex: if we have 2 employees with ids 1 and 2 and they refrence 3 and 4, we should keep 1 and 2 and add 3 and 4
                                // if it wouldn't have been a refrence to itself, and 3 and 4 were refrenced by another entity we should only keep 3 and 4 as 1 and 2 were "lost"
                                rows = entities[Number.parseInt(entityIndex)].rows;
                                ids.forEach(id => {
                                    if (!currentIds[id]) {
                                        let row: any = {}

                                        row[idFields[0]] = id;
                                        rows.push(row);
                                        if (!newAddedIds[entity.entityName]) {
                                            newAddedIds[entity.entityName] = {}
                                        }
                                        newAddedIds[entity.entityName][`${entity.entityName}(${id})`] = `${mainEntity.entityName}(${idsMap[ed.name][id]})`;
                                        // again, if there's a at least one new id, make the entity unloaded and uncheck the entries
                                        delete loadedEnitities[Number.parseInt(entityIndex)];
                                        selectedEntries[entity.entityName] = {}
                                    }
                                })
                            }
                            // set the entity rows
                            entities[Number.parseInt(entityIndex)].rows = rows;
                            if (loadedEnitities[Number.parseInt(entityIndex)]) {
                                if (!selectedEntries[entities[Number.parseInt(entityIndex)].entityName]) {
                                    selectedEntries[entities[Number.parseInt(entityIndex)].entityName] = {}
                                }
                                for (let i = 0; i < rows.length; i++) { selectedEntries[entities[Number.parseInt(entityIndex)].entityName][i] = true }
                            }
                        }
                    })
                    if (!exists) {
                        ids.forEach((id, index) => {
                            rows.push({ id: id })
                            if (!newAddedIds[ed.name]) {
                                newAddedIds[ed.name] = {}
                            }
                            newAddedIds[ed.name][`${ed.name}(${id})`] = `${mainEntity.entityName}(${idsMap[ed.name][id]})`;
                        })
                        const nextEntityIndex = Object.keys(entities).length;
                        
                        const columns = [];
                        for (let fieldName in ed.fields) {
                            const field = ed.fields[fieldName];
                            if (field.isCustomField || field.clientOnly) {
                                continue;
                            }
                            columns.push(fieldName);
                        }
                        
                        entities[nextEntityIndex] = { entityName: ed.name, rows: rows, columns }
                        selectedEntries[entities[nextEntityIndex].entityName] = {}
                    }
                }
            }
        });
        if (!this.props.s.recursiveMode && noEntityReferenced) {
            alert(_msg("CsvExporter.noIdsAdded.label"));
        }
        this.props.r.setInReduxState({ entities, selectedEntries, loadedEnitities });
        if (this.props.s.printLogs) {
            if (_.isEmpty(newAddedIds)) return { entities, selectedEntries, loadedEnitities };
            console.groupCollapsed(mainEntity.entityName);
            Object.entries(newAddedIds).forEach(([entityName, idsMapping]: [entityName: string, idsMappings: any]) => {
                console.groupCollapsed(entityName);
                Object.entries(idsMapping).forEach(([id, mapping]) => {
                    console.log(`${id} is referenced by ${mapping}`);
                });
                console.groupEnd();
            })
            console.groupEnd();
        }
        return { entities, selectedEntries, loadedEnitities };
    }

    modifyEntity(entity: Entity | undefined, index: number, entitis: EntityType, loadedEnititis: { [key: number]: boolean }, selectedEntris: { [key: string]: EntryType }) {
        if (entity) {
            entitis[index] = entity;
            loadedEnititis[index] = true;
            if (!selectedEntris[entitis[index].entityName]) {
                selectedEntris[entitis[index].entityName] = {}
            }
            for (let i = 0; i < entity?.rows.length!; i++) { selectedEntris[entitis[index].entityName][i] = true }
            return 1;
        }
        return 0;
    }

    async addReferencedIdsRecursive() {
        let currentEntities = _.cloneDeep(this.props.s.entities);
        let currentLoadedEntities = _.cloneDeep(this.props.s.loadedEnitities);
        let currentSelectedEntries = _.cloneDeep(this.props.s.selectedEntries);
        let currentIteration = 0;
        let limit = 10;
        this.props.r.setInReduxState({ recursiveMode: true });
        while (currentIteration < limit) {
            let foundRecords = false;
            this.props.s.printLogs && console.log(`Iteration ${currentIteration}`);
            let index = 0;
            let entity = await this.loadEntities(index, _.cloneDeep(currentEntities[index]), currentLoadedEntities);
            const modifiedEntity = this.modifyEntity(entity, index, currentEntities, currentLoadedEntities, currentSelectedEntries)
            if (modifiedEntity) foundRecords = true;

            while (index < Object.keys(currentEntities).length) {
                let { entities, loadedEnitities, selectedEntries } = _.cloneDeep(this.addReferencedIds(index, currentEntities, currentLoadedEntities, currentSelectedEntries));
                index++;
                if (entities[index]) {
                    let entity = await this.loadEntities(index, _.cloneDeep(entities[index]), loadedEnitities);
                    const modifiedEntity = this.modifyEntity(entity, index, entities, loadedEnitities, selectedEntries)
                    if (modifiedEntity) foundRecords = true;
                }
                currentEntities = entities;
                currentLoadedEntities = loadedEnitities;
                currentSelectedEntries = selectedEntries;
            }
            this.props.s.printLogs && console.log(`New records found: ${foundRecords}`);
            if (!foundRecords) break;
            currentIteration++;
        }
    }

    async loadEntity() {
        this.props.r.selectOrDeselectAll(true, 0);

        const entity = this.props.entities[0];
        const loadQueryParams = this.getLoadQueryParams(entity.entityName);

        const { data } = (await apolloClientHolder.apolloClient.query({
            context: {
                [ApolloContext.ON_ERROR_HANDLER]: (e: CatchedGraphQLError) => {
                    return true;
                },
                showSpinner: true
            },
            query: loadQueryParams.loadQuery,
            variables: FindByFilterParams.create().startIndex(0).pageSize(-1).filter(this.props.filter).sorts(this.props.sorts)
        }));

        this.props.r.selectOrDeselectAll(true, data[this.props.loadQueryParams.loadOperationName].results.length);
        let rows = this.prepareRows(data[this.props.loadQueryParams.loadOperationName].results, entity.entityName)
        this.props.r.setEntityRows(rows)
    }

    getTabName(entity: { entityName: string; rows: { [key: string]: any; }[]; columns: string[]; }, index: number): string {
        if (!this.props.s.selectedEntries[this.props.s.entities[index].entityName]) return `Loading...`
        return `${Object.keys(this.props.s.selectedEntries[this.props.s.entities[index].entityName]).length}/${entity.rows.length}`;
    }

    protected getEntityForConversion(entity: { entityName: string; rows: RowsType; columns: string[]; }) {
        const selectedRows: RowsType = [];
        const ed = entityDescriptors[entity.entityName];
        Object.keys(this.props.s.selectedEntries[entity.entityName]).forEach((key) => selectedRows.push(_.cloneDeep(entity.rows[+key])));
        selectedRows.forEach((row) => Object.keys(row).forEach((key) => {
            if (key[0] === '_' || ed.fields[key].type[0] === "[") {
                delete row[key];
            }
        }))
        return selectedRows;
    }

    transformKey(entityName: string, key: string) {
        const ed = entityDescriptors[entityName];
        if (ed.fields[key].type === FieldType.string) return `${key} $string`;
        if (ed.fields[key].type === FieldType.date) return `${key} $datetimeS`;
        return key;
    }

    downloadCsv() {
        let rowsToExport: any = []
        Object.entries(this.props.s.entities).forEach(([entityIndex, entity]) => {
            if (this.props.s.loadedEnitities[+entityIndex]) {
                const selectedRows = this.getEntityForConversion(entity);
                const ed = entityDescriptors[entity.entityName];

                rowsToExport.push([entityDescriptors[entity.entityName].getPrefixForCsvExport()]);
                rowsToExport.push(Object.keys(entity.rows[0]).filter(key => key[0] !== '_' && ed.fields[key].type[0] !== "[").map(key => this.transformKey(entity.entityName, key)));
                selectedRows.forEach((row) => rowsToExport.push(Object.values(row)));
                rowsToExport.push([])
            }
        })

        Utils.exportToCsv("MultiCSV.csv", rowsToExport);
    }

    getEntities() {
        let entities: { [key: string]: any } = {}
        Object.values(this.props.s.entities).forEach((entity) => {
            let idsForEntity: number[] = [];
            const ed = entityDescriptors[entity.entityName];
            if (!this.props.s.recursiveMode && ed.idFields.length > 1) {
                alert(_msg("CsvExporter.composedPKQueryAlert.label", entity.entityName));
                return;
            }
            const idField = ed.idFields[0];
            Object.keys(this.props.s.selectedEntries[entity.entityName]).forEach((index: string) => {
                idsForEntity.push(entity.rows[Number(index)][idField]);
            })
            if (idsForEntity.length > 0) {
                entities[entity.entityName] = idsForEntity;
            }
        })

        return entities;
    }

    getCsvFromServer = async () => {
        const entities = this.getEntities()
        let csvString: any = (await apolloClient.query({
            query: JPA_GOODIES_SERVICE_GET_MULTI_CSV_SQL,
            variables: {
                arg0: entities
            }
        })).data.jpaGoodiesService_multiCsvSql
        return csvString;
    }

    exportCsvFromServer = async () => {
        let csvString = await this.getCsvFromServer();

        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', 'MultiCSV.csv');
        a.click()
    }

    getLoadQueryParams(entityName: string) {
        const ed = entityDescriptors[entityName];
        let fieldsToQuery = ""
        Object.entries(ed.fields).forEach(([fieldName, fieldOptions]) => {
            const type = getType(fieldOptions.type)
            if (!fieldOptions.clientOnly && !fieldOptions.isCustomField) {
                if (entityDescriptors[type]) {
                    const idFields = entityDescriptors[type].idFields;
                    if (idFields.length === 1) {
                        fieldsToQuery += `${fieldName} `
                        fieldsToQuery += `{ ${idFields[0]} } `
                    }
                    // if the PK is composed then we're not gonna query that
                } else {
                    fieldsToQuery += `${fieldName} `
                }
            }
        })
        // TODO CSR: de unde te-ai inspirat? Am aceleasi preocupari de DRY. Hai sa discutam, sa vedem daca nu putem sa apelam
        // vreo functie de pe undeva; si sa fie in acea functie chestiile astea
        // vad totusi ca mai jos e refolosita loadQueryParams.loadQuery
        const loadOperationName = `${_.lowerFirst(ed.name)}Service_findByFilter`;
        const loadParamType = "FindByFilterParamsInput";
        return {
            loadOperationName,
            loadQuery: gql(`query q($params: ${loadParamType}) { 
                ${loadOperationName}(params: $params) {
                    results { 
                        ${fieldsToQuery}
                     } totalCount
                }
            }`)
        };
    }

    async loadEntities(index?: number, entity?: Entity, loadedEnititis?: { [key: number]: boolean }) {
        let tabIndex = index ? index : this.props.s.currentTabIndex;
        let loadedEnitities = loadedEnititis ? loadedEnititis : this.props.s.loadedEnitities;

        if (loadedEnitities[tabIndex]) {
            return;
        }
        const entty = entity ? entity : this.props.s.entities[tabIndex];
        const loadQueryParams = this.getLoadQueryParams(entty.entityName);
        const ids = entty.rows.map((row) => row.id).toString();
        const entityName = entty.entityName;
        const idFields = entityDescriptors[entityName].idFields;
        if (!this.props.s.recursiveMode && idFields.length > 1) {
            alert(_msg("CsvExporter.composedPKQueryAlert.label", entityName));
        } else {
            const { data } = (await apolloClientHolder.apolloClient.query({
                query: loadQueryParams.loadQuery,
                variables: FindByFilterParams.create().filter(Filter.create("id", FilterOperators.forNumber.in, ids))
            }));
            const results: any = Object.values(data)[0];
            let rows = this.prepareRows(results['results'], entty.entityName);
            if (entity) {
                entty.rows = rows;
                return entty;
            }
            else
                this.props.r.setEntityRows(rows, tabIndex);
        }
        return entty;
    }

    protected _getSelectedRecordsNo() {
        let total = 0;
        Object.entries(this.props.s.selectedEntries).forEach(([key, value]) => {
            total += Object.keys(value).length;
        })
        return total;
    }

    protected _getSelectedEntriesCountForEntity(entityName: string) {
        return Object.keys(this.props.s.selectedEntries[entityName]).length;
    }

    doubleClickAction(index: number): void {
        this.props.r.toggleEntry(index);
    }

    protected renderCheckboxes(entity: { entityName: string; rows: { [key: string]: any; }[]; columns: string[]; }) {
        return <Column key={'checkbox'} columnKey={'checkbox'} allowCellsRecycling isResizable={false} isReorderable={false} width={40}
            header={<Cell>
                <Checkbox data-testid={multiCsvEditorTestids.checkboxItemGlobal} onChange={(_, data) => { this.props.r.selectOrDeselectAll(data.checked!, entity.rows.length) }}
                    checked={Object.keys(this.props.s.selectedEntries[this._getCurrentEntity().entityName]).length === entity.rows.length}
                    indeterminate={Object.keys(this.props.s.selectedEntries[this._getCurrentEntity().entityName]).length !== entity.rows.length &&
                        Object.keys(this.props.s.selectedEntries[this._getCurrentEntity().entityName]).length !== 0}
                    disabled={!this.props.s.loadedEnitities[this.props.s.currentTabIndex]}
                />
            </Cell>}
            cell={props => <Cell>
                <Checkbox data-testid={multiCsvEditorTestids.checkboxItem + '_' + props.rowIndex} onChange={(_, data) => { this.props.r.toggleEntry(props.rowIndex) }}
                    checked={this.props.s.selectedEntries[this._getCurrentEntity().entityName][props.rowIndex] !== undefined}
                    disabled={!this.props.s.loadedEnitities[this.props.s.currentTabIndex]}
                />
            </Cell>}
        />
    }

    renderAdditionalColumns(entity: { entityName: string; rows: { [key: string]: any; }[]; columns: string[]; }) {
        return this.renderCheckboxes(entity);
    }

    protected renderTabLabel(index: number, entity: { entityName: string; rows: { [key: string]: any; }[]; columns: string[]; }): JSX.Element {
        return <Label data-testid={multiCsvEditorTestids.tabLabel} circular color={index !== 0 ? this.props.s.loadedEnitities[index] ? "green" : "red" : undefined}>{this.getTabName(entity, index)}</Label>
    }

    protected pushAddButton(panes: any): void {
        // empty as we don't want to push the add button
    }

    protected renderHeaderButtons(tabLabel: string): JSX.Element {
        return <>
            <TestsAreDemoCheat objectToPublish={this} />
            <Popup
                trigger={<Icon name="info circle" color="blue" size="large" />} content={
                    <div className="flex flex-grow">
                        <div className="flex-grow"><Label circular color="red">0/5</Label>
                            {_msg("CsvExporter.notLoaded.label")}</div>
                        <div className="flex-grow"><Label circular color="green">0/5</Label>
                            {_msg("CsvExporter.loaded.label")}</div>
                        <div className="flex-grow"><Label circular color="green">5/5</Label>
                            {_msg("CsvExporter.allSelected.label")}</div>
                    </div>
                }
                size={"large"}
            />
            <Button data-testid={csvExporterTestids.load} compact disabled={this.props.s.loadedEnitities[this.props.s.currentTabIndex]} onClick={() => { this.loadEntities() }}>
                {/* TODO CSR: 1/ Daca punem propr string, e inutil {`...`}; putem pune direct. 2/ Trebuie bagate in mesaje, nu hardcodate aici! De survolat peste tot.  */}
                {_msg("CsvExporter.load.label")}
            </Button>
            <Button data-testid={csvExporterTestids.loadReferencedIds} compact onClick={() => { this.addReferencedIds() }}
                disabled={!this.props.s.loadedEnitities[this.props.s.currentTabIndex] || this._getSelectedEntriesCountForEntity(this._getCurrentEntity().entityName) === 0}
            >
                {_msg("CsvExporter.addRefrencedIds.label")}
            </Button>
            <Button data-testid={csvExporterTestids.loadReferencedIdsRecursive} compact onClick={() => { this.addReferencedIdsRecursive() }}
                disabled={!this.props.s.loadedEnitities[this.props.s.currentTabIndex] || this._getSelectedEntriesCountForEntity(this._getCurrentEntity().entityName) === 0}
            >
                {_msg("CsvExporter.addRefrencedIdsRecursive.label")}
            </Button>
            <Button data-testid={csvExporterTestids.export} compact onClick={() => this.downloadCsv()}>
                {_msg("CsvExporter.exportSelected.label", this._getSelectedRecordsNo())}
            </Button>
            <Button data-testid={csvExporterTestids.exportFromServer} compact onClick={() => this.exportCsvFromServer()}>
                {_msg("CsvExporter.exportSelectedFromServer.label", this._getSelectedRecordsNo())}
            </Button>
        </>
    }
}

export const CsvExporterRRC = ReduxReusableComponents.connectRRC(CsvExporterState, CsvExporterReducers, CsvExporter);"../../reduxReusableComponents/ReduxReusableComponents""../multiCsvEditor/MultiCsvEditor""../../entity_crud/entityCrudConstants""../../apolloClient""../../entity_crud/FindByFilterParams""../../utils/Utils""../../entity_crud/EntityDescriptor""../../entity_crud/FieldType"