import React from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

export const Draggable = (props: any) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: props.type ? props.type : 'draggable',
    item: props.item,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
    options: {
      dropEffect: "copy"
    }
  }));
  const style = isDragging ? props.onDraggingStyle : undefined;

  return <div ref={drag} style={{ ...style, opacity: isDragging ? 0.5 : 1, cursor: 'grab' }} {...props}>
    {props.children}
  </div >

}

// KI: https://github.com/react-dnd/react-dnd/issues/3530
export const Droppable = (props: any) => {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: props.accept ? props.accept : 'draggable',
      item: props.item,
      drop: (item: any, monitor: any) => props.drop && props.drop(item, props.item),
      canDrop: (item: any, monitor: any) => {
        return props.canDrop ? props.canDrop(item, props.item) : true;
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop()
      })
    })
  );

  const style = isOver ? props.onOverStyle : undefined;
  return <div ref={drop} style={{ ...style, transition: '0.1s' }} {...props}>
    {props.children}
  </div >;
}

export class DragAndDropArea extends React.Component<{}> {
  render() {
    return <DndProvider backend={HTML5Backend} >{this.props.children}</DndProvider>
  }
}