import { SelectExt, SelectExtOption, SelectExtProps } from "@crispico/foundation-react/components/selectExt/SelectExt";
import { getDropdownItemLabel } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DropdownFieldRenderer";
import React from "react";
import { FieldInterval } from "../CrudSettings";
import { FieldEditorProps } from "../fieldRenderersEditors";

export class DropdownFieldEditor extends React.Component<FieldEditorProps & Partial<SelectExtProps>> {

    protected getDropdownLabel(fi: FieldInterval): any{
        return getDropdownItemLabel(this.props.fieldDescriptor, fi);
    }

    render() {
        const { props } = this;
        const { fieldDescriptor } = props;
        const options: SelectExtOption[] = [];
        const defaultValue: SelectExtOption[] = [];
        const value = fieldDescriptor.getFieldValue(props.formikProps.values);

        fieldDescriptor.fieldDescriptorSettings?.fieldIntervals?.forEach(fi => {
            const option = { value: fi.from, label: this.getDropdownLabel(fi) };
            options.push(option);
            // Cast "fi.from" to string because we can have settings when values come as numbers
            if (String(value) === String(fi.from)) {
                defaultValue.push(option);
            }
        });

        return <SelectExt isClearable={true} closeMenuOnSelect={true} options={options} isMulti={false} defaultValue={defaultValue}
            onSelectedOptionsChange={(selected: SelectExtOption[]) => props.formikProps.setFieldValue(fieldDescriptor.getFieldName(), selected[0]?.value || null)} {...this.props} />
    }
}

export default DropdownFieldEditor;"../../components/selectExt/SelectExt""./DropdownFieldRenderer"