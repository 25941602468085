import React from "react";
import { MarkdownExt } from "../MarkdownExt.js";
import { SplitPaneExt } from "../../copied/ReactSplitPaneExt/ReactSplitPaneExt.js";
import { FeaturesList } from "../feature-book/FeatureList.js";
import { LOCAL_STORAGE_CURRENT_DOC } from "../TestsAreDemoMaster.js";

// @ts-ignore
import d from "../../common-lib-and-node/common.mjs";
import { DocDescriptor, CommonLibAndNodeDefaultExport } from "../../common-lib-and-node/common-types";
const { generateMarkdownDoc } = (d as CommonLibAndNodeDefaultExport);

export class DocFromTests extends React.Component {

    state = {} as { 
        descriptors?: string[]
        markdown?: string 
    };

    async componentDidMount() {
        const response = await fetch("docDescriptors/index.json");
        if (!response.ok) {
            return;
        }
        const indexJson = await response.text();
        this.setState({descriptors: JSON.parse(indexJson)});       
    }

    async loadDescriptor(file: string) {
        try {
            const response = await fetch("docDescriptors/" + file);
            if (!response.ok) {
                throw new Error();
            }
            const json = await response.text()
            const docDescriptor: DocDescriptor = JSON.parse(json);
            this.setState({ markdown: await generateMarkdownDoc(docDescriptor) });
        } catch {
            this.setState({ markdown: undefined });
        }
    }

    render() {
        return (
            <SplitPaneExt size={"25%"} split="vertical">
                <FeaturesList items={this.state.descriptors} localStorageKey={LOCAL_STORAGE_CURRENT_DOC}
                    onSelect={(descriptor: string) => this.loadDescriptor(descriptor)} />
                <MarkdownExt>{this.state.markdown}</MarkdownExt>;
            </SplitPaneExt>)
    }
}