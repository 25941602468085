import { EntityEditorFormSimple, Utils } from '@crispico/foundation-react';
import { entityDescriptors } from '@crispico/foundation-react/entity_crud/entityCrudConstants';
import { EntityDescriptor, FieldDescriptor, addEntityDescriptor } from '@crispico/foundation-react/entity_crud/EntityDescriptor';
import { FieldEditorProps } from '@crispico/foundation-react/entity_crud/fieldRenderersEditors';
import React, { ReactElement, RefObject } from 'react';
import { Button, Dropdown, DropdownProps, Segment } from 'semantic-ui-react';
import { PIE_COUNT_BY_CRITERIA, DEMO_WIDGET, DEMO_WIDGET_2, LIST_RECORDS_WIDGET, CALCULATE_RECORDS_WIDGET, FIELD_WIDGET, FREE_TEXT_WIDGET, MULTIPLE_FIELDS_WIDGET } from './DashboardContants';
import { testState0, configTestState0 } from './dashboardTab/testStates';
import { Filter } from '@crispico/foundation-react/components/CustomQuery/Filter';
import { Optional } from '@crispico/foundation-react/CompMeta';
import gql from 'graphql-tag';
import { apolloClientHolder } from '@crispico/foundation-react/apolloClient';
import { FieldWidget } from './dashboardTab/entity_widgets/FieldWidget/FieldWidget';
import { FieldType } from '@crispico/foundation-react/entity_crud/FieldType';
import { FreeTextWidget } from './dashboardTab/entity_widgets/FreeTextWidget';
import { WidgetProps } from './dashboardTab/WidgetWrapper';
import { EntityFilterMode } from '@crispico/foundation-react/entity_crud/fieldRenderersEditors/EntityFilter';
import { MultipleFieldsWidget } from './dashboardTab/entity_widgets/MultipleFieldsWidget/MultipleFieldsWidget';
import { AssociationFieldEditor } from '@crispico/foundation-react/entity_crud/AssociationFieldEditor';
import { DashboardTab } from './dashboardTab/DashboardTab';
import { RRCProps, Reducers, ReduxReusableComponents, State } from '@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents';
import { CalculateForRecordsRRC } from '@crispico/foundation-react/components/CalculateForRecords/CalculateForRecords';
import { ListOfRecordsRRC } from '@crispico/foundation-react/components/ListOfRecords/ListOfRecords';
import { PieCountInTerritoriesFastTabRRC } from './pieCountByCriteria/PieCountByCriteria';

export enum DashboardWidgetType {
    FILTER, ENTITY, ANY
}

export class DashboardWidgetFactories {
    static INSTANCE = new DashboardWidgetFactories();

    widgets: { [widgetType: string]: DashboardWidgetFactory } = {};
}

export abstract class DashboardWidgetFactory {

    public type = DashboardWidgetType.FILTER;
    public componentClass: any = React.Component;
    public allowMultiple = true;

    abstract getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor;

    renderWidget(id: string, widgetProps: WidgetProps): ReactElement {
        // widgetIdIfReferenced - for CalculateForRecords
        return <this.componentClass {...widgetProps} widgetIdIfReferenced={widgetProps.id} id={id} />;
    }

    renderTitle(widgetConfig: any) {
        return widgetConfig.title;
    }
    renderEditor(id: string, widgetConfig: any, dashboardTabRef: RefObject<DashboardTab>, dashboardEntity: any): JSX.Element {
        return this.renderEditorInternal(id, widgetConfig, dashboardTabRef, dashboardEntity);
    };
    protected renderEditorInternal(id: string, widgetConfig: any, dashboardTabRef: RefObject<DashboardTab>, dashboardEntity: any, entityDescriptor?: EntityDescriptor) {
        return <EntityEditorFormSimple
            entity={{ ...widgetConfig, ...{ uid: id } }}
            entityDescriptor={entityDescriptor ? entityDescriptor : this.getEntityDescriptor(widgetConfig, dashboardEntity)}
            onSubmitHandler={values => {
                dashboardTabRef.current?.props.r.setInReduxState({ values });
                dashboardTabRef.current?.props.r.editItem(id, dashboardEntity);
            }}
            onCancelHandler={() => dashboardTabRef.current?.props.r.setInReduxState({ editorOpen: undefined })} />
    }
    abstract getWizardInfo(): { title: string, description: string, component?: any, testState?: any, image?: string, storybook?: boolean };
    isWidgetWrapperVisible(widgetConfig: any) {
        return true;
    }
    getAdditionalFieldsToRequest(widgetConfig: any): string[] {
        return [];
    }
}

export function DashboardWidgetEntityDescriptor(name: string) {
    return new EntityDescriptor({ name: name, hasAttachedDashboards: false }, false)
        .addFieldDescriptor({ name: "uid", type: FieldType.string, optional: true, enabled: false })
        .addFieldDescriptor({ name: "description", type: FieldType.text, optional: true })
        .addFieldDescriptor({ name: "headerFontSize", type: FieldType.number, optional: true })
        .addFieldDescriptor({ name: "headerBackgroundColor", type: FieldType.color, optional: true })
        .addFieldDescriptor({ name: "headerIcon", type: FieldType.string, optional: true })
        .addFieldDescriptor({ name: "backgroundColor", type: FieldType.color, optional: true })
}

export function DashboardWidgetWithFilterEntityDescriptor(name: string) {
    return DashboardWidgetEntityDescriptor(name)
        .addFieldDescriptor({ name: "entityType", type: FieldType.entityName })
        .addFieldDescriptor({ name: "filter", type: FieldType.filter, mode: EntityFilterMode.OBJECT, fieldForEntityName: "entityType", optional: true })
        .addFieldDescriptor({ name: "applyDataExplorerFilterTo", type: FieldType.entityFields, fieldForEntityName: "entityType", allowMultiple: false, optional: true })
}

export class DemoWidgetState extends State {
        counter = 1;
}

export class DemoWidgetReducers<S extends DemoWidgetState = DemoWidgetState> extends Reducers<S> {
        multiplyAndIncrement(multiplyExisting: number, add: number) {
            this.s.counter = this.s.counter * multiplyExisting + add;
        }
}

type DemoWidgetProps = RRCProps<DemoWidgetState, DemoWidgetReducers> & { rootFilter: Filter, widgetConfig: { someText: string, someColor: string } }

export class DemoWidget<T extends DemoWidgetProps = DemoWidgetProps> extends React.Component<T> {

    render() {
    return (<><Segment>
        I'm a widget. Something from widgetConfig = <b>{this.props.widgetConfig.someText}</b>; something from state = <b>{this.props.s.counter}</b>&nbsp;
        <Button color={this.props.widgetConfig.someColor as any} onClick={() => this.props.r.multiplyAndIncrement(2, 1)}>Modify the state</Button>
    </Segment>
    </>);
    }
}

export const DemoWidgetRRC = ReduxReusableComponents.connectRRC(DemoWidgetState, DemoWidgetReducers, DemoWidget);

DashboardWidgetFactories.INSTANCE.widgets[DEMO_WIDGET] = new class extends DashboardWidgetFactory {
    componentClass = DemoWidgetRRC;
    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetEntityDescriptor('DemoWidget');
    }
    getWizardInfo() {
        return { title: 'DemoWidget', description: 'This is a demonstration widget.', component: DemoWidgetRRC, testState: { id: "DemoWidgetRRC", ...testState0.demoWidget1, ...configTestState0.widgetWrapperConfigs.demoWidget2 }, storybook: true }
    }
}();


type DemoWidget2Props = RRCProps<DemoWidgetState, DemoWidgetReducers> & { filter: Filter, rootFilter: Filter, entityType: string, rootFilterCount?: number, widgetConfig: { someText: string, someColor: string } }

export class DemoWidget2<T extends DemoWidget2Props = DemoWidget2Props> extends React.Component<T> {

    render() {
    return (<><Segment>
        I'm a widget with access to root filter. Something from widgetConfig = <b>{this.props.widgetConfig.someText}</b>; something from state = <b>{this.props.s.counter}</b>&nbsp;
        <Button color={this.props.widgetConfig.someColor as any} onClick={() => this.props.r.multiplyAndIncrement(2, 1)}>Modify the state</Button>
    </Segment>
        <div>
            <label>
                <b>Root filter:</b> {JSON.stringify(this.props.rootFilter)}
            </label><br />
            <label>
                <b>My filter:</b> {JSON.stringify((this.props as any).widgetConfig.filter)}
            </label><br />
            <label>
                <b>Entity type:</b> {this.props.entityType}
            </label><br />
            <label>
                <b>Root filter count:</b> {this.props.rootFilterCount}
            </label>
        </div>
        <Utils.Observer value={this.props.rootFilter} didUpdate={() => console.log(this.props.rootFilter)} />
    </>);
    }
}

export const DemoWidget2RRC = ReduxReusableComponents.connectRRC(DemoWidgetState, DemoWidgetReducers, DemoWidget2);

DashboardWidgetFactories.INSTANCE.widgets[DEMO_WIDGET_2] = new class extends DashboardWidgetFactory {
    componentClass = DemoWidget2RRC;
    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetEntityDescriptor('DemoWidget2')
            .addFieldDescriptor({ name: "filter", type: FieldType.filter, entityDescriptor: entityDescriptors["Employee"], optional: true });
    }
    getWizardInfo() {
        return { title: _msg("Dashboard.widget.demoWidget.title"), description: 'TODO', component: DemoWidget2RRC, testState: { id: "DemoWidget2RRC", ...testState0.demoWidget2, ...configTestState0.widgetWrapperConfigs.demoWidget2 }, storybook: true }
    }
}();

class CalculationLogicFieldEditor extends React.Component<FieldEditorProps> {
    state = {
        calculationLogics: undefined as Optional<Array<String>>
    }

    constructor(props: FieldEditorProps) {
        super(props);
        this.getCalculationLogics();
    }

    async getCalculationLogics() {
        if (this.state.calculationLogics) {
            return;
        }
        const query = gql(`query q { dashboardService_calculationLogics }`);
        const result = (await apolloClientHolder.apolloClient.query({ query })).data.dashboardService_calculationLogics;
        this.setState({ calculationLogics: result });
    }

    handleChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, data.value);

    render = () => {
        const value = this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values);
        let options: any[] = [];
        this.state.calculationLogics?.sort().forEach((value: String) => {
            options.push({ key: value, text: value, value });
        });

        return <Dropdown data-cy={"calculationLogicDropdown"} fluid selection search={true} options={options} onChange={this.handleChange} value={value} />
    }
}

const calculationLogicFieldDescriptor = new class extends FieldDescriptor {
    constructor() {
        super();
        this.name = "calculationLogic";
        this.type = "custom";
    }

    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
        return React.createElement(CalculationLogicFieldEditor as any, props);
    }
}()

DashboardWidgetFactories.INSTANCE.widgets[CALCULATE_RECORDS_WIDGET] = new class extends DashboardWidgetFactory {
    componentClass = CalculateForRecordsRRC;
    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetWithFilterEntityDescriptor("CalculateForRecords")
            .addFieldDescriptor({ name: "title", type: FieldType.string })
            .addFieldDescriptor(calculationLogicFieldDescriptor)
            .addFieldDescriptor({ name: "parameters", type: FieldType.string, json: true, optional: true })
            .addFieldDescriptor({ name: "saveIntervalExpression", type: FieldType.cron, optional: true })
            .addFieldDescriptor({ name: "rollingAverageExpression", type: FieldType.string, optional: true })
            .addFieldDescriptor({ name: "showChartInWidget", type: FieldType.boolean, optional: true })
            .addFieldDescriptor({ name: "fontSize", type: FieldType.string, defaultValue: "small", optional: true })
            .addFieldDescriptor({ name: "valueColor", type: FieldType.color, optional: true, defaultValue: "black" })
            .addFieldDescriptor({ name: "intervals", type: FieldType.string, json: true, optional: true })
            .addFieldDescriptor({ name: "scaleFactors", type: FieldType.string, optional: true })
            .addFieldDescriptor({ name: "averageColor", type: FieldType.color, optional: true, defaultValue: "black" })
            .addFieldDescriptor({ name: "showAverageAsMainInfo", type: FieldType.boolean, optional: true })
            .addFieldDescriptor({ name: "upwardTrendIndicatorIsRed", type: FieldType.boolean, optional: true })
            .addFieldDescriptor({ name: "historyDaysToKeep", type: FieldType.number, optional: true })
            .addFieldDescriptor({ name: "groupBySuborganizations", type: FieldType.boolean, optional: true })
            .addFieldDescriptor({ name: "groupByFields", type: FieldType.entityFields, fieldForEntityName: "entityType", showOnlyComposedFields: true, optional: true })
    }

    hasRenderWidget2 = () => true;
    renderEditor(id: string, widgetConfig: any, dashboardTabRef: RefObject<DashboardTab>, dashboardEntity: any) {
        return this.renderEditorInternal(id, widgetConfig, dashboardTabRef, dashboardEntity, this.getEntityDescriptor({ ...widgetConfig, idIfReferenced: id }, dashboardEntity));
    }
    getWizardInfo() {
        return { title: _msg("CalculateForRecordsWidget"), description: 'TODO', component: CalculateForRecordsRRC, testState: { id: "CalculateForRecordsRRC", ...testState0.calculateWidget, ...configTestState0.widgetWrapperConfigs.calculateWidget } }
    }
}();

addEntityDescriptor(new EntityDescriptor({ name: "EmployeeForDemo" }))
    .addFieldDescriptor({ name: "firstName", type: FieldType.string })
    .addFieldDescriptor({ name: "lastName", type: FieldType.string })
    .addFieldDescriptor({ name: "birthDate", type: FieldType.date })
    .addFieldDescriptor({ name: "stillEmployed", type: FieldType.boolean })
    .addFieldDescriptor({ name: "rating", type: FieldType.double })
    .addFieldDescriptor({ name: "id", type: FieldType.number })
    .addFieldDescriptor({ name: "workStatus", type: FieldType.enum })
    .addFieldDescriptor({ name: "identifier", type: FieldType.string })
    .addFieldDescriptor({ name: "employmentYear", type: FieldType.date })
    .addFieldDescriptor({ name: "salary", type: FieldType.double })
    .addFieldDescriptor({ name: "description", type: FieldType.string })

DashboardWidgetFactories.INSTANCE.widgets[LIST_RECORDS_WIDGET] = new class extends DashboardWidgetFactory {
    componentClass = ListOfRecordsRRC;
    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetWithFilterEntityDescriptor("ListOfRecords")
            .addFieldDescriptor({ name: "title", type: FieldType.string })
            .addFieldDescriptor({ name: "fields", type: FieldType.entityFields, fieldForEntityName: "entityType", optional: true })
            .addFieldDescriptor({ name: "sorts", type: FieldType.sort, fieldForEntityName: "entityType", optional: true })
            .addFieldDescriptor({ name: "nrOfRecords", type: FieldType.defaultScalar })
            .addFieldDescriptor({ name: "displayAsTable", type: FieldType.boolean, optional: true })
    }

    getWizardInfo() {
        return { title: _msg("ListOfRecordsWidget"), description: 'TODO', component: ListOfRecordsRRC, testState: { id: "ListOfRecordsRRC", ...testState0.listWidget, ...configTestState0.widgetWrapperConfigs.listWidget } }
    }

}();

DashboardWidgetFactories.INSTANCE.widgets[PIE_COUNT_BY_CRITERIA] = new class extends DashboardWidgetFactory {
    componentClass = PieCountInTerritoriesFastTabRRC;
    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetWithFilterEntityDescriptor("PieCountByCriteria")
            .addFieldDescriptor({ name: "title", type: FieldType.string, optional: true })
            .addFieldDescriptor({ name: "currentDate", type: FieldType.string, optional: true })
            .addFieldDescriptor({ name: "field", type: FieldType.string, json: true })
            .addFieldDescriptor({ name: "pieSliceType", type: FieldType.entityName, optional: true })
            .addFieldDescriptor({ name: "pieSlices", type: 'PieSlices', optional: true }, new class extends FieldDescriptor {
                protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
                    const entityName = props.formikProps.values["pieSliceType"]
                    const newProps = {
                        ...props,
                        innerEntityDescriptor: entityName ? entityDescriptors[entityName] : undefined,
                        isMulti: true
                    }
                    return React.createElement(AssociationFieldEditor as any, newProps as FieldEditorProps);
                }
            })
            .addFieldDescriptor({ name: "excludeOthers", type: FieldType.boolean, optional: true })
            .addFieldDescriptor({ name: "detailedTooltip", type: FieldType.boolean, optional: true })
            .addFieldDescriptor({ name: "fontSizeForTotal", type: FieldType.number, optional: true })
            .addFieldDescriptor({ name: "colors", type: FieldType.pieCountColorPalette, optional: true })
            .addFieldDescriptor({ name: "showLegend", type: FieldType.boolean, optional: true })
            .addFieldDescriptor({ name: "legendDoesNotShowValuesUnder", type: FieldType.number, defaultValue: 0, optional: true });
    }

    getWizardInfo() {
        return { title: _msg("Chart.pieCountByCriteria.title"), description: _msg("Chart.pieCountByCriteria.content"), component: PieCountInTerritoriesFastTabRRC, testState: { id: "PieCountInTerritoriesFastTabRRC", ...testState0.pieCountByCriteria, ...configTestState0.widgetWrapperConfigs.pieCountByCriteria } }
    };
}();

DashboardWidgetFactories.INSTANCE.widgets[FIELD_WIDGET] = new class extends DashboardWidgetFactory {
    type = DashboardWidgetType.ENTITY;
    componentClass = FieldWidget;
    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetEntityDescriptor("FieldWidget")
            .addFieldDescriptor({ name: "headerFontSize", defaultValue: 10 }) // default 10 for this widget, the field is in DashboardWidgetEntityDescriptor
            .addFieldDescriptor({ name: "title", type: FieldType.string, optional: true })
            .addFieldDescriptor({ name: "entityName", type: FieldType.entityName, defaultValue: dashboardEntity.entityName, enabled: false })
            .addFieldDescriptor({ name: "field", type: FieldType.entityFields, fieldForEntityName: "entityName", allowMultiple: false })
            .addFieldDescriptor({ name: "fontSize", type: FieldType.string, defaultValue: "small", optional: true })
    }
    renderTitle(widgetConfig: any) {
        const ed = entityDescriptors[widgetConfig.entityName];
        const fdc = ed.getFieldDescriptorChain(widgetConfig.field);
        return ed.getComposedFieldLabel(fdc);
    }
    getWizardInfo() {
        return { title: 'Field widget', description: 'Displays the value of a field and the date of the measurement.', component: FieldWidget, testState: { entity: { salary: 999.5, fieldsLastUpdate: { salary: "2021-07-07T14:15:41+02:00[Europe/Paris]" } }, widgetConfig: { entityName: 'EmployeeForDemo', field: 'salary' } } }
    };
    getAdditionalFieldsToRequest(widgetConfig: any) {
        return [widgetConfig.field];
    }
}

DashboardWidgetFactories.INSTANCE.widgets[FREE_TEXT_WIDGET] = new class extends DashboardWidgetFactory {
    type = DashboardWidgetType.ANY;
    componentClass = FreeTextWidget;

    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetEntityDescriptor("FreeTextWidget")
            .addFieldDescriptor({ name: "text", type: FieldType.string, optional: true })
            .addFieldDescriptor({ name: "showWidgetWrapper", type: FieldType.boolean, optional: true })
            .addFieldDescriptor({ name: "fontSize", type: FieldType.string, defaultValue: "small", optional: true })
    }
    isWidgetWrapperVisible(widgetConfig: any) {
        return widgetConfig.showWidgetWrapper;
    }
    getWizardInfo() {
        return { title: 'Free text widget', description: 'Displays preconfigured text.', component: FreeTextWidget, testState: { widgetConfig: { text: "An example preconfigured text.", fontSize: "24" } } };
    };
}

DashboardWidgetFactories.INSTANCE.widgets[MULTIPLE_FIELDS_WIDGET] = new class extends DashboardWidgetFactory {
    type = DashboardWidgetType.ENTITY;
    componentClass = MultipleFieldsWidget;
    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetEntityDescriptor("MultipleFieldsWidget")
            .addFieldDescriptor({ name: "headerFontSize", defaultValue: 10 })
            .addFieldDescriptor({ name: "title", type: FieldType.string, optional: true })
            .addFieldDescriptor({ name: "entityName", type: FieldType.entityName, defaultValue: dashboardEntity.entityName, enabled: false })
            .addFieldDescriptor({ name: "fields", type: FieldType.entityFields, fieldForEntityName: "entityName", allowMultiple: true })
            .addFieldDescriptor({ name: "fontSize", type: FieldType.string })
            .addFieldDescriptor({ name: "nbColumns", type: FieldType.string })
            .addFieldDescriptor({ name: "widthBetweenColumns", type: FieldType.string, optional: true, defaultValue: "5" })
            .addFieldDescriptor({ name: "fieldLabelFontSize", type: FieldType.string })
    }
    renderTitle(widgetConfig: any) {
        return widgetConfig.title
    }
    getWizardInfo() {
        return { title: 'Multiple fields widget', description: 'Displays the value of multiple fields and the date of the measurement.', component: MultipleFieldsWidget, testState: { entity: { firstName: "John", lastName: "Johnathan", birthDate: "1998-07-07T14:15:41+02:00", salary: 990.91, fieldsLastUpdate: { firstName: "2021-07-07T14:15:41+02:00", lastName: "2021-07-07T14:15:41+02:00", birthDate: "2021-07-07T14:15:41+02:00", salary: "2021-07-07T14:15:41+02:00" } }, widgetConfig: { entityName: 'EmployeeForDemo', fields: 'firstName,lastName,birthDate,salary', nbColumns: 2, widthBetweenColumns: 8, fontSize: 12, fieldLabelFontSize: 14 } } }
    };
}
"../..""../../entity_crud/entityCrudConstants""../../entity_crud/EntityDescriptor""../../entity_crud/fieldRenderersEditors""../../components/CustomQuery/Filter""../../CompMeta""../../apolloClient""../../entity_crud/FieldType""../../entity_crud/fieldRenderersEditors/EntityFilter""../../entity_crud/AssociationFieldEditor""../../reduxReusableComponents/ReduxReusableComponents""../../components/CalculateForRecords/CalculateForRecords""../../components/ListOfRecords/ListOfRecords"