import React from 'react';
import { Filter } from '@crispico/foundation-react/components/CustomQuery/Filter';
import { FilterOperators } from '@crispico/foundation-gwt-js';
import _ from 'lodash';
import { EntityDescriptor, Optional, Organization, SliceEntityTablePage, TestUtils, Utils } from '@crispico/foundation-react';
import { entityDescriptors, getOrganizationFilter } from '@crispico/foundation-react/entity_crud/entityCrudConstants';
import { Container, Dimmer, Header, Loader, Segment } from 'semantic-ui-react';
import Interweave from 'interweave';
import { Reducers, RRCProps, State } from '@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents';

const EMPTY_FILTER: Filter = Filter.createComposed(FilterOperators.forComposedFilter.and, []);
export enum WidgetStatus {
    NONE, ERROR_INCOMPATIBLE_TYPES, IN_PROGRESS, DONE
}

export interface AbstractWidgetWithFilterConfig {
    filter: Filter | undefined;
    entityType: string;
    applyDataExplorerFilterTo: string | undefined;
}

export class AbstractWidgetWithFilterState extends State {
        status = WidgetStatus.NONE as WidgetStatus;
}

export class AbstractWidgetWithFilterReducers<S extends AbstractWidgetWithFilterState = AbstractWidgetWithFilterState> extends Reducers<S> { }

export const AbstractWidgetWithFilterOnlyForExtension =  AbstractWidgetWithFilterReducers;

export type AbstractWidgetWithFilterProps = RRCProps<AbstractWidgetWithFilterState, AbstractWidgetWithFilterReducers> & { widgetConfig: AbstractWidgetWithFilterConfig, expandedOrganization?: Organization, dataExplorerFilter?: Filter, dataExplorerEntityDescriptor?: EntityDescriptor };

export abstract class AbstractWidgetWithFilter<T extends AbstractWidgetWithFilterProps = AbstractWidgetWithFilterProps> extends React.Component<T> {

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps: any) {
        this.componentDidUpdateInternal(prevProps);
    }

    protected componentDidUpdateInternal(prevProps: T) {
        if (prevProps && (!_.isEqual(prevProps.widgetConfig, this.props.widgetConfig)
            || !_.isEqual(prevProps.dataExplorerFilter, this.props.dataExplorerFilter)
            || !_.isEqual(prevProps.expandedOrganization, this.props.expandedOrganization))) {
           this.refresh();
        }
    }
   
    protected async refreshInternal(filter: Filter) {
        // to be overriden by extending classes
    }

    protected async refresh() {
        if (!this.shouldRefresh()) {
            return;
        }
        this.props.r.setInReduxState({ status: WidgetStatus.IN_PROGRESS });
        await this.refreshInternal(this.createFilter());
        this.props.r.setInReduxState({ status: WidgetStatus.DONE });
    }

    protected shouldRefresh() {
        if (!this.props.dataExplorerFilter && this.props.dataExplorerEntityDescriptor) {
            return false;
        }
        const ed = entityDescriptors[this.props.widgetConfig.entityType];
        if (this.props.dataExplorerEntityDescriptor) {
            if (!this.areTypesCompatible(ed, this.props.dataExplorerEntityDescriptor!)) {
                this.props.r.setInReduxState({ status: WidgetStatus.ERROR_INCOMPATIBLE_TYPES });
                return false;
            }
        }
        if (TestUtils.storybookMode || (this.props as any).zeroTrainingMode ||
            this.props.s.status !== WidgetStatus.NONE && this.props.s.status !== WidgetStatus.DONE) {
            return false;
        }
        return true;
    }

    protected areTypesCompatible(local: EntityDescriptor, dataExplorer: EntityDescriptor) {
        if (local.name === dataExplorer.name) {
            return true;
        }
        const props = this.props;
        if (Utils.isNullOrEmpty(props.widgetConfig.applyDataExplorerFilterTo)) {
            return false;
        }
        const chain = local.getFieldDescriptorChain(props.widgetConfig.applyDataExplorerFilterTo!);
        if (chain[chain.length - 1].getType() !== dataExplorer.name) {
            return false;
        }
        return true;
    }

    protected createFilter() {
        const props = this.props;
        const config = props.widgetConfig;
        const ed = entityDescriptors[config.entityType];
        let dataExplorerFilter = props.dataExplorerFilter;
        if (dataExplorerFilter && props.dataExplorerEntityDescriptor && props.dataExplorerEntityDescriptor.name !== ed.name) {
            dataExplorerFilter = applyDataExplorerFilter(_.cloneDeep(dataExplorerFilter!), config.applyDataExplorerFilterTo!);
        }
        const organizationFilter = ed ? Filter.enableAllFilters(getOrganizationFilter(ed, props.expandedOrganization)) : undefined;
        const filter = Filter.eliminateDisabledFilters(Filter.createComposedForClient(FilterOperators.forComposedFilter.and,
            [dataExplorerFilter, organizationFilter, Filter.enableAllFilters(config.filter)].filter(f => f !== undefined) as Filter[]));
        return filter ? filter : EMPTY_FILTER;
    }

    protected renderMain(): JSX.Element | null {
        return <></>
    }

    render() {
        if (this.props.s.status === WidgetStatus.NONE) {
            return <></>;
        } else if (this.props.s.status === WidgetStatus.ERROR_INCOMPATIBLE_TYPES) {
            const ed = entityDescriptors[this.props.widgetConfig.entityType];
            const type = this.props.dataExplorerEntityDescriptor;
            if (!type) {
                return <></>;
            }
            return <Container>
                <Interweave content={_msg("WidgetWithFilter.error.incompatible", ed.icon, ed.getLabel(), type.icon, type.getLabel())} />
            </Container>;
        } else if (this.props.s.status === WidgetStatus.IN_PROGRESS) {
            return <Segment className="wh100">
                <Dimmer active><Loader size='medium'>{_msg("general.loading")}</Loader></Dimmer>
            </Segment>
        } else if (this.props.s.status === WidgetStatus.DONE) {
            return this.renderMain();
        }
    }
    
}

function applyDataExplorerFilter(filter: Filter, applyDataExplorerFilterTo: string) {
    if (filter.field) {
        filter.field = applyDataExplorerFilterTo + "." + filter.field;
    }
    if (filter.filters) {
        filter.filters = filter.filters.map(f => applyDataExplorerFilter(f, applyDataExplorerFilterTo));
    }
    return filter;
}
"../../components/CustomQuery/Filter""../..""../../entity_crud/entityCrudConstants""../../reduxReusableComponents/ReduxReusableComponents"