import { TraceMap, originalPositionFor, sourceContentFor } from '@jridgewell/trace-mapping';
import ErrorStackParser from "error-stack-parser";
import { parse } from 'stack-trace';

export interface SourceCodeInfo {
    sourceFile: string;
    sourceLine: number;
    sourceColumn: number;
    sourceCode: string;
}

/**
 * When the program suspends, we see a stack trace e.g.:
 * * file1.function1()
 * * file2.function2()
 * * File3TestsAreDemo.someTest()
 * * ...
 * 
 * We are interested in the ...TestsAreDemo file. But in order to retrieve it (its source code + line number), we always (at each suspend) need
 * to decode file1 and file2 as well. Meaning loading the corresponding .map file + decoding.
 * 
 * Hence we want to cache. In "step by step" mode performed by a human, this optimization is not really needed. However, when we are in the 
 * "puppeteer" mode, when puppeteer is making screenshots at each stop: we need this optimization.
 */
export class TraceMapCache {

    static INSTANCE = new TraceMapCache();

    protected cache: { [fileName: string]: TraceMap; } = {};

    async getSourceCodeState(fileName: string, line: number, column: number): Promise<SourceCodeInfo | undefined> {
        const sourceMapFile = this.getMapFileUrl(fileName);
        if (!this.cache[fileName]) {
            try {
                const response = await fetch(sourceMapFile);
                if (!response.ok) {
                    throw new Error("Server returned code " + response.status + " - " + response.statusText)
                }
                const mapFile = await (response).text();
                const traceMap = new TraceMap(JSON.parse(mapFile));
                this.cache[fileName] = traceMap;
            } catch (e) {
                // CS: let's see if this message makes sense, or we should hide it
                console.error("Error while processing source-map: fetch", sourceMapFile, e);
                return undefined;
            }
        }
        const position = originalPositionFor(this.cache[fileName], { line, column });
        if (!position.source
            || !position.line) {  // this is equivalent of instanceof InvalidOriginalMapping
            // CS: idem cf. above
            console.error("Error while processing source-map: originalPositionFor()", sourceMapFile);
            return undefined;
        }
        const sourceCode = sourceContentFor(this.cache[fileName], position.source);
        if (!sourceCode) {
            // CS: idem cf. above
            console.error("Error while processing source-map: sourceContentFor()", sourceMapFile);
            return undefined;
        }
        return { sourceCode, sourceFile: position.source, sourceLine: position.line, sourceColumn: position.column };
    }

    /**
     * Initially this function didn't use a cache. Now it does. As any cache, there is the dilemma: memory vs speed optimization.
     * 
     * Disadvantage: if there is a single error w/ 10 lines, we'll download and store maybe 6 or 7 source maps.
     * And then we won't use them any more, because the error was very rare.
     * 
     * Advantage: if an error appears several times, then we gain time by using the cache. 
     * 
     * Initially, this was for TestsAreDemo. So speed was not that crucial. But since adding the feature that sends the (decoded)
     * error to server, speed becomes important. If we have a recurring error that maybe is not blocking, we don't want it to
     * generate a lot of network traffic.
     */
    async decodeStackTrace(err: Error): Promise<string> {
        let indexOfNl: number;
        if (!err.stack
            || (indexOfNl = err.stack.indexOf("\n")) < 0) { // the first line is the message anyway; so no need to return it
            return err.message + " - No stacktrace available";
        }
        const stackFrameFunctions = parse(err).map(stackFrame => stackFrame.getFunctionName()); // for show funtion of error

        let stackLog = err.stack?.substring(0, indexOfNl);
        const stackFrames = ErrorStackParser.parse(err);
        for await (const [idx, stackFrame] of stackFrames.entries()) {
            // if the file name, line number or column number cannot be determined skip this stackFrame
            if (!stackFrame.fileName || !stackFrame.lineNumber || !stackFrame.columnNumber) {
                continue;
            }

            const sourceCodeState = await this.getSourceCodeState(stackFrame.fileName, stackFrame.lineNumber, stackFrame.columnNumber);
            if (!sourceCodeState) {
                continue;
            }

            // conf. "The column number is 0-based." (column + 1) and "The line number is 1-based." (line - 1) (https://github.com/mozilla/source-map)
            // the source is only path and she can be relative or absolute
            // create from source an url to source line, column where is error
            // ex. ../../test.js => http://localhost:3000/test.js:1:1
            const sourceUrl = window.location.origin + sourceCodeState.sourceFile.split("..").pop() + ":" + sourceCodeState.sourceLine + ":" + (sourceCodeState.sourceColumn + 1);
            const functionName = stackFrameFunctions[idx] ? (" at " + stackFrameFunctions[idx]) : "";
            stackLog += "\n\t " + functionName + " [" + sourceCodeState.sourceCode.split("\n")[sourceCodeState.sourceLine - 1].trim() + "] (" + sourceUrl + ")";
        }
        return stackLog;
    }

    private getMapFileUrl(fileName: string) {
        let url = fileName;
        if (!fileName.startsWith("http")) {
            // if the file name is only path, create the url
            // I observed in lib dev/preview mode the fileName is only path
            url = window.location.origin + "/" + fileName;
        }
        return url.split("?")[0] + ".map";
    }
}