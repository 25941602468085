import { Messages } from "@crispico/foundation-gwt-js";
import { NavLinkProps } from "react-router-dom";
import { ID } from "./entityCrudConstants";
import StringFieldRenderer, { StringFieldRendererProps } from "./fieldRenderersEditors/StringFieldRenderer";

type Props = StringFieldRendererProps & {
    url?: string,
    navLinkProps?: Partial<NavLinkProps>
};

export class AssociationFieldRenderer extends StringFieldRenderer<Props> {
    private getFieldName(item: any) {
        return Messages.getInstance().maybeTranslateByUser(this.props.innerEntityDescriptor!.toMiniString(item));
    }

    protected getValue() {
        return this.props.innerEntityDescriptor!.toMiniString(this.props.value);
    }

    protected getUrl() {
        return this.props.url || (!this.props.value ? "" : this.props.innerEntityDescriptor!.getEntityEditorUrl(this.props.value[ID]));
    }

    protected getNavLinkExtraProps() {
        return this.props.navLinkProps || {};
    }

    protected getShowManyToOneCellAsLink() {
        return this.props.fieldDescriptor.showManyToOneCellAsLink;
    }

    render() {
        // For one-to-many and many-to-many
        if (Array.isArray(this.props.value)) {
            return <>
                {this.props.value.map((item: any) => {
                    return super.renderLabel(this.props, this.getFieldName(item));
                })}
            </>;
        }

        if (this.getShowManyToOneCellAsLink()) {
            return super.render();
        }
        return super.getContent();
    }
}