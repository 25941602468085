import React from "react";
import { SplitPaneExt } from "../../copied/ReactSplitPaneExt/ReactSplitPaneExt.js";
import { MarkdownExt } from "../MarkdownExt.js";
import { LOCAL_STORAGE_CURRENT_DIR_WITH_SCREENSHOTS } from "../TestsAreDemoMaster.js";
import { FeaturesList } from "../feature-book/FeatureList.js";

interface State { 
    dirs?: string[]
    currentDir?: string
    markdown?: string 
}

export class ScreenshotsFromTests extends React.Component<any, State> {

    state: State = {};

    async componentDidMount() {
        const result = await fetch("screenshots/index.json");
        if (!result.ok) {
            return;
        }
        const indexJson = await result.text();
        this.setState({dirs: JSON.parse(indexJson)});       
    }

    async loadDir(dir: string) {
        try {
            const result = await fetch("screenshots/" + dir + "/report.md");
            if (!result.ok) {
                throw new Error();
            }
            const markdown = await result.text()
            this.setState({ currentDir: dir, markdown });
        } catch {
            this.setState({ markdown: undefined });
        }
    }

    render() {
        return (
            <SplitPaneExt size={"25%"} split="vertical">
                <FeaturesList items={this.state.dirs} localStorageKey={LOCAL_STORAGE_CURRENT_DIR_WITH_SCREENSHOTS}
                    onSelect={(dir: string) => this.loadDir(dir)} />
                <MarkdownExt baseUrlForImages={"screenshots/" + this.state.currentDir} >{this.state.markdown}</MarkdownExt>;
            </SplitPaneExt>)
    }
}