import { FilterOperators } from "@crispico/foundation-gwt-js";
import { auditService_findByFilter, auditService_findByFilterVariables } from "@crispico/foundation-react/apollo-gen-foundation/auditService_findByFilter";
import { apolloClientHolder } from "@crispico/foundation-react/apolloClient";
import { BarChart, BarChartRaw, ChartMapping } from "@crispico/foundation-react/components/BarChart/BarChart";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { ResponsiveLineChart, ResponsiveLineChartRRC } from "@crispico/foundation-react/components/responsiveLineChart/ResponsiveLineChart";
import { entityDescriptors, ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { CUSTOM_FIELDS, FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { EntityDescriptorForServerUtils } from "@crispico/foundation-react/flower/entityDescriptorsForServer/EntityDescriptorForServerUtils";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import { Datum, Point, PointSymbolProps, Serie } from "@nivo/line";
import moment from "moment";
import React from "react";
import { ReactNode } from "react";
import { Icon, Label } from "semantic-ui-react";
import { DisplayMode, getHistoryGraphColor, HistoryGraphData, HistoryGraphItem, HistoryGraphItemEntityDescriptor,  HistoryGraphItemProps, HistoryGraphItemReducers, HistoryGraphItemState, HISTORY_GRAPH_ITEM_DEFAULT_HEIGHT } from "./HistoryGraphItem";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { ActionName, AuditUtils } from "../AuditUtils";
import { categoricalColorSchemes } from "@nivo/colors";
import { LOAD_AUDIT_FOR_GRAPH } from "../queries";

export enum ChartType {
    BAR_CHART, LINE_CHART
}

export class HistoryGraphFieldItemState extends HistoryGraphItemState {
    series = [] as Serie[];
    fieldMappingId = undefined as string | undefined;
    chartType = undefined as ChartType | undefined;
}

export class HistoryGraphFieldItemReducers<S extends HistoryGraphFieldItemState = HistoryGraphFieldItemState> extends HistoryGraphItemReducers<S> {
    updateSeries(serie: Serie) {
        // update existing serie for an entity, don't add duplicate
        const index = this.s.series.findIndex(serieState => serieState.id == serie.id);
        if (index > -1) {
            this.s.series[index] = serie;
        } else {
            this.s.series.push(serie);
        }
    }
}

export type HistoryGraphFieldItemProps = RRCProps<HistoryGraphFieldItemState, HistoryGraphFieldItemReducers> & HistoryGraphItemProps & {
    entityName: string;
    fieldName: string;
};

export class HistoryGraphFieldItem extends HistoryGraphItem<HistoryGraphFieldItemProps> {
    protected barChartRef = React.createRef<BarChartRaw>();
    protected responsivelineChartRef = React.createRef<ResponsiveLineChart>();

    protected getName() {
        return this.getFieldDescriptor().getLabel();
    }

    private getFieldDescriptor() {
        return entityDescriptors[this.props.entityName].getField(this.props.fieldName);
    }

    protected async refreshInternal() {
        const props = this.props;
        const { entityName, fieldName, startDate, endDate } = props;
        if (!entityName || !fieldName || EntityDescriptorForServerUtils.getFieldId(entityName, fieldName) === undefined) {
            return;
        }
        const fieldMappingId = String(EntityDescriptorForServerUtils.getFieldId(entityName, fieldName));
        props.r.setInReduxState({ fieldMappingId, series: [] })

        const commonFilters: Filter[] = [
            AuditUtils.getFilterForAuditableEntity(entityName),
            AuditUtils.getFilterForAuditableField(entityName, fieldName),
            Filter.createComposed(FilterOperators.forComposedFilter.or, [
                Filter.create("action", FilterOperators.forString.equals, ActionName.UPDATE),
                Filter.create("action", FilterOperators.forString.equals, ActionName.REFRESH),
            ])
        ];

        const startFilter: Filter = Filter.create("date", FilterOperators.forDate.lessThanOrEqualTo, moment(startDate).toISOString());
        const filter: Filter = Filter.createComposed(FilterOperators.forComposedFilter.and, [
            Filter.create("date", FilterOperators.forDate.greaterThanOrEqualTo, moment(startDate).toISOString()),
            Filter.create("date", FilterOperators.forDate.lessThanOrEqualTo, moment(endDate).toISOString())
        ]);
        const endFilter: Filter = Filter.create("date", FilterOperators.forDate.greaterThanOrEqualTo, moment(endDate).toISOString());

        const asc: Sort[] = [{ field: "date", direction: "ASC" }];
        const desc: Sort[] = [{ field: "date", direction: "DESC" }];

        const fd = entityDescriptors[entityName].getField(fieldName);       
        let tableIndex = 0;

        for (let i = 0; i < props.entities.length; i++) {
            const entity = props.entities[i];
            const entityId = entity[ID];
            const entityIdFilter: Filter = Filter.create("entityId", FilterOperators.forNumber.equals, entityId.toString());
            const startVars = FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, commonFilters.concat([startFilter, entityIdFilter]))).sorts(desc).pageSize(1);
            const vars = FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, commonFilters.concat([filter, entityIdFilter]))).sorts(asc);
            const endVars = FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, commonFilters.concat([endFilter, entityIdFilter]))).sorts(asc).pageSize(1);

            const startResult = (await apolloClientHolder.apolloClient.query<auditService_findByFilter, auditService_findByFilterVariables>({ query: LOAD_AUDIT_FOR_GRAPH, variables: startVars, context: { showSpinner: false } })).data.auditService_findByFilter?.results;
            let result = (await apolloClientHolder.apolloClient.query<auditService_findByFilter, auditService_findByFilterVariables>({ query: LOAD_AUDIT_FOR_GRAPH, variables: vars, context: { showSpinner: false } })).data.auditService_findByFilter?.results;
            const endResult = (await apolloClientHolder.apolloClient.query<auditService_findByFilter, auditService_findByFilterVariables>({ query: LOAD_AUDIT_FOR_GRAPH, variables: endVars, context: { showSpinner: false } })).data.auditService_findByFilter?.results;
            const serie: Serie = { id: entityId, color: "var(--blue)", data: [] };
            if (i > 0) {
                serie.color = getHistoryGraphColor(i);
            }

            if (result && startResult && startResult.length > 0) {
                const item = startResult[0];
                item.date = startDate;
                result = [item].concat(result);
            }

            if (result && endResult && endResult.length > 0) {
                const item = endResult[0];
                item.date = endDate;
                result = result.concat(item);
            }

            if (result?.length == 0) {
                continue;
            }

            let previousDate:any = undefined;
            
            // declare index here and not in combination with item because can grow without item being pushed to data and 
            // a bug for selected points can appears otherwise
            let index = 0;
            result?.forEach(item => {
                // this was needed because items were overlapping if field changed in same sec, so for the moment I ignore these quick changes
                // maybe the BarChart needs to work with layers instead of items or maybe there is a way to overlap items? in timeline
                if (previousDate !== undefined && moment(item.date).toDate().getTime() - previousDate < 1000) {
                    serie.data[serie.data.length - 1].x = moment(item.date).toDate().getTime();
                    serie.data[serie.data.length - 1].y = fd.getFieldValueConvertedToMeasurementUnit(item.newValue);
                } else {
                    serie.data.push({
                        id: index++,
                        x: moment(item.date).toDate().getTime(),
                        y: fd.getFieldValueConvertedToMeasurementUnit(item.newValue),
                        serieId: entityId,
                        tableIndex: tableIndex++,
                    });
                }               
                serie.label = entityDescriptors[entityName].toMiniString(entity);
                previousDate = moment(item.date).toDate().getTime();
            });
            props.r.updateSeries(serie);
        }
        let existsMappingFromFdSettings = false;
        const { fieldDescriptorSettings } = fd;
        if (fieldDescriptorSettings?.fieldIntervals) {
            const { fieldIntervals } = fieldDescriptorSettings;
            if (fieldIntervals.length > 0) {
                existsMappingFromFdSettings = true;
            }
        }

        let chartType = (!fd.type || fd.type === FieldType.number || fd.type === FieldType.double) ? ChartType.LINE_CHART : ChartType.BAR_CHART;
        let hasStringValues = false;
        props.s.series.forEach(serie => {
            if (fd.type !== FieldType.number && fd.type !== FieldType.double && !existsMappingFromFdSettings && serie && serie?.data) {

                const d = serie.data.length / 10;
                // selecting 10 entries from the data
                const entriesToCheck = new Array(10).fill(0).map((value, index) => {
                    return Math.floor(index * d);
                });
                // deciding whether number of string value should be used
                entriesToCheck.forEach(i => {
                    const entry = serie.data[i];
                    if (entry === undefined || entry === null) {
                        return;
                    }
                    if (typeof entry.y === "string") {
                        if (isNaN(Number(entry.y))) {
                            hasStringValues = true;
                        }
                    }
                });
            }
        });
        // if the values tested are numbers stored a string, a line chart is used
        if (hasStringValues) {
            chartType = ChartType.BAR_CHART;
        }
        props.r.setInReduxState({ chartType });
    }

    
    protected getSelected() {
        let selected: { [id: number]: number } = {};
        this.props.s.series.forEach(serie => {
            const data = serie.data.filter(entry => Number(entry.x) <= this.props.currentTime!);
            if (data.length === 0) {
                return;
            }
            selected[Number(serie.id)] = data.reduce(function(a, b) {
                return (a.date > b.date) ? a : b;
            }).id;
        });
        return selected;
    }

    protected getTableEntityDescriptor() {
        const fd = entityDescriptors[this.props.entityName].getField(this.props.fieldName);
        const ed = HistoryGraphItemEntityDescriptor().addFieldDescriptor({ name: "value", type: fd.type });
        ed.getField("value").fieldDescriptorSettings = fd.fieldDescriptorSettings;
        return ed;
    }

    protected renderSelectedPoint(): ReactNode | null {
        // TODO: for multiple series must be implemented in another style
        if (this.props.s.series.length > 1) {
            return <></>
        }
        const serie = this.props.s.series.find(serie => !Utils.isNullOrEmpty(this.state.selected[Number(serie.id)]));
        const selectedPoint = serie?.data[this.state.selected[Number(serie.id)]];
        return selectedPoint ? <><span className="tiny-margin-right">{selectedPoint.y}</span>{<Label basic><Icon name='calendar alternate outline' /> {moment(selectedPoint.x).format(Utils.dateTimeWithSecFormat)}</Label>}</> : null
    }

    protected getData() {
        // when animation is on display on table just selected points for multiple series
        return this.props.s.series.length > 0 ? this.props.s.series.reduce((previousValue: HistoryGraphData[], currentValue: Serie) => {
            // if multiple entities and animation on
            if (this.props.s.series.length > 1 && Object.keys(this.state.selected).length > 0 && this.isAnimationOn() && !Utils.isNullOrEmpty(this.state.selected[Number(currentValue.id)])) {
                const selectedPoint: Datum = currentValue.data[this.state.selected[Number(currentValue.id)]];
                return previousValue.concat([{ id: selectedPoint.tableIndex, label: currentValue.label, date: Number(selectedPoint.x), value: selectedPoint.y }])
            }

            // default behavior with all points from serie or animation off for multiple series
            return previousValue.concat(currentValue.data.map(d => { return { id: d.tableIndex, label: currentValue.label, date: Number(d.x), value: d.y }}))
        }, []) : [];
    }

    protected getMapping() {        
        const fd = entityDescriptors[this.props.entityName].getField(this.props.fieldName);
        const mappingAddedForValues = new Set<string>();
       
        let mapping: ChartMapping[] = [];
       
        const { fieldDescriptorSettings } = fd;
        if (fieldDescriptorSettings?.fieldIntervals) {
            const { fieldIntervals } = fieldDescriptorSettings;
            for (let i = 0; i < fieldIntervals.length; i++) {
                const fi = fieldIntervals[i];
                if (fi.to) { // field is continuous
                    mapping.push({
                        start: Number(fi.from), end: Number(fi.to),
                        color: fi.color, id: i,
                        text: fi.label,
                        label: <div><Icon name="circle" style={{ color: fi.color }} />{fi.label ? (fi.label + " ") : null}{fi.from + " " + _msg("general.to.lowercase") + " " + fi.to}</div>
                    });
                } else {
                    const dummyEntity = fd.isCustomField ? { [CUSTOM_FIELDS]: { [fd.name]: fi.from } } : { [fd.name]: fi.from };
                    mapping.push({
                        value: fi.enumOrderIndex !== undefined && fi.enumOrderIndex !== null ? fi.enumOrderIndex : fi.from,
                        color: fi.color, id: i,
                        text: fi.label,
                        label: fi.label || fd.renderField(dummyEntity)
                    });
                }
            }
        }        
        if (this.props.s.chartType === ChartType.BAR_CHART && mapping.length === 0) {
            this.props.s.series.forEach(serie => {
                serie.data.forEach(entry => {
                    const valueAsString = String(entry.y);
                    const value = AuditUtils.convertAuditValue(fd, entry.y);
                    if (!Utils.isNullOrEmpty(valueAsString)) {
                        if (!mappingAddedForValues.has(valueAsString)) {
                            // creating a new mapping in case a bar chart is used
                            const rendered = fd.renderField(fd.isCustomField ? { [CUSTOM_FIELDS]: { [fd.name]: value } } : { [fd.name]: value });
                            mapping = [...mapping, {
                                id: mappingAddedForValues.size,
                                value: valueAsString,
                                color: categoricalColorSchemes.category10[mappingAddedForValues.size % 10],                                
                                label: rendered,
                                legend: <><Icon name="circle" style={{ color: categoricalColorSchemes.category10[mappingAddedForValues.size % 10] }} />{rendered}</>
                            }];
                            mappingAddedForValues.add(valueAsString);
                        }
                    }
                });
            });
        }       
        return mapping;
    }

    protected getHeight() {
        let heightFromRef;
        switch(this.props.s.chartType) {
            case ChartType.BAR_CHART:
                heightFromRef = this.barChartRef.current?.getHeight();
                if (heightFromRef)  {
                    heightFromRef += 32; // 32 from zoom bar
                }
                break;
            case ChartType.LINE_CHART:
                heightFromRef = this.responsivelineChartRef.current?.getHeightBasedOnParent();
        }
        return heightFromRef ? heightFromRef : HISTORY_GRAPH_ITEM_DEFAULT_HEIGHT;
    }

    private renderBarChart() {
        const props = this.props;
        const selectedItems: string[] = [];
                
        Object.keys(this.state.selected).forEach(key => {
            const id = Number(key);
            const s = this.state.selected[id];
            if (s === undefined) {
                return;
            }
            selectedItems.push(id + "." + s);
        });
        return <BarChart
            id={"BC" + props.fieldName + props.s.displayMode}
            ref={this.barChartRef} hasZoomSlider={true}
            initialStartDate={props.startDate} initialEndDate={props.endDate}
            currentStartDate={props.currentStartDate ? props.currentStartDate : props.startDate}
            currentEndDate={props.currentEndDate ? props.currentEndDate : props.endDate}
            initialSeries={props.s.series} mapping={this.getMapping()}
            onRangeChange={(s, e) => props.onRangeChange?.call(null, s, e)}
            showCurrentTime={props.showCurrentTime} currentTime={props.currentTime}
            selectedItems={selectedItems} onItemClick={(item: any) => {
                const key = item.currentTarget.getAttribute("data-item-index");
                const [id, index] = key.split(".");
                this.setState({ selected: { [id]: index } });
            }}
        />;
    }

    private renderLineChart() {
        const props = this.props;
        return <ResponsiveLineChartRRC
            id={"LC" + props.fieldName + props.s.displayMode}
            hasZoomSlider={true} ref={this.responsivelineChartRef}
            startDate={props.startDate} endDate={props.endDate}
            currentStartDate={props.currentStartDate} currentEndDate={props.currentEndDate}
            currentTime={props.currentTime} showCurrentTime={props.showCurrentTime}
            series={props.s.series}
            mapping={this.getMapping()} scaleYField={props.s.fieldMappingId} curve="stepAfter"
            pointSymbol={(props: Readonly<PointSymbolProps>) => {
                if (this.state.selected[props.datum.serieId] == props.datum.id) {
                    return <g>
                        <circle fill={this.props.s.series.find(serie => serie.id == props.datum.serieId)?.color} r={props.size * 2.5} strokeWidth={0} stroke={props.borderColor} opacity={0.5} />
                        <circle fill={"#fff"} r={props.size * 1.7} strokeWidth={0} stroke={props.borderColor} />
                        <circle r={props.size * 1.2} strokeWidth={0} stroke={props.borderColor} fill={"var(--blue)"} />
                    </g>;
                } else {
                    return <circle r={props.size / 2} fill={props.color} />;
                }
            }}
            onClick={(point: Point) => {
                if (!this.isAnimationOn() && (point.data as any)?.id !== undefined && (point.data as any)?.id !== "end" &&
                    (point.data as any)?.id !== "start")  {
                    this.setState({ selected: { [(point.data as any).serieId]: (point.data as any).id } });
                }
            }}
            onRangeChange={(s, e) => props.onRangeChange?.call(null, s, e)}
        />;
    }

    protected renderItem() {
        switch(this.props.s.chartType) {
            case ChartType.BAR_CHART:
                return <div className="flex-container wh100">{this.renderBarChart()}</div>;
            case ChartType.LINE_CHART:
                return <div className="flex-container flex-grow-shrink-no-overflow" style={{ paddingRight: 10 }}>{this.renderLineChart()}</div>;
            default:
                return null;
        }
    }

    render() {
        if (this.props.s.fieldMappingId == null) {
            return <></>;
        }
        return super.render();
    }
}

export const HistoryGraphFieldItemRRC = ReduxReusableComponents.connectRRC(HistoryGraphFieldItemState, HistoryGraphFieldItemReducers, HistoryGraphFieldItem);
"../../../apollo-gen-foundation/auditService_findByFilter""../../../apolloClient""../../../components/BarChart/BarChart""../../../components/CustomQuery/Filter""../../../components/CustomQuery/SortBar""../../../components/responsiveLineChart/ResponsiveLineChart""../../../entity_crud/entityCrudConstants""../../../entity_crud/FieldType""../../../entity_crud/FindByFilterParams""../../../flower/entityDescriptorsForServer/EntityDescriptorForServerUtils""../../../utils/Utils""../../../reduxReusableComponents/ReduxReusableComponents"