import _, { isEqual } from "lodash";
import moment from "moment";
import React, { ReactNode } from "react";
import { Button, Dimmer, Header, Icon, Loader, Popup, Segment } from "semantic-ui-react";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { EntityTableSimpleRRC } from "@crispico/foundation-react/entity_crud/EntityTableSimple";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { FIELDS_READ, Utils } from "@crispico/foundation-react/utils/Utils";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { categoricalColorSchemes } from "@nivo/colors";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DateFieldRenderer";
import { DatePickerFieldEditor } from "@crispico/foundation-react/components/DatePicker/DatePickerFieldEditor";
import { ChartMapping } from "@crispico/foundation-react/components/BarChart/BarChart";

export type HistoryGraphData = {
    id: number,
    label: string,
    date: number,
    value: any
};

export enum DisplayMode {
    TABLE, CHART
}

export const HISTORY_GRAPH_ITEM_DEFAULT_HEIGHT = 300;

export function HistoryGraphItemEntityDescriptor() {
    return new EntityDescriptor({ name: "HistoryGraphItem" }, false)
    .addFieldDescriptor({ name: "label", type: FieldType.string })
    .addFieldDescriptor({ name: "date", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat }), additionalFieldRendererProps: FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat }) })
    .addFieldDescriptor({ name: "value", type: FieldType.string });
}

export class HistoryGraphItemState extends State {
    loading = false;
    displayMode = DisplayMode.CHART;
    collapsedItem = false;    
}

export class HistoryGraphItemReducers<S extends HistoryGraphItemState = HistoryGraphItemState> extends Reducers<S> {

}

export type HistoryGraphItemProps = RRCProps<HistoryGraphItemState, HistoryGraphItemReducers> & {
    id: string;
    refreshUUID: string;
    startDate: number;
    endDate: number;
    entities: any[];
    showCurrentTime?: boolean;
    currentTime?: number;
    currentStartDate?: number;
    currentEndDate?: number;
    onRangeChange?: (startDate: number, endDate: number) => void;
}
type LocalState = {
    selected: { [id: number]: number };
}
export abstract class HistoryGraphItem<P extends HistoryGraphItemProps> extends React.Component<P, LocalState> {
    protected abstract getName(): string;
    protected abstract renderItem(): ReactNode;
    protected abstract getData(): HistoryGraphData[];

    constructor(props: P) {
        super(props);

        this.state = { selected: {} };
    }
    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Readonly<HistoryGraphItemProps>, prevState?: LocalState) {
        this.componentDidUpdateInternal(prevProps, prevState);
    }

    protected componentDidUpdateInternal(prevProps?: HistoryGraphItemProps, prevState?: LocalState) {
        const props = this.props;
        if (props.s.collapsedItem) {
            return;
        }
        if (!prevProps || !_.isEqual(prevProps.refreshUUID, props.refreshUUID)) {
            this.refresh();
        }
        if ((prevProps?.currentTime !== props.currentTime) && props.currentTime !== undefined && this.getData().length > 0) {
            const selected = this.getSelected();
            if (!isEqual(selected, this.state.selected)) {
                this.setState({selected: selected});
            }
        }
    }

    protected async refresh() {
        this.props.r.setInReduxState({ loading: true });
        await this.refreshInternal();
        this.props.r.setInReduxState({ loading: false });
    }

    protected async refreshInternal() {
        // to be implemented by history graph item
    }

    protected renderSelectedPoint(): ReactNode | null {
        return null;
    }

    protected getSelected(): { [id: number]: number } {
        return {};
    }

    protected getMapping(): ChartMapping[] {
        return [];
    }

    protected getHeight() {
        return HISTORY_GRAPH_ITEM_DEFAULT_HEIGHT;
    }

    protected getTableEntities() {
        return this.props.currentStartDate && this.props.currentEndDate ? this.getData().filter(d => {
            return this.props.currentStartDate! <= d.date && d.date <= this.props.currentEndDate!;
        }) : this.getData();
    }

    protected getTableSelected() {
        const selectedIds: number[] = [];
        Object.keys(this.state.selected).forEach(key => {
            const id = Number(key);
            selectedIds.push(this.state.selected[id]);
        });
        if (selectedIds.length > 1) {
            return this.getData().reduce(function(a, b) {
                return selectedIds.includes(a.id) && a.date > b.date ? a : b;
            }).id;
        } else {
            return this.getData().find(d => d.id === selectedIds[0])?.id;
        }
    }

    protected exportTable() {
        const ed = this.getTableEntityDescriptor();
        const fields: string[] = Object.keys(ed.getAuthorizedFields(FIELDS_READ));
        const rows: any[] = [fields.map(f => ed.getField(f).getLabel())];
        this.getTableEntities().forEach(entity => rows.push(fields.map(fieldName => {
            if (fieldName === "date") {
                return moment(entity[fieldName]).format(ed.getField(fieldName).format);
            }
            return (entity as any)[fieldName];
        })));
        Utils.exportToCsv("History_graph_" + this.getName() + "_" + moment(Utils.now()).toISOString() + ".csv", rows);
    }

    protected showError() {
        return this.getData().length === 0 && !this.props.s.loading;
    }

    protected renderInfoButton() {
        const mapping = this.getMapping();
        return <Popup on='click' trigger={<Icon name="info circle" size="large" />}>
            <div className="HistoryGraphItem_legendDiv">{mapping.length > 0 ? mapping.map(m => {
                return <div className="AuditFieldGraph_legend_entry">{m.legend ? m.legend : m.label ? m.label : m.text}</div>;
            }) : _msg("HistoryGraphItem.noLegend")}</div>
        </Popup>;
    }

    protected renderHeaderButtons() {
        const downloadIcon = <Icon disabled={this.props.s.collapsedItem} link={!this.props.s.collapsedItem} name="download" />;
        return <>
            <Button primary={this.props.s.displayMode === DisplayMode.TABLE} disabled={this.props.s.collapsedItem}
                onClick={() => this.props.r.setInReduxState({ displayMode: this.props.s.displayMode === DisplayMode.TABLE ? DisplayMode.CHART : DisplayMode.TABLE })} 
                compact className="tiny-margin-right">{entityDescriptors["Audit"].getIcon()} {_msg("entityCrud.editor.table")}
            </Button>
            {this.props.s.collapsedItem ? <span>{downloadIcon}</span> : <a onClick={() => this.exportTable()}>{downloadIcon}</a>}
        </>;
    }

    protected renderHeader() {
        return <div className="AuditFieldGraph_field">
            <div className="flex-grow flex-center">
                <Button size="mini" compact icon={<Icon name={this.props.s.collapsedItem ? "plus" : "minus"} />} onClick={() => this.props.r.setInReduxState({ collapsedItem: !this.props.s.collapsedItem })} />
                <Header className="AuditFieldGraph_header" size="medium">{this.getName()}</Header>
                {this.renderSelectedPoint()}
            </div>
            <div className="flex-center">
                {this.renderInfoButton()}
                {this.renderHeaderButtons()}
            </div>
        </div>;
    }

    protected getTableEntityDescriptor() {
        return HistoryGraphItemEntityDescriptor();
    }

    protected renderTable() {
        // The suffix '_historyGraphItem' is needed because not all components are migrated to RRC so the id from EntityTableSimple can be conflicting if other table is rendered in the same page.
        return <EntityTableSimpleRRC id={"entityTableSimple_historyGraphItem" + this.props.id} entityDescriptor={this.getTableEntityDescriptor()} entitiesAsParams={this.getTableEntities()} selectedAsParams={this.getTableSelected()} />
    }

    protected isAnimationOn() {
        if (this.props.showCurrentTime && this.props.currentTime && this.props.startDate && this.props.endDate && this.props.currentTime != this.props.startDate) {
            return true;
        }
        return false;
    }

    protected renderLoading() {
        return <Segment className="AuditGraph_loading">
            {/* zIndex = 0 bacause of the issue described here with Dimmer & active: https://github.com/Semantic-Org/Semantic-UI-React/issues/3940 */}
            <Dimmer active style={{ zIndex: 0 }}><Loader size='medium'>{_msg("general.loading")}</Loader></Dimmer>
        </Segment>;
    }

    protected renderMain() {
        if (this.props.s.collapsedItem) {
            return <span></span>;
        } else if (this.showError()) {
            return <span>{_msg("general.no.data")}</span>;
        } else if (this.props.s.loading) {
            return this.renderLoading();
        }
        return <SplitPaneExt size={this.props.s.displayMode === DisplayMode.CHART ? "100%" : "75%"} paneStyle={{ overflow: "hidden" }} pane2Style={{ maxHeight: this.getHeight() }}>
            {this.renderItem()}
            {this.renderTable()}
        </SplitPaneExt>;
    }

    render() {
        return <Segment className="tiny-spacing small-padding wh100">
            {this.renderHeader()}
            {this.renderMain()}
        </Segment>;
    }
}

export const HistoryGraphItemRRC = ReduxReusableComponents.connectRRC(HistoryGraphItemState, HistoryGraphItemReducers, HistoryGraphItem);

// 42 unique colors.
const historyGraphColors = [
    ...new Set([
        ...categoricalColorSchemes["category10"],
        ...categoricalColorSchemes["dark2"],
        ...categoricalColorSchemes["paired"],
        ...categoricalColorSchemes["accent"],
        ...categoricalColorSchemes["nivo"],
    ])
]

/**
 * Gets the color at index i from our color map (historyGraphColors) which is a concatenated
 * array of colors from nivo/d3. If the index is bigger than the size of array, a rollover is used 
 * to start from the top again
 * @param index color number
 */
export function getHistoryGraphColor(index: number) {
    return historyGraphColors[index % historyGraphColors.length];
}
"../../../components/ReactSplitPaneExt/ReactSplitPaneExt""../../../entity_crud/entityCrudConstants""../../../entity_crud/EntityDescriptor""../../../entity_crud/EntityTableSimple""../../../entity_crud/FieldType""../../../utils/Utils""../../../reduxReusableComponents/ReduxReusableComponents""../../../entity_crud/fieldRenderersEditors/DateFieldRenderer""../../../components/DatePicker/DatePickerFieldEditor""../../../components/BarChart/BarChart"