import { BlocklyReadOnly } from "@crispico/foundation-react/blockly/BlocklyReadOnly";
import { SORT, SORT_INVALID } from "../../blockly/customBlocks";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react/reduxHelpers";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import lodash from 'lodash';
import React from "react";
import { Button, Icon, Label } from "semantic-ui-react";
import { getMessageForField } from "../fieldNameContentAssist/FieldNameContentAssist";
import { Severity } from "../ModalExt/ModalExt";
import { sliceSortForm, SortForm } from "./SortForm";

export interface Sort {
    field: string;
    direction: string;
}

export function createSortBlock(sort: Sort, entityDescriptorName: string) {
    let field = sort.field ? getMessageForField(sort.field, entityDescriptorName) : sort.field;
    let blockType: string = SORT;
    try {
        entityDescriptors[entityDescriptorName].getField(sort.field);
    } catch (e){
        blockType = SORT_INVALID;
    }

    return (
        `<block type='${blockType}' x='0' y='0'>
            <field name='FIELD'>${field}</field>
        </block>`
    );
}

export const sliceSortBar = createSliceFoundation(class SliceSortBar {

    initialState = {
        sorts: [] as Array<Sort>,
        indexForEditor: -1,
        error: false,
        editorOpened: false as [number, number] | boolean
    }

    nestedSlices = {
        sortForm: sliceSortForm
    }

    reducers = {
        ...getBaseReducers<SliceSortBar>(this),

        closeSortEditor(state: StateFrom<SliceSortBar>) {
            state.editorOpened = false;
            state.indexForEditor = -1;
            state.error = false;
        },

        openSortEditor(state: StateFrom<SliceSortBar>, data: { sortIndex: number, editorPostion: [number, number] }) {
            if (state.editorOpened) { return; }
            state.indexForEditor = data.sortIndex;
            state.editorOpened = data.editorPostion;
        },

    }

    impures = {
        ...getBaseImpures<SliceSortBar>(this),

        onSave(sort: Sort, setSortsInCustomQuery?: (sorts: Array<Sort>) => void) {
            if (!sort.field) {
                this.getDispatchers().setInReduxState({ error: true })
                return;
            }
            this.getDispatchers().setInReduxState({ error: false })
            let sorts = lodash.cloneDeep(this.getState().sorts);
            if (this.getState().indexForEditor === -1) {
                sorts.push(sort);
            } else {
                sorts[this.getState().indexForEditor] = sort;
            }
            this.getDispatchers().setInReduxState({ sorts: sorts })
            this.getDispatchers().closeSortEditor();
            setSortsInCustomQuery && setSortsInCustomQuery(lodash.cloneDeep(this.getState().sorts));
        },

        deleteSort(sortIndex: number, forEditor: boolean, setSortsInCustomQuery?: (sorts: Array<Sort>) => void) {
            let sorts = lodash.cloneDeep(this.getState().sorts);
            sorts = sorts.filter((f, index) => {
                return index !== sortIndex;
            });
            this.getDispatchers().setInReduxState({ sorts: sorts })
            setSortsInCustomQuery && setSortsInCustomQuery(sorts);
            forEditor && this.getDispatchers().closeSortEditor();
        },

        toggleDirection(sortIndex: number, setSortsInCustomQuery?: (sorts: Array<Sort>) => void) {
            let sorts = lodash.cloneDeep(this.getState().sorts);
            let sort = sorts[sortIndex];
            sort.direction = sort.direction === "ASC" ? "DESC" : "ASC";
            sorts[sortIndex] = sort;
            this.getDispatchers().setInReduxState({ sorts: sorts });
            setSortsInCustomQuery && setSortsInCustomQuery(sorts);
        },

        moveLeft(setSortsInCustomQuery?: (sorts: Array<Sort>) => void) {
            if (this.getState().indexForEditor <= 0) { return; }
            let indexForEditor = this.getState().indexForEditor;
            let sorts = lodash.cloneDeep(this.getState().sorts);
            let sort = sorts[indexForEditor];
            sorts[indexForEditor] = sorts[indexForEditor - 1];
            sorts[indexForEditor - 1] = sort;
            this.getDispatchers().setInReduxState({ indexForEditor: indexForEditor - 1, sorts: sorts })

            setSortsInCustomQuery && setSortsInCustomQuery(sorts);
        },

        moveRight(setSortsInCustomQuery?: (sorts: Array<Sort>) => void) {
            if (this.getState().indexForEditor >= this.getState().sorts.length - 1) { return; }
            let indexForEditor = this.getState().indexForEditor;
            let sorts = lodash.cloneDeep(this.getState().sorts);
            let sort = sorts[indexForEditor];
            sorts[indexForEditor] = sorts[indexForEditor + 1];
            sorts[indexForEditor + 1] = sort;
            this.getDispatchers().setInReduxState({ indexForEditor: indexForEditor + 1, sorts: sorts })

            setSortsInCustomQuery && setSortsInCustomQuery(sorts);
        },

    }

});

interface SortBarProps {
    noApply?: boolean
    entityDescriptor: string
    setSortsInCustomQuery?: (sorts: Array<Sort>) => void
}

export class SortBar extends React.Component<PropsFrom<typeof sliceSortBar> & SortBarProps> {

    onSave = (sort: Sort) => {
        if (sort.field && !entityDescriptors[this.props.entityDescriptor].getField(sort.field).sortable) {
            Utils.showGlobalAlert({ message: _msg('Tag.noSort'), severity: Severity.INFO });
            return;
        }
        this.props.dispatchers.onSave(sort, this.props.setSortsInCustomQuery);
    }

    closeSortEditor = () => this.props.dispatchers.closeSortEditor();

    moveLeft = () => this.props.dispatchers.moveLeft(this.props.setSortsInCustomQuery);

    moveRight = () => this.props.dispatchers.moveRight(this.props.setSortsInCustomQuery);

    remove = () => this.props.dispatchers.deleteSort(this.props.indexForEditor, true, this.props.setSortsInCustomQuery);

    openSortEditor = (e: any, index: number) => {
        this.props.dispatchers.openSortEditor({ sortIndex: index, editorPostion: [e.clientX, e.clientY] });
    }

    render() {
        return (
            <>
                <Button data-testid="CustomQueryBar_sortAdd" className="CustomQueryBar_sortFilterAddButton" onClick={(e) => this.openSortEditor(e, -1)} color="orange"><Icon name='add'></Icon>{_msg('SortBar.label')}</Button>
                {this.props.sorts.map((s, index) =>
                    <SortBlockForBar key={index} entityDescriptor={this.props.entityDescriptor} index={index} sort={s} toggleDirection={() => this.props.dispatchers.toggleDirection(index, this.props.setSortsInCustomQuery)}
                        deleteSort={() => this.props.dispatchers.deleteSort(index, false, this.props.setSortsInCustomQuery)} openSortEditor={(e: any) => this.openSortEditor(e, index)} />
                )}
                <SortForm error={this.props.error} sorts={this.props.sorts} formOpened={this.props.editorOpened} {...this.props.sortForm} dispatchers={this.props.dispatchers.sortForm}
                    embeddedMode={false} entityDescriptor={this.props.entityDescriptor}
                    remove={this.remove} moveLeft={this.moveLeft} moveRight={this.moveRight}
                    onSave={this.onSave} closeSortEditor={this.closeSortEditor} indexForEditor={this.props.indexForEditor}
                />
            </>
        );
    }

}

interface SortBlockProps {
    index: number,
    toggleDirection?: any,
    deleteSort?: any,
    openSortEditor: any,
    sort: Sort,
    entityDescriptor: string
}

function SortBlockForBar(props: SortBlockProps) {
    return (
        <Label key={props.index} size='mini' className='SortBar_sortDiv'>
            <div onClick={props.toggleDirection}>
                <Icon className='SortBar_sortIcon' link size='large' name={props.sort.direction === 'ASC' ? 'sort up' : 'sort down'} />
            </div>
            <BlocklyReadOnly openEditor={props.openSortEditor} key={props.index}>{createSortBlock(props.sort, props.entityDescriptor)}</BlocklyReadOnly>
            <Button onClick={props.deleteSort} color="red" className="OnlyFilterBar_removeButton" size='mini' icon='delete' />
        </Label>
    );
}

export function SortAsText(props: {entityDescriptor: string, sorts: Sort[]}) {
    if (props.sorts.length === 0) {
        return null;
    }
    return <span style={{color: "#ecb22d"}}>{_msg("SortBar.label")}: {props.sorts.map((sort, index) => {
            let fieldLabel = "";
            let ed = entityDescriptors[props.entityDescriptor]
            for (const field of sort.field.split(".")) {
                fieldLabel += (fieldLabel !== "" ? "." : "") + ed.getField(field).getLabel();
                ed = entityDescriptors[ed.getField(field).type];
            }

            return <span key={index}>
                {}
                <b>{fieldLabel}</b>
                <Icon className='SortBar_sortIcon' size='small' name={sort.direction === 'ASC' ? 'sort up' : 'sort down'} />
                {index + 1 < props.sorts.length ? ', ' : null}
            </span>
        })}
    </span>;
}"../../blockly/BlocklyReadOnly""../../entity_crud/entityCrudConstants""../../reduxHelpers""../../utils/Utils"